import React, { useState } from "react";
import styled from "styled-components";
import { useAddModelToDate, useAddModelToDates } from 'Hooks/Event/UseEvent';
import { List, Popover } from "antd";
import EventTypeTag from "Components/EventDetails/EventTypeTag";
import NwEllipsis from "Components/Gui/NwEllipsis";
import { showDateNoTime, showPeriod } from "Libs/NwMoment";
import ProjectLine from "Components/ProjectLine/ProjectLine";
import StyleVariables from "Components/Gui/StyleVariables";
import OptionNumber from "Components/EventDrawer/EventDrawerUi/OptionNumber";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import CalendarIconDate from "Components/Dates/CalendarIconDate";
import { AgendaEventContent } from "Components/Schedule/Agenda/AgendaEventStyle";
import { useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import Axios from "axios";
import { update } from "lodash";


const OptionNumberContainer = styled.div`
    position: absolute;
    top: .25rem;
    right: .5rem;
`

const CheckIconContainer = styled.div`
    position: absolute;
    top: .25rem;
    right: .5rem;

    svg {
        font-size: 1.5rem;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.SuccessColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
        cursor: pointer;
    }
`

const BusyButtonContainer = styled.div`
    position: absolute;
    top: .25rem;
    right: .5rem;
    
    svg {
        font-size: 1.5rem;
        --fa-primary-color: white;
        --fa-secondary-color: ${props => props.theme.DangerColor};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
        cursor: pointer;
    }
`

const VerticalDateButton = styled.div`
    cursor: ${props => props.inEvent ? 'not-allowed' : 'pointer'};
    padding-right: 1rem;
    position: relative;
`

const ModelsTableColumnEventDate = ({
    addModelsAs,
    availability,
    isFetchingAvailability,
    eventDate,
    model,
    currentEvent
}) => {

    const { mutateAsync: addModelToDate } = useAddModelToDate();
    const { mutateAsync: addModelToDates } = useAddModelToDates();
    const [visible, setVisible] = useState(false);
    const [addingModel, setAddingModel] = useState(false);
    const [addingModelToAllDates, setAddingModelToAllDates] = useState(false);

    const cs_dispatch = useContactsSelectorDispatch();

    const datesToAdd = currentEvent.EventDates
        .filter(ed => (
            ed.EventDatesModels.every(edm => Number(edm.ModelID) !== Number(model.ID))
        ))
        .map(ed => ed.ID)

    const handleVisibleChange = visible => {
        setVisible(visible);
    };

    const handleUpdateEvent = async () => {
        const eventUpdated = await Axios.get(`/events/${currentEvent.ID}`)
        cs_dispatch({ type: 'UpdateCurrentEvent', eventUpdated: eventUpdated });
    }

    const handleModelAdd = async () => {
        setAddingModel(true);
        try {
            await addModelToDate({ modelId: model.ID, eventDateId: eventDate.ID, eventId: currentEvent.ID, optionNumber: addModelsAs });
            await handleUpdateEvent()
            setAddingModel(false);
        } catch (error) {
            console.log('ant : Add Models to EventDate Error => ', error)
            setAddingModel(false);
        }
    };

    const handleModelAddAllDates = async () => {
        setAddingModelToAllDates(true);
        try {
            await addModelToDates({ modelId: model.ID, eventDatesIds: [...datesToAdd], eventId: currentEvent.ID, optionNumber: addModelsAs });
            await handleUpdateEvent()
            setAddingModelToAllDates(false);
        } catch (error) {
            console.log('ant : Add Models to EventDate Error => ', error)
            setAddingModelToAllDates(false);
        }
    };

    const PopoverContent = ({ events }) => (
        (events.length > 0)
            ?
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={events}
                renderItem={event => (
                    <>
                        <AgendaEventContent>
                            <EventTypeTag event={event} /> {event.Name}
                            {event.Description && <small><NwEllipsis text={event.Description} rows={2} popover /></small>}
                            <small>{showDateNoTime(event.FromDate)} - {showDateNoTime(event.ToDate)}</small>
                            {event.Project &&
                                <ProjectLine project={event.Project} />
                            }
                        </AgendaEventContent>
                    </>
                )}
            />
            :
            <p>no events</p>
    )

    const OptionNumberButton = ({ }) => {
        if (currentEvent.TypeName === "Job") {
            const eventdatemodel = eventDate.EventDatesModels.find(edm => edm.ModelID === model.ID);
            return (
                <OptionNumberContainer onClick={e => e.stopPropagation()}>
                    <OptionNumber
                        key={eventdatemodel.OptionNumber}
                        number={eventdatemodel.OptionNumber}
                        event={currentEvent}
                        eventDates={currentEvent.eventDates}
                        eventDateInfo={eventDate}
                        selectedModel={eventdatemodel}
                        eventDatesModel={eventdatemodel}
                        reloadGroupedModels={() => { }}
                        onExtendOptionNumberToAllDates={() => { }}
                        onChangeOptionNumber={handleUpdateEvent}
                    />
                </OptionNumberContainer>
            )
        } else {
            return (
                <CheckIconContainer>
                    <NwIcon icon={duotone("circle-check")} />
                </CheckIconContainer>
            )
        }
    }

    if (eventDate) {
        //single-date event
        return (
            ((isFetchingAvailability || !availability)
                ?
                    <CalendarIconDate loading />
                :
                (availability.inEvent
                    ?
                    <VerticalDateButton
                        available={false}
                        inEvent={true}
                    >
                        <CalendarIconDate date={eventDate.FromDate} color={StyleVariables.PrimaryColor} />
                        <OptionNumberButton />
                    </VerticalDateButton>
                    :
                    (availability.available
                        ?
                        <VerticalDateButton
                            available={true}
                            inEvent={false}
                            onClick={() => handleModelAdd(eventDate.ID)}
                        >
                            <CalendarIconDate
                                loading={addingModel}
                                date={eventDate.FromDate}
                                color={StyleVariables.DarkerBorderColor}
                                addible
                            />
                        </VerticalDateButton>
                        :
                        <VerticalDateButton
                            available={availability.available}
                            inEvent={availability.inEvent}
                            onClick={() => handleModelAdd(eventDate.ID)}
                        >
                            <CalendarIconDate
                                loading={addingModel}
                                date={eventDate.FromDate}
                                color={StyleVariables.DarkerBorderColor}
                                addible
                            />
                            <Popover
                                placement="top"
                                content={<PopoverContent events={availability.otherEvents} />}
                                title={showPeriod(eventDate.FromDate, eventDate.ToDate)}
                                trigger="hover"
                                open={visible}
                                onOpenChange={handleVisibleChange}
                            >
                                <BusyButtonContainer>
                                    <NwIcon icon={duotone("circle-exclamation")} />
                                </BusyButtonContainer>
                            </Popover>
                        </VerticalDateButton>
                    )
                )
            )
        )
    } else {
        return (
            <VerticalDateButton
                available={true}
                inEvent={false}
                onClick={() => handleModelAddAllDates()}
            >
                <CalendarIconDate loading={addingModelToAllDates} color={StyleVariables.DarkerBorderColor} />
            </VerticalDateButton>
        )
    }

    // return (
    //     availability
    //         ?
    //         (availability.inEvent
    //             ?
    //             <VerticalDateButton
    //                 available={false}
    //                 inEvent={true}
    //             >
    //                 <CalendarIconDate date={eventDate.FromDate} color={StyleVariables.PrimaryColor} />
    //                 <OptionNumberButton />
    //             </VerticalDateButton>
    //             :
    //             (availability.available
    //                 ?
    //                 <VerticalDateButton
    //                     available={true}
    //                     inEvent={false}
    //                     onClick={() => handleModelAdd(eventDate.ID)}
    //                 >
    //                     <CalendarIconDate
    //                         loading={addingModel}
    //                         date={eventDate.FromDate}
    //                         color={StyleVariables.DarkerBorderColor}
    //                         addible
    //                     />
    //                 </VerticalDateButton>
    //                 :
    //                 <VerticalDateButton
    //                     available={availability.available}
    //                     inEvent={availability.inEvent}
    //                     onClick={() => handleModelAdd(eventDate.ID)}
    //                 >
    //                     <CalendarIconDate
    //                         loading={addingModel}
    //                         date={eventDate.FromDate}
    //                         color={StyleVariables.DarkerBorderColor}
    //                         addible
    //                     />
    //                     <Popover
    //                         placement="top"
    //                         content={<PopoverContent events={availability.otherEvents} />}
    //                         title={showPeriod(eventDate.FromDate, eventDate.ToDate)}
    //                         trigger="hover"
    //                         open={visible}
    //                         onOpenChange={handleVisibleChange}
    //                     >
    //                         <BusyButtonContainer>
    //                             <NwIcon icon={duotone("circle-exclamation")} />
    //                         </BusyButtonContainer>
    //                     </Popover>
    //                 </VerticalDateButton>
    //             )
    //         )
    //         :
    //         (eventDate
    //             ?

    //             :
                
    //         )
    // )
};

export default ModelsTableColumnEventDate;
