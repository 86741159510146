import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Dropdown, Row } from 'antd';
import ProjectTransactionsCard from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsCard';
import { NwAddButton } from 'Components/Gui/NwButton';
import ProjectGroupedTransactionsList from 'Components/Project/ProjectTransactions/Transactions/ProjectGroupedTransactionsList';
import ProjectUsagesCard from './Usages/ProjectUsagesCard';
import ProjectInvoicesCard from './Invoices/ProjectInvoicesCard';
import AddProjectTransactionDrawer from './Transactions/AddProjectTransactionDrawer';
import { GetTimestamp } from 'Libs/NwMoment';
import { checkTenant } from 'Libs/NwUtils';
import { useUserPermission } from 'Contexts/UserContext';

const Container = styled.div`
    .ant-card-body-content {
        padding: 0 !important;
    }

    div.add-button-container {
        padding: 1rem 2rem;
        border-bottom: ${props => props.theme.DefaultBorder};
        display: flex;
        justify-content: flex-end;
    }
`;

const ProjectTransactions = ({ projectId, eventData, mode = 'project', modelId, readOnly }) => {
    const [transactionGroup, setTransactionGroup] = useState();
    const [transactionDrawer, setTransactionDrawer] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(GetTimestamp());

    const accessAccounting = useUserPermission('AccessAccounting');

    const handleCloseTransactionDrawer = () => {
        setTransactionGroup();
        setTransactionDrawer(false);
        setLastUpdate(GetTimestamp());
    };

    const handleShowTransactionDrawer = ({ key }) => {
        setTransactionGroup(key);
        setTransactionDrawer(true);
    };

    const addTransactionMenuItems = () => {
        const isEliteBooker = checkTenant('elitemodels') && !accessAccounting
        const items = [
            { key: "Fee", label: "Fee" },
            { key: "Expense", label: "Expense" },
            { key: "Credit", label: "Credit" }
        ]
        //if isElite and user does not have access to accounting I remove the Credit option
        if (isEliteBooker) {
            items.splice(2, 1)
        }
        return items
    }

    return (
        <Container>
            {(mode === 'event') ?
                <>
                    <div className="add-button-container">
                        <Dropdown key='addTransaction'
                            menu={{
                                onClick: handleShowTransactionDrawer,
                                items: addTransactionMenuItems()
                            }}
                        >
                            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <NwAddButton ghost size='small' label="add a transaction" />
                            </div>
                        </Dropdown>
                    </div>
                    <ProjectGroupedTransactionsList
                        event={eventData}
                        projectId={projectId}
                        modelId={modelId}
                        readOnly={readOnly}
                        lastUpdate={lastUpdate}
                    />
                </>
            :
                <div className="nw-cards-grid">
                    <Row gutter={10} type="flex">
                        <Col span={16}>
                            <ProjectTransactionsCard projectId={projectId} modelId={modelId} readOnly={readOnly} />
                        </Col>
                        <Col span={8}>
                            <div>
                                <ProjectInvoicesCard projectId={projectId} />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <ProjectUsagesCard projectId={projectId} readOnly={readOnly} />
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {transactionDrawer && (
                    <AddProjectTransactionDrawer
                        transactionGroup={transactionGroup}
                        projectId={projectId}
                        onClose={handleCloseTransactionDrawer}
                    />
                )
            }
        </Container >
    )
};

export default ProjectTransactions;