import React, { useContext, useMemo, useState } from 'react';
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWAmountInput, NWQuantitySelector } from "Components/Gui/NwForm/NwFormItems";
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import useConfigurations from 'Hooks/UseConfigurations';
import { validateAmountZeroAllowed } from 'Libs/NwUtils';
import Axios from 'axios';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import { ListContext } from 'Contexts/ListContext';
import styled from 'styled-components';

const Wrapper = styled.div`
    .payroll {
        background-color: ${props => props.theme.LighterBorderColor};
        padding: .5rem 1rem .75rem 1rem;
        border-radius: 4px;
        border: ${props => props.theme.DefaultBorder};
        margin: .5rem 0;
        span {
            font-size: ${props => props.theme.FontSizeSm};
            button {
                margin-right: 1rem;
            }
        }
        .payroll-amount {
            display: block;
            margin: .5rem 1rem 0 4rem;
        }
        .payroll-not-allowed {
            color: ${props => props.theme.DangerColor};
            font-size: ${props => props.theme.FontSizeSm};
            margin: .15rem 1rem 0 4rem;
        }
    }
`;


const TransactionAmountForm = ({ transaction, onSave, onCancel }) => {
    const configurations = useConfigurations()
    const { cachedList } = useContext(ListContext);

    const transactionFamilies = cachedList.transactionFamilies.items;
    const rateTransaction = transactionFamilies.find(family => family.Code === 'JobFee');
    const currentLabel = rateTransaction.TransactionLabels.find(label => label.ID === transaction.TransactionLabelID);

    const hasClientAdvance = useMemo(() => {
        if (transaction.Family === "JobFee") {
            return transaction.ClientStatements.some(cs => cs.isClientAdvance)
        }
        return false;
    }, [transaction.ClientStatements]);

    const payrollAllowed = transaction.Family === "JobFee" && (transaction.Currency === configurations.defaultCurrency) && !currentLabel.FixedTarif;

    const [touchForm, setTouchForm] = useState(false);
    const [usePayroll, setUsePayroll] = useState(hasClientAdvance);

    let currentClientAdvance = 0;
    if (hasClientAdvance) {
        currentClientAdvance = Math.abs(transaction.ClientStatements.find(cs => cs.isClientAdvance).Amount);
    }

    let initialValues = {
        CurrencySingleFee: transaction ? [transaction.Currency, transaction.SingleFee] : [configurations.defaultCurrency, 0],
        QuantityTypeQuantity: transaction ? [transaction.Quantity, transaction.TypeQuantity] : [0, 'Units'],
        ClientAdvance: [configurations.defaultCurrency, currentClientAdvance]
    };

    const submitForm = async (values, setStatus, setSubmitting) => {
        setSubmitting(true);
        const dataToSend = {
            TransactionID: transaction.ID,
            Amount: values.CurrencySingleFee[1],
            Quantity: values.QuantityTypeQuantity[0],
            TypeQty: values.QuantityTypeQuantity[1],
            ClientAdvance: usePayroll ? values.ClientAdvance[1] : 0
        }

        try {
            await Axios.put(`/transactions/${transaction.Family}/modifyamount`, dataToSend)
            forceReloadTransaction(transaction.ID)
            if (transaction.ProjectID) {
                forceReloadProjectTransactions(transaction.ProjectID)
            }
            setTouchForm(false);
            setSubmitting(false);
            onSave();
        } catch (error) {
            setSubmitting(false);
            console.log("errore", error);
        }
    };

    return (
        <Wrapper>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    submitForm(values, setStatus, setSubmitting);
                }}
            >
                {({ isSubmitting, values, handleSubmit }) => {
                    return (
                        <NwForm
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit}
                            layout="vertical">
                            <Row gutter={16}>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <Field
                                        component={NWAmountInput}
                                        value={values.CurrencySingleFee}
                                        currencyReadOnly={true}
                                        validate={validateAmountZeroAllowed}
                                        label="Single Fee"
                                        name="CurrencySingleFee"
                                    />
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={24}>
                                    <Field
                                        component={NWQuantitySelector}
                                        value={values.QuantityTypeQuantity}
                                        label="Quantity"
                                        name="QuantityTypeQuantity"
                                    />
                                </Col>
                            </Row>
                            {payrollAllowed &&
                                <div className="payroll">
                                    <CustomSwitch
                                        text={`Payroll / Client Advance`}
                                        checked={usePayroll}
                                        onChange={value => setUsePayroll(value)}
                                    />
                                    {usePayroll &&
                                        <div className="payroll-amount">
                                            <Field
                                                component={NWAmountInput}
                                                value={values.ClientAdvance}
                                                label=""
                                                name="ClientAdvance"
                                                fixedCurrency={configurations.defaultCurrency}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false)
                                            onCancel();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </NwForm>
                    )
                }}
            </Formik>
        </Wrapper>
    );
};

export default TransactionAmountForm;