import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Scrollbar from 'react-scrollbars-custom';
import { Switch } from "antd";
import Axios from 'axios';

import ModelsWallHeader from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWallHeader/ModelsWallHeader';
import ModelsWallMiniHeader from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWallHeader/ModelsWallMiniHeader';
import { ModelsWallFooter } from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWallFooter/ModelsWallFooter';
import ModelsTableWrapper from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsTable/ModelsTableWrapper';
import ModelCardsWrapper from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsCards/ModelCardsWrapper';
import StyleVariables from 'Components/Gui/StyleVariables.js';
import EmptyState from 'Components/Gui/EmptyState';
import CenteredBlock from 'Components/Gui/CenteredBlock';
import useLocalStorageState from 'Hooks/UseLocalStorageState';
import { forceReloadModels, modelFullName } from "Hooks/Contact/UseContactsList";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { WallLineItem } from 'Components/Gui/WallLineItem';
import { WallContainer } from 'Components/Gui/StyledComponent';
import { useContactsSelector, useContactsSelectorDispatch } from 'Components/ContactsSelector/Common/ContactsSelectorContext';
import { useModelContext } from 'Contexts/ModelContext';
import { isMinor } from 'Libs/NwMoment';
import { useNavigate } from 'react-router';


const ScrollbarContainer = styled.div`
    border: ${StyleVariables.DefaultBorder};
    border-radius: 4px;
    height: ${props => props.small ? 'calc(100vh - 25rem)' : 'calc(100vh - 20rem)'};
    position: relative;

    .empty-wall-line-item {
        height: 1.75rem;
        width: 100%;
    }

    .list-counter {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: ${props => props.theme.PrimaryColor}DC;
        color: #fff;
        font-size: ${props => props.theme.FontSizeSm};
        padding: .25rem 1rem;
        border-radius: 0 0 4px 4px;
        width: 100%;
        font-weight: bold;
    }
`

const UnderScrollbar = styled.div`
    margin-top: 10px;
    text-align: center;
`;

const ModelsWall = ({
    inSideBar,
    isLoading,
    list,
    listNotFeaturesFiltered,
    tags
}) => {

    const { selectedModel, onSelectModel } = useModelContext();
    const navigate = useNavigate();

    const { selection, additional, actions, layout, context } = useContactsSelector();
    const cs_dispatch = useContactsSelectorDispatch();

    const { area, scope } = context;
    const { listType, quickFilters, activeSet } = selection
    const { view } = layout
    
    const {
        multipleSelectionActive,
        selectedItemsIDs,
    } = actions

    const { disabledItems } = additional

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1
    const [scrollPosition, setScrollPosition] = useLocalStorageState(`modelswall_listScrollPosition`, 0)
    const [motherAgencyInList, setMotherAgencyInList] = useLocalStorageState(`modelswall_motherAgencyInList`, false)
    const [recalcScroll, setRecalcScroll] = useState(false);

    const handleClickOnList = (itemId) => {
        // if (area === "accounting") {
            //window.history.replaceState(null, '', `/model/${itemId}`)
            // navigate(`/model/${itemId}`)
        // } else {
            //window.history.replaceState(null, '', `/model/${itemId}/${listType}/${activeSet}`)
            // navigate(`/model/${itemId}/${listType}/${activeSet}`)
        // }
        //onSelectModel(itemId)
        if (scope === "booking") {
            navigate(`/schedule/${listType}/${activeSet}/${itemId}`)
            //onSelectModel(itemId)
        } else {
            if (area === "accounting") {
                navigate(`/model/${itemId}`)
            } else {
                navigate(`/model/${itemId}/${listType}/${activeSet}`)
            }
        }
    }

    const handleReload = () => {
        forceReloadModels()
    }

    useEffect(() => {
        if (disabledItems) {
            const selectedWithoutDisabled = selectedItemsIDs.filter(modelId => !disabledItems.find(item => item === modelId));
            cs_dispatch({
                type: "UpdateSelectedItems",
                newlist: [...selectedWithoutDisabled]
            })
        }
    }, [disabledItems]);

    useEffect(() => {
        if (recalcScroll) {
            setRecalcScroll(false)
            setScrollPosition(itemScrollPosition() - 100)
        }
    }, [motherAgencyInList])

    useEffect(() => {
        if (list && list.length && selectedModel) { // && !listUpdated
            setScrollPosition(itemScrollPosition() - 100)
        }
    }, [list, selectedModel]);

    const resetScroll = () => {
        setScrollPosition(0)
    }


    const beforeChangeSort = () => {
        setScrollPosition(0)
    };

    const handleScrollStop = (scrollValues) => {
        setScrollPosition(scrollValues.scrollTop)
    }

    const itemScrollPosition = () => {
        const listItemHeight = motherAgencyInList ? 42 : 34
        if (selectedModel) {
            const index = (list.findIndex((item) => {
                return item.ID === Number(selectedModel)
            }));
            if (index > 0) return listItemHeight * index;
        }
        return 0
    }

    const OnAddTag = async (tagID) => {
        await Axios.post(`/contacts/addtagmulti?idt=${tagID.ID}`, selectedItemsIDs)
        handleReload()
    };

    const OnRemoveTag = async tagID => {
        await Axios.post(`/contacts/removetagmulti?idt=${tagID.ID}`, selectedItemsIDs)
        handleReload()
    }

    if (inSideBar) {
        return (
            <>
                <ModelsWallMiniHeader
                    onAfterChangeFilters={resetScroll}
                    tags={tags}
                />
                {list.length ?
                    <>
                        <ScrollbarContainer
                            motherAgencyInList={motherAgencyInList}
                            small={listType === "all-models"}
                        >
                            <Scrollbar
                                onScrollStop={handleScrollStop}
                                scrollTop={scrollPosition}
                                style={{ width: '100%', height: '100%', minHeight: 100 }}
                            >
                                {list.map(item =>
                                    <WallLineItem
                                        isSelected={selectedModel && (item.ID === Number(selectedModel))}
                                        key={item.ID}
                                        onClick={() => handleClickOnList(item.ID)}
                                        text={modelFullName(item)}
                                        prefix={(quickFilters.sort === 'ID') ? item.ID : null}
                                        smallText={item.MAN || (item.R ? "represented" : "no mother agency")}
                                        isMinor={isMinor(item.B)}
                                        showSmallText={motherAgencyInList && !isMobile}
                                    />
                                )}
                                <div className="empty-wall-line-item"></div>
                            </Scrollbar>
                            <div className="list-counter">{list.length} models</div>
                        </ScrollbarContainer>
                        {!isMobile &&
                            <UnderScrollbar>
                                <small>
                                    <Switch
                                        checkedChildren="show"
                                        onChange={() => {
                                            setRecalcScroll(true)
                                            setMotherAgencyInList(!motherAgencyInList)
                                        }}
                                        checked={motherAgencyInList}
                                        unCheckedChildren="hide"
                                    />
                                    &nbsp;mother agencies
                                </small>
                            </UnderScrollbar>
                        }
                    </>
                    :
                    <CenteredBlock fullpage={true}>
                        <EmptyState message="no models fitting your search" />
                    </CenteredBlock>
                }
            </>
        )
    };

    return (
        <WallContainer>
            <ModelsWallHeader
                filteredList={list}
                isLoading={isLoading}
                modelList={listNotFeaturesFiltered}
                beforeChangeSort={beforeChangeSort}
            />
            {(view.current === 'table')
                ?
                <ModelsTableWrapper
                    footer={
                        (breakpoints > 3 && multipleSelectionActive) &&
                        <ModelsWallFooter
                            onAddTag={OnAddTag}
                            onRemoveTag={OnRemoveTag}
                            models={list}
                            isLoading={isLoading}
                        />
                    }
                    isLoading={isLoading}
                    isMobile={isMobile}
                    models={list}
                />
                :
                <ModelCardsWrapper
                    footer={
                        (breakpoints > 3 && multipleSelectionActive) &&
                        <ModelsWallFooter
                            onAddTag={OnAddTag}
                            onRemoveTag={OnRemoveTag}
                            models={list}
                            isLoading={isLoading}
                        />
                    }
                    isLoading={isLoading}
                    models={list}
                />

            }
        </WallContainer>
    )
}

export default ModelsWall;
