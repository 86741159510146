import BillingProjectsFiltersReducer from 'Contexts/BillingProjectsFiltersReducer';
import InvoiceFiltersReducer from 'Contexts/InvoiceFiltersReducer';
import PaymentFiltersReducer from 'Contexts/PaymentFiltersReducer';
import EventFiltersReducer from 'Contexts/EventFiltersReducer';
import AreaReducer from 'Contexts/AreaReducer';
import LoadingReducer from 'Contexts/LoadingReducer';
import TravelPlanFilterReducer from 'Contexts/TravelPlanFilterReducer';
import StatementFilterReducer from 'Contexts/StatementFilterReducer';
import TaskFiltersReducer from 'Contexts/TaskFiltersReducer';
import PackageFiltersReducer from 'Contexts/PackageFiltersReducer';

const NavigationReducer = ({ 
    billingProjectsFiltersContext,
    invoicesFiltersContext,
    paymentsFiltersContext,
    eventFiltersContext,
    areaContext ,
    loadingStatusContext,
    travelPlanFiltersContext,
    statementFiltersContext,
    taskFiltersContext,
    packageFiltersContext,
}, action) => ({
    billingProjectsFiltersContext: BillingProjectsFiltersReducer(billingProjectsFiltersContext, action),
    invoicesFiltersContext: InvoiceFiltersReducer(invoicesFiltersContext, action),
    paymentsFiltersContext: PaymentFiltersReducer(paymentsFiltersContext, action),
    eventFiltersContext: EventFiltersReducer(eventFiltersContext, action),
    areaContext: AreaReducer(areaContext, action),
    loadingStatusContext: LoadingReducer(loadingStatusContext, action),
    travelPlanFiltersContext: TravelPlanFilterReducer(travelPlanFiltersContext, action),
    statementFiltersContext: StatementFilterReducer(statementFiltersContext, action),
    taskFiltersContext: TaskFiltersReducer(taskFiltersContext, action),
    packageFiltersContext: PackageFiltersReducer(packageFiltersContext, action)
});

export default NavigationReducer