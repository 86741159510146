import React, { useState, useEffect } from 'react';

import NwDrawer from 'Components/Gui/NwDrawer';
import { getContactBillingInfo, getContacts } from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils'
import { useProject, onGetOrderedEventList } from 'Hooks/Project/UseProject';
import { Loading } from 'Components/Gui/Loading';
import { useStateValue } from 'Contexts/StateProvider';
import JobFeeForm from 'Components/Transactions/TransactionsCreateForms/JobFeeForm';
import JobExpenseForm from 'Components/Transactions/TransactionsCreateForms/JobExpenseForm';
import JobAdvanceForm from 'Components/Transactions/TransactionsCreateForms/JobAdvanceForm';
import JobRefundForm from 'Components/Transactions/TransactionsCreateForms/JobRefundForm';
import JobCreditForm from 'Components/Transactions/TransactionsCreateForms/JobCreditForm';
import ProductForm from 'Components/Transactions/TransactionsCreateForms/ProductForm';
import AgencyFeeForm from 'Components/Transactions/TransactionsCreateForms/AgencyFeeForm';
import { Segmented } from 'antd';
import BuildingFeature from "Components/Utils/BuildingFeature";
import { TransactionDrawerContent } from './ProjectTransactionsUI';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import ExpenseFormProject from '../../../Transactions/TransactionsCreateForms/ExpenseFormProject';
import { checkTenant } from 'Libs/NwUtils';
import { useUserPermission } from 'Contexts/UserContext';

const AddProjectTransactionDrawer = ({ projectId, transactionGroup, onClose }) => {
    const [touchForm, setTouchForm] = useState(false);
    const { data: currentProject } = useProject(projectId);
    const [contacts, setContacts] = useState([]);
    const [clientBillingInfos, setClientBillingInfos] = useState(null);
    const [{ areaContext }] = useStateValue();

    const [selectedTab, setSelectedTab] = useState(1);

    const accessAccounting = useUserPermission('AccessAccounting');

    useEffect(() => {
        const fetchBillingInfo = async (contactID) => {
            const billingInfos = await getContactBillingInfo(contactID)
            setClientBillingInfos(billingInfos)
        }

        if (currentProject) {
            const events = onGetOrderedEventList(currentProject, areaContext, false, false)
            setContacts(getContacts(currentProject, events))
            fetchBillingInfo(currentProject.CustomerID)
        }
    }, [currentProject]);

    const FamilyCreateForm = ({ }) => {
        if (!currentProject || !contacts || !clientBillingInfos) {
            return <Loading />
        }


        switch (transactionGroup) {
            case 'Fee':
                return (
                    <TransactionDrawerContent>
                        <Segmented
                            block
                            value={selectedTab}
                            onChange={setSelectedTab}
                            options={[
                                {
                                    label: (
                                        <div>
                                            <h2>Model Fee</h2>
                                            <small></small>
                                        </div>
                                    ),
                                    value: 1,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Product / Service</h2>
                                            <small></small>
                                        </div>
                                    ),
                                    value: 2,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Additional Agency Fee</h2>
                                            <small></small>
                                        </div>
                                    ),
                                    value: 3,
                                }

                            ]}
                        />
                        {selectedTab === 1 &&
                            <JobFeeForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 2 &&
                            <ProductForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 3 &&
                            <AgencyFeeForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                    </TransactionDrawerContent>
                )
            case 'Expense':
                const isEliteBooker = checkTenant('elitemodels', true) && !accessAccounting
                if (isEliteBooker) {
                    return (
                        <TransactionDrawerContent>
                            <JobRefundForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        </TransactionDrawerContent>
                    )
                }
                return (
                    <TransactionDrawerContent>
                        <Segmented
                            block
                            value={selectedTab}
                            onChange={setSelectedTab}
                            options={[
                                {
                                    label: (
                                        <div>
                                            <h2>Agency<span><NwIcon icon={light("arrow-right")} /></span>Client</h2>
                                            <small>a billable expense<br />paid by the agency</small>
                                        </div>
                                    ),
                                    value: 1,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Model<span><NwIcon icon={light("arrow-right")} /></span>Client</h2>
                                            <small>a billable expense<br />paid by the model</small>
                                        </div>
                                    ),
                                    value: 2,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Agency<span><NwIcon icon={light("arrow-right")} /></span>Model</h2>
                                            <small>a non billable advance<br />paid by the agency for the model</small>
                                        </div>
                                    ),
                                    value: 3,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Client<span><NwIcon icon={light("arrow-right")} /></span>Model</h2>
                                            <small>an expense paid for<br />the model by the client</small>
                                        </div>
                                    ),
                                    value: 4,
                                }

                            ]}
                        />
                        {selectedTab === 1 &&
                            <JobExpenseForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 2 &&
                            <JobRefundForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 3 &&
                            <ExpenseFormProject
                                currentProject={currentProject}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 4 &&
                            <JobAdvanceForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                    </TransactionDrawerContent>
                )
            case 'Credit':
                return (
                    <TransactionDrawerContent>
                        <Segmented
                            block
                            value={selectedTab}
                            onChange={setSelectedTab}
                            options={[
                                {
                                    label: (
                                        <div>
                                            <h2>Job Credit</h2>
                                            <small>a discount,<br />a tax paid by the client</small>
                                        </div>
                                    ),
                                    value: 1,
                                },
                                {
                                    label: (
                                        <div>
                                            <h2>Model Advance</h2>
                                            <small>a payroll or an advance paid<br />by the client to the model</small>
                                        </div>
                                    ),
                                    value: 2,
                                }
                            ]}
                        />
                        {selectedTab === 1 &&
                            <JobCreditForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                        {selectedTab === 2 &&
                            <JobAdvanceForm
                                currentProject={currentProject}
                                clientBillingInfos={clientBillingInfos}
                                contacts={contacts}
                                //onChangeForm={handleChangeForm}
                                onCloseDrawer={onClose}
                            />
                        }
                    </TransactionDrawerContent>
                )
            default:
                return (<BuildingFeature />)
        }
    }

    return (
        <NwDrawer
            width='60vw'
            minWidth='980px'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={`New ${transactionGroup}`}>
            <FamilyCreateForm />
        </NwDrawer>
    )
};

export default AddProjectTransactionDrawer;