import React from "react";
import { FiltersContainer, FilterItem, ObjectCounter, FilterItemWrapper } from 'Components/Filters/FilterForm/FilterFormUI';
import NwDivider from "Components/Gui/NwDivider";
import { Typography } from "antd";
import NwIcon from "Components/Gui/NwIcon";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";

const ContactsFiltersSections = () => {
    const { global } = useContactsSelector()
    const { filtersSections } = global
    const cs_dispatch = useContactsSelectorDispatch()

    const handleOpenFiltersSection = (areaID) => {
        cs_dispatch({ type: 'OpenFiltersSection', sectionID: areaID })
    }

    return (
        <>
            <NwDivider>available filters</NwDivider>
            <FiltersContainer>
                {filtersSections && filtersSections.length > 0
                    ?
                    (filtersSections.map((section, index) => (
                        <FilterItem key={section.areaID} onClick={() => handleOpenFiltersSection(section.areaID)}>
                            <FilterItemWrapper count={section.counter > 0}>
                                <Typography>{section.areaName}</Typography>
                                {section.counter > 0 &&
                                    <ObjectCounter>{section.counter}</ObjectCounter>
                                }
                            </FilterItemWrapper>
                            <NwIcon icon={light('arrow-to-right')} />
                        </FilterItem>
                    )))
                    :
                    <div>loading filters</div>
                }
            </FiltersContainer>
        </>
    )
}
export default ContactsFiltersSections;