import styled from "styled-components";

export const ContactDetailsContainer = styled.div`
    position: relative;

    .details-header-container {
        position: fixed;
        z-index: 6;
        left: 329px;
        top: 50px;

        border-bottom: 20px solid ${props => props.theme.LayoutBodyBackgroundTransparent};
        border-top: 20px solid ${props => props.theme.LayoutBodyBackgroundTransparent};

        width: calc(100vw - 350px);

        @media screen and (max-height: 920px) {
            position: relative;
            left: 0px;
            top: 0;
            z-index: 0;
            border-bottom: none;
            border-top: none;
        }

        .ant-ribbon {
            font-weight: bold;
            text-transform: uppercase;
            height: 25px;
            line-height: 25px;

            .ribbon-content {
                cursor: pointer;
            }
        }

        .ant-page-header {
            height: ${props => props.customer ? 'unset' : '13rem'};
            border-bottom: ${props => props.theme.DefaultBorder};
            padding: 0;

            @media screen and (max-width: 1440px) {
                height: ${props => props.customer ? 'unset' : '16.5rem'};
            }

            .ant-page-header-heading {
                padding-left: ${props => props.customer ? '2rem' : '11rem'};
                padding-right: 1rem;
                padding-top: .75rem;

                .ant-page-header-heading-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    span.name-icon {
                        margin-right: 12px;
                        font-size: 1rem;
                    }

                    .name {
                        font-size: 2rem;
                        line-height: 1.15;
                        padding-right: 1em;
                        cursor: pointer;

                        &.name-with-alert {
                            margin-left: 24px;
                        }
                    }
                    .header-history {
                        line-height: 1.1em;
                        font-size: .8rem;
                        font-weight: initial !important;
                        cursor: pointer;
                    }
                }
            }

            .ant-page-header-content {
                padding-top: ${props => props.customer ? '0.25rem' : '0.15rem'};

                .header-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-left: ${props => props.customer ? '0' : '10rem'};
                    padding-right: 0;
                    //min-height: 9.1rem;
                    min-height: ${props => props.customer ? '5.2rem' : '9.1rem'};

                    .header-body {
                        width: 100%;
                        min-height: ${props => props.customer ? 0 : '65px'};
                        padding: ${props => props.customer ? '0 0 1rem 2rem' : '0 1rem'};

                        a {
                            color: ${props => props.theme.TextColor};
                        }

                        p {
                            line-height: 1.1em;
                            font-size: .9rem;
                            margin: 0;
                            padding: 0;
                        }

                        .header-body-boxes-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .ant-divider {
                                margin: 0 8px;
                            }

                            &.model-info-line {
                                @media screen and (max-width: 1650px) {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }

                                .header-models-counters {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;

                                    @media screen and (max-width: 1650px) {
                                        margin-top: 0.25em;

                                        .ant-divider:first-child {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            

                            .header-body-boxes {
                                margin-bottom: 0.15em;
                                margin-right: 0;
                                padding: 0.25em 0.5em;
                                text-transform: uppercase;
                                font-size: .9rem;

                                @media screen and (max-width: 1650px) {
                                    padding: 0;
                                }

                                &.header-body-boxes-hoverable {
                                    cursor: pointer;
                                    &:hover {
                                        background-color: ${props => props.theme.SelectedBackgroundColor};
                                        color: #000;
                                    }
                                }
                            }
                        }

                        .header-body-contacts-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-end;
                            margin: 1rem .75rem 0 0;
                            font-size: 1.75rem;

                            @media only screen and (max-width: 1440px) {
                                margin: .5rem 0 0 0;
                            }

                            .header-body-contacts-box {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                width: 100%;
                                /* @media only screen and (max-width: 1440px) {
                                    justify-content: flex-start;
                                } */

                                .header-body-contacts-lines-container {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-end;
                                    justify-content: center;
                                    flex: 1 1 0;
                                    
                                    @media only screen and (max-width: 1440px) {
                                        align-items: flex-start;
                                    }
                                    @media only screen and (max-width: 1280px) {
                                        align-items: flex-end;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        align-items: flex-start;
                                    }

                                    .line-container-left {
                                        font-size: 1rem;
                                        padding-bottom: .1rem;

                                        p {
                                            font-size: 1.1rem;
                                            font-weight: 600;
                                            cursor: pointer;

                                            @media only screen and (max-width: 1440px) {
                                                font-size: .9rem;
                                            }
                                        }
                                    }
                                }
                            }

                            
                            
                        }
                    }
                }
            }

            .header-footer {
                /* padding-bottom: 6px; */
                border-top: ${props => props.theme.DefaultBorder};
                padding: 0 1rem;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .features-container {
                    display: flex;
                    align-items: center;
                }
                .icons-container {
                    display: flex;
                    align-items: center;

                    svg {
                      margin: 0 .5rem;
                    }
                }
            }
        }

        &.no-sidebar {
            left: 80px;
            width: calc(100vw - 104px);

            @media screen and (max-height: 920px) {
                left: 4px;
            }

            .ant-page-header {
                height: ${props => props.customer ? 'unset' : '13rem'};

                @media screen and (max-width: 1190px) {
                    height: ${props => props.customer ? 'unset' : '16.5rem'};
                }
            }
        }

        &.details-header-container-compact {
            .ant-page-header {
                height: ${props => props.customer ? 'unset' : '16rem'};

                .header-content {
                    min-height: ${props => props.customer ? 'unset' : '9.2rem'};

                    .header-body {
                        .header-body-contacts-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                }

                .ant-page-header-heading {
                    .header-history {
                        .history-details {
                            display: none;
                        }
                    }
                }
            }
        }

        &.details-header-container-drawer {
            position: relative;
            left: 0px;
            top: 0;
            z-index: 0;
            border-bottom: none;
            border-top: none;
            width: auto;

            .ant-page-header {
                .ant-page-header-content {
                    .header-content {
                        .header-body {
                            .header-body-boxes-container {
                                &.model-info-line {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    .header-models-counters {
                                        margin-top: 0.25em;

                                        .ant-divider:first-child {
                                            display: none;
                                        }
                                    }
                                }
                                .header-body-boxes {
                                    padding: 0;
                                }
                            }
                            .header-body-contacts-container {
                                margin: .5rem 0 0 0;
                                .header-body-contacts-box {
                                    .header-body-contacts-lines-container {
                                        align-items: flex-start;
                                        .line-container-left {
                                            p {
                                               font-size: .9rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .header-footer {
                    .features-container {
                    }
                    .icons-container {
                        svg {
                        }
                    }
                }
            }

            .ant-page-header {
                height: auto;

                .header-content {
                    /* min-height: ${props => props.customer ? 'unset' : '9.2rem'}; */
                    .header-body {
                        .header-body-contacts-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    .details-content {
        margin-top: ${props => props.collapseView ? '236px' : '210px'};

        &.details-content-compact {
            margin-top: ${props => props.collapseView ? '240px' : '260px' };

            @media screen and (max-height: 920px) {
                margin-top: 20px;
            }
        }

        @media screen and (max-height: 920px) {
            margin-top: 20px;
        }
    }

    .customer-details-content {
        margin-top: ${props => props.customer ? '152px' : '214px'};

        &.details-content-compact {
            margin-top: ${props => props.customer ? '152px' : '260px'};
            @media screen and (max-height: 920px) {
                margin-top: 20px;
            }
        }

        @media screen and (max-height: 920px) {
            margin-top: 20px;
        }
    }
`;
