import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, notification } from 'antd';
import Axios from 'axios';
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInput, NWTextAreaCharCounter, NWNonBookingEventSelectorForTransactions, NWDatePicker } from "Components/Gui/NwForm/NwFormItems";
import { forceReloadProject, onGetOrderedEventList } from 'Hooks/Project/UseProject';
import useConfigurations from 'Hooks/UseConfigurations';
import { ProjectTransactionFormContainer } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { Loading } from 'Components/Gui/Loading';
import ModelSelectorNonBookingDates from './Common/ModelSelectorNonBookingDates';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import { forceReloadProjectStatements } from 'Hooks/Project/UseProjectStatements';
import { useStateValue } from 'Contexts/StateProvider';
import { getGroupedModelEvent, getGroupedModelProject, handleChangeEventForDate } from '../../Project/ProjectTransactions/Transactions/TransactionCreateFormUtils';
import AmountLine from './Common/AmountLine';
import ServiceLine from './Common/ServiceLine';
import useTransactionLabels from 'Hooks/UseTransactionLabels';
import TransactionLabelsSelector from './Common/TransactionLabelsSelector';
import FixedSaveAndCancelBar from './Common/FixedSaveAndCancelBar';
import { datePickerToApi, getNow } from 'Libs/NwMoment';
import { getMoment } from "Libs/NwMoment";


const ExpenseFormProject = ({ currentProject, onCloseDrawer }) => {  //onChangeForm
    
    const transactionProperties = useTransactionLabels("Expense");
    const getTransactionLabel = transactionLabelID => transactionProperties.labels.find(label => label.ID === transactionLabelID);
    const defaultTypeQuantity = transactionProperties.startLabel.TypeQuantityName ? transactionProperties.startLabel.TypeQuantityName : "Units"

    const configurations = useConfigurations();

    const [{ areaContext }] = useStateValue();
    const events = onGetOrderedEventList(currentProject, areaContext, false, false);

    const [groupedModelDates, setGroupedModelDates] = useState(getGroupedModelProject(currentProject));

    const saveTransaction = async (values, setSubmitting) => {

        //TODO: validazione: se c'è evento ci deve essere modella

        let apiData = {
            StatementType: 'Model',
            TransactionLabelID: values.TransactionLabelID,
            Title: values.Title,
            Description: values.Description,
            Quantity: values.Quantity[0],
            TypeQuantity: values.Quantity[1],
            Currency: values.Amount[0],
            SingleFee: values.Amount[1],
            RefDate: values.RefDate && datePickerToApi(values.RefDate)
        }
        if (values.ServiceID) {
            apiData.ServiceID = values.ServiceID;
            if (values.AgencyCost) {
                apiData.AgencyCost = values.AgencyCost[1];
            } else {
                apiData.AgencyCost = values.SingleFee[1];
            }
        }

        let models = [];
        for (const groupedModel of groupedModelDates) {
            if (groupedModel.active) {
                const modelData = {
                    ContactID: groupedModel.ModelID,
                    ContactBillingInfoID: null,
                    ProjectID: currentProject.ID
                }
                if (values.SelectedEvent) {
                    const modelEvent = values.SelectedEvent.Models.find(mod => mod.ModelID === groupedModel.ModelID);
                    if (modelEvent) {
                        modelData[values.SelectedEvent.elType + "ID"] = modelEvent[values.SelectedEvent.elType + "ID"];
                    }
                }
                models.push(modelData)
            }
        }
        if (models.length === 0) {
            setSubmitting(false);
            notification.error({
                message: 'Error',
                description: 'Please select at least one model',
                placement: 'bottomRight',
                bottom: 100,
                duration: 0
            });
        } else {
            apiData = {
                ...apiData,
                Contacts: [...models]
            }
            try {
                await Axios.post('/transactions/expense', apiData);
                forceReloadProject(currentProject.ID);
                forceReloadProjectAccountingDetails(currentProject.ID);
                forceReloadProjectTransactions(currentProject.ID);
                forceReloadProjectStatements(currentProject.ID);
                setSubmitting(false);
                onCloseDrawer();
            } catch (error) {
                console.log('ant : Create Expense Error => ', error);
                setSubmitting(false);
            }
        }
    };

    const updateModelSelector = (eventselected) => {
        if (eventselected) {
            if (eventselected.elType === 'Meeting') {
                setGroupedModelDates([])
            } else {
                setGroupedModelDates(getGroupedModelEvent(eventselected));
            }
        } else {
            setGroupedModelDates(getGroupedModelProject(currentProject));
        }
    }

    const initialValues = {
        TransactionLabelID: transactionProperties.startLabel.ID,
        Title: transactionProperties.startLabel.Label,
        ServiceID: null,
        SelectedEvent: null,
        Description: '',
        Amount: [configurations.defaultCurrency, null],
        AgencyCost: [configurations.defaultCurrency, null],
        Quantity: [1, defaultTypeQuantity],
        RefDate: (currentProject.FirstDate ? getMoment(currentProject.FirstDate) : getNow())
    }

    return (
        currentProject
            ?
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Title is required"),
                    TransactionLabelID: Yup.string().required("Label is required")
                })}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveTransaction(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit, touched, isValid }) => (
                    <NwForm
                        values={values}
                        //onTouchForm={onChangeForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <ProjectTransactionFormContainer>
                            <Row gutter={32}>
                                <Col span={14} className='rate-form'>
                                    <Field
                                        component={NWNonBookingEventSelectorForTransactions}
                                        label="Event"
                                        name="SelectedEvent"
                                        events={events}
                                        onAfterChange={(value) => {
                                            if (value) {
                                                const labelSelected = values.TransactionLabelID;
                                                const transactionLabel = getTransactionLabel(labelSelected)
                                                setFieldValue('Title', `${transactionLabel.Label} for ${value.Title}`);
                                                handleChangeEventForDate(value, setFieldValue);
                                            }
                                            updateModelSelector(value)
                                        }}
                                    />
                                    <TransactionLabelsSelector 
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        transactionProperties={transactionProperties} 
                                        updateTitle
                                    />
                                    <Field
                                        component={NWDatePicker}
                                        label="Date"
                                        name="RefDate"
                                        format="MMM DD, YYYY"
                                        allowClear={false}
                                        value={values.RefDate}
                                    />
                                    <Field
                                        component={NWInput}
                                        label="Title"
                                        name="Title"
                                        type="text"
                                        value={values.Title}
                                        maxLength={255} 
                                    />
                                    <Field
                                        component={NWTextAreaCharCounter}
                                        label="Description"
                                        name="Description"
                                        type="text"
                                        value={values.Description}
                                        maxLength={2500}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                    />
                                    <AmountLine
                                        values={values}
                                        touched={touched}
                                        onChangeAmount={
                                            (value) => {
                                                if (!touched.AgencyCost) {
                                                    setFieldValue('AgencyCost', [value[0], value[1]])
                                                }
                                            }
                                        }
                                        amountLabel="Expense Amount"
                                    />
                                    <ServiceLine values={values} setFieldValue={setFieldValue} hasAgencyCost />
                                </Col>
                                <Col span={10}>
                                    <ModelSelectorNonBookingDates
                                        models={groupedModelDates}
                                        updateModels={setGroupedModelDates}
                                        selectedEvent={values.SelectedEvent}
                                    />
                                </Col>
                            </Row>
                        </ProjectTransactionFormContainer>
                        <FixedSaveAndCancelBar
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            onCancel={() => {
                                //onChangeForm(false);
                                onCloseDrawer();
                            }}
                        />
                    </NwForm>
                )}
            </Formik>
            :
            <Loading />
    )
};

export default ExpenseFormProject;