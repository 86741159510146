import React, { useContext } from 'react';
import { HeaderContext } from 'Components/_LayoutComponents/Header/NwHeader';
import { NavMenu, NwNavIcon } from 'Components/_LayoutComponents/Header/NwHeaderUI';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Popover } from 'antd';
import { getNavClassName, getNavMenuMode } from './NwNavUtils';



const NwNavBarAccounting = ({
    allLogs,
    createNewItemMenu,
    current,
    drawer,
    handleChangeOpenKeys,
    handleClick,
    isLoaded,
    isOpen,
    lastSeenMenu,
    openKeys,
}) => {

    // const managementClientsReportsAllowed = useUserPermission('ViewManagementClientsReports');
    // const managementModelsReportsAllowed = useUserPermission('ViewManagementModelsReports');
    // const managementDepartmentsReportsAllowed = useUserPermission('ViewManagementDepartmentsReports');
    // const managementBookersReportsAllowed = useUserPermission('ViewManagementBookersReports');
    // TEMP: riattivare permessi
    const managementClientsReportsAllowed = true;
    const managementModelsReportsAllowed = true;
    //const managementDepartmentsReportsAllowed = true;
    const managementBookersReportsAllowed = true;

    const balanceReportAllowed = true;

    const headerContextValues = useContext(HeaderContext);
    const { device } = headerContextValues;
    const { modelLogs, customerLogs, statementLogs } = allLogs;


    const navItems = [
        { key: "/accounting", label: "Dashboard", disabled: !isLoaded },
        {
            //key: "/accounting-jobs",
            popupClassName: "main-nav-submenu",
            disabled: !isLoaded,
            label: "Billing",
            children: [
                {
                    type: "group",
                    //key: "/menu-tools-models",
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Create Invoices
                        </span>
                    ),
                    children: [
                        { key: "/billing/projects", label: "Jobs / Projects", disabled: !isLoaded },
                        { key: "/billing/expenses", label: "Model Expenses", disabled: !isLoaded },
                        { key: "/billing/commissions", label: "Commissions As Mother Agency", disabled: !isLoaded },
                    ]
                },
            ]
        },
        {
            key: "/menu-invoice",
            label: "Invoices",
            popupClassName: !isOpen('/menu-invoice') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/invoices' }),
            children: [
                lastSeenMenu(statementLogs, "invoice-drawer", "no statements", "customerName"),
                {
                    type: "group",
                    key: `/menu-tools-projects`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/invoices',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("clipboard-list-check")} />Invoices Search
                                </div>
                            )
                        }
                    ]
                },
                createNewItemMenu('Statement')
            ]
        },
        {
            key: "Statements",
            disabled: !isLoaded,
            label: "Statements",
            popupClassName: "main-nav-submenu",
            children: [
                {
                    key: "/balance-report/models",
                    disabled: !balanceReportAllowed,
                    label: balanceReportAllowed ? "Models Balance Report" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;Models Balance Report
                            </div>
                        </Popover>
                },
                {
                    key: "/balance-report/agencies",
                    disabled: !balanceReportAllowed,
                    label: balanceReportAllowed ? "Agencies Balance Report" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;Models Balance Report
                            </div>
                        </Popover>
                },
                {
                    key: "/payments/models",
                    label: "Models Payments"
                },
                {
                    key: "/payments/agencies",
                    label: "Agencies Payments"
                },
            ]
        },
        {
            label: "Reports",
            disabled: !isLoaded,
            popupClassName: "main-nav-submenu",
            children: [
                {
                    key: "/accounting-reports/clients",
                    disabled: !managementClientsReportsAllowed,
                    label: managementClientsReportsAllowed ? "On Clients" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;On Clients
                            </div>
                        </Popover>
                },
                {
                    key: "/accounting-reports/models",
                    disabled: !managementModelsReportsAllowed,
                    label: managementModelsReportsAllowed ? "On Models" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;On Models
                            </div>
                        </Popover>
                },
                {
                    key: "/accounting-reports/bookers",
                    disabled: !managementBookersReportsAllowed,
                    label: managementBookersReportsAllowed ? "On Bookers" :
                        <Popover
                            zIndex={1050}
                            content="Your current Netwalk profile is not allowed to access this section">
                            <div style={{ opacity: 0.4 }}>
                                <NwNavIcon icon={light('ban')} />&nbsp;On Bookers
                            </div>
                        </Popover>
                },
            ]
        },
        {
            key: "/accounting/models",
            label: "Models",
            popupClassName: "main-nav-submenu",
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/accounting/models' }),
            children: [
                lastSeenMenu(modelLogs, 'model', 'no models'),
                createNewItemMenu('Model')
            ]
        },
        {
            key: "/accounting/customers",
            label: "Contacts",
            popupClassName: "main-nav-submenu",
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/accounting/customers' }),
            children: [
                lastSeenMenu(customerLogs, 'customer', 'no contacts'),
                createNewItemMenu('Customer')
            ]
        },
        { key: "/accounting/admin/transactions", label: "Settings", disabled: !isLoaded }
    ]

    const keysWithSubmenus = navItems.filter(item => item.children).map(item => item.key);

    return (
        <NavMenu
            $device={device}
            className={getNavClassName(device, navItems.length)}
            inlineIndent={10}
            items={navItems}
            mode={getNavMenuMode(drawer, device)}
            onClick={handleClick}
            onOpenChange={(k) => handleChangeOpenKeys(k, keysWithSubmenus)}
            openKeys={openKeys}
            selectedKeys={[current]}
        />
    )
}

export default NwNavBarAccounting;