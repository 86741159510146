import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Layout } from "antd";
import _ from 'lodash';
import ModelsWall from "Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWall";
//import NavigationBack from 'Components/ContactsSelector/Common/NavigationBack';
import { useModelsList, usePlaylistsCount, modelFullName } from "Hooks/Contact/UseContactsList";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { UserContext } from 'Contexts/UserContext';
import { StyledLayout } from "Components/Gui/StyledComponent";
import { getModelInTown } from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWallUtils';
import { useDebounce } from 'react-use';
import Axios from "axios";
import { convertFiltersToParams } from "Components/Filters/FilterForm/FilterUtils";
import { useContactsSelector, useContactsSelectorDispatch } from "../Common/ContactsSelectorContext";
import NwDrawer from "Components/Gui/NwDrawer";
import { useModelContext } from "Contexts/ModelContext";
import { useFolders } from "Contexts/FoldersContext";
import ContactsFiltersSidebar from "Components/ContactsSelector/Common/ContactsFiltersSidebar";
import { deaccentizeandlowerize } from "Libs/NwUtils";

const { Content, Sider } = Layout;

const SiderStyled = styled(Sider)`
    height: calc(100vh - 60px);
    background-color: #fff;
    z-index: 1030;
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
        0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
        0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
        0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
    position: fixed;
    padding: 1em;
    overflow: auto;
`;

const SelectorLayout = styled(Layout)`
    margin-left: ${props => props.$size === 'big' ? '360px' : (props.$size === 'small' ? '220px' : '300px')};
    margin-left: ${props => props.$type === 'compact' && 0};
`;

const WallWrapper = styled.div`
    position: relative;
`;

const ModelsSelector = ({ }) => {

    const { selectedModel, onSelectListType, onSelectActiveSet } = useModelContext();

    const { selection, context, additional, global } = useContactsSelector();
    const cs_dispatch = useContactsSelectorDispatch()

    const {
        activeSet,
        filters,
        listType,
        quickFilters,
    } = selection;

    // console.log("ModelsSelector");
    // console.log("activeSet", activeSet);
    // console.log("listType", listType);

    const {
        area,
        currentPackage,
        currentUserID,
        projectId,

        scope,
        selectorAction,
        selectorActionID,
    } = context;

    const { showOnlyModelsWithBooks, startOnProjectModels, } = additional;

    const { featuresList } = global;

    const folders = useFolders()

    const location = useLocation();

    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1;
    const { data: fullList, isFetching: fetchingItems } = useModelsList()
    const { data: playlistsCount } = usePlaylistsCount()

    //const [listUpdated, setListUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [projectItems, setProjectItems] = useState([]);
    //const [editFolder, setEditFolder] = useState(false);
    const [itemsList, setItemsList] = useState([]);
    const [modelsListNotFeaturesFiltered, setModelsListNotFeaturesFiltered] = useState([]);

    //const [{ folderContext }, areaContext] = useStateValue();

    const { currentUser } = useContext(UserContext);

    const siderWidth = startOnProjectModels ? 360 : (breakpoints < 3 ? 200 : 300)
    const layoutSize = startOnProjectModels ? 'big' : (breakpoints < 3 ? 'small' : 'medium')

    const setListType = (listtype) => {
        cs_dispatch({
            type: 'ChangeListType',
            listType: listtype
        })
        if (onSelectListType) {
            onSelectListType(listtype)
        }
    }

    useEffect(() => {
        if (projectId && startOnProjectModels) {
            const getProjectModels = async () => {
                const prjmod = await Axios.get(`/projectmodels/getforproject/${projectId}`);
                const ModelIDs = prjmod.map(pm => pm.ModelID);
                if (ModelIDs.length > 0) {
                    setListType("project")
                    setProjectItems(ModelIDs);
                } else {
                    setListType("all-models")
                }
            }
            getProjectModels();
        }
    }, []) //projectId, startOnProjectModels, scope

    const [filteredIDs, setFilteredIDs] = useState(null)
    const [fetchingWallIds, setFetchingWallIds] = useState(false)

    const fetchWallIds = async () => {
        setFetchingWallIds(true)
        const modelTypeToSearch = (area === "scouting") ? "Scouting" : "Booking" 
        const convertedFilters = convertFiltersToParams(filters.active, featuresList, currentUserID)
        const postParams = {
            ...convertedFilters,
            ModelType: modelTypeToSearch,
            Departments: currentUser.ActiveContext.activeDepartments
        }
        const wallids = await Axios.post("/models/wall-id", postParams)
        setFilteredIDs(wallids)
        setFetchingWallIds(false)
    }

    const filterItemsByFolder = () => {
        const folder = folders.openFolders.find(folder => folder.ID.toString() === activeSet);
        if (folder) {
            setFilteredIDs(folder.items)
        }
    }

    useEffect(() => {
        if (listType === "smart-lists" && activeSet && filters && filters.active) {
            fetchWallIds()
        }
        if (listType === "folders") {
            filterItemsByFolder()
        }
    }, [filters.active, listType, activeSet, folders])

    useEffect(() => {
        if ((listType === "smart-lists" && activeSet && filters && filters.active) || listType === "folders") {
            if (filteredIDs && Array.isArray(filteredIDs)) {
                cs_dispatch({
                    type: "UpdateSavedWallIDs",
                    savedWallIDs: [...filteredIDs]
                })
            }
        }
    }, [filteredIDs, folders])

    const [debouncedNameFilter, setDebouncedNameFilter] = useState(quickFilters.nameFilter);
    useDebounce(() => {
        if (quickFilters.nameFilter !== debouncedNameFilter) {
            setDebouncedNameFilter(quickFilters.nameFilter)
        }
    }, 300, [quickFilters.nameFilter]);

    useEffect(() => {
        if (selectorAction === 'find-similar-models' && selectorActionID) {
            cs_dispatch({
                type: "ChangeQuickFilters",
                quickFilters: {
                    nameFilter: "",
                    intown: "all",
                    onlymine: false,
                    tagFilter: [],
                    sort: "Name",
                    sortFolder: "Name",
                    gender: "all",
                    showFeaturesQuickFilters: true,
                    featuresQuickFilters: {}
                }
            })
        }
    }, [location])

    const isLoadingItems = () => {
        if (fetchingItems || fetchingWallIds) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (!isLoadingItems()) {
            let areaFullList = fullList ? area === 'scouting'
                ? fullList.filter(model => model.ST === 5)
                : fullList.filter(model => model.ST === 4) : [];
            let templist = []
            if (listType === "folders") {
                if (filteredIDs && filteredIDs.length) {
                    const filteredItems = areaFullList.filter(item => filteredIDs.includes(item.ID));
                    templist = filteredIDs.map(id => filteredItems.find(item => item.ID === id)).filter(item => item !== undefined);
                } else {
                    templist = [];
                }
            } else {
                if (listType === "project") {
                    templist = areaFullList.filter(model => projectItems.includes(model.ID))
                } else {
                    if (listType === "all-models") {
                        const activeDepartments = currentUser.ActiveContext.activeDepartments
                        templist = areaFullList.filter(model => model.DEPS.some(departmentId => activeDepartments.includes(departmentId)))
                    }
                    if (listType === "smart-lists") {
                        if (!filteredIDs) {
                            templist = areaFullList
                        } else {
                            if (filteredIDs.length) {
                                templist = areaFullList.filter(item => filteredIDs.includes(item.ID))
                            }
                            else {
                                templist = [];
                            }
                        }
                    }
                }
            }

            //if it's "find similar models", remove the current model from the list
            if (selectorAction === 'find-similar-models' && selectorActionID) {
                templist = templist.filter((el) => el.ID !== parseInt(selectorActionID))
            }

            // quick filter
            if (listType === "all-models") { // quick search
                const { intown, onlymine, tagFilter, gender } = quickFilters;
                let normalizedNameFilter = debouncedNameFilter ? deaccentizeandlowerize(debouncedNameFilter) : null;
                
                templist = templist.filter(item => {
                    let result = true;
                    if (normalizedNameFilter) { //nameFilter
                        const normalizedItemName = deaccentizeandlowerize(item.N);
                        const normalizedItemSurname = item.S ? deaccentizeandlowerize(item.S) : "";
                        result = (normalizedItemName.startsWith(normalizedNameFilter)) || (normalizedItemSurname && normalizedItemSurname.startsWith(normalizedNameFilter)) || ((normalizedItemName + ' ' + normalizedItemSurname).startsWith(normalizedNameFilter))
                    }
                    if (tagFilter && tagFilter.length) {
                        tagFilter.map(tag => result = result && item.T.map(tag => tag.Name).includes(tag))
                    }
                    return result;
                })

                switch (intown) {
                    case "in":
                        templist = templist.filter(model => getModelInTown(model.ITS));
                        break;
                    case "out":
                        templist = templist.filter(model => !getModelInTown(model.ITS));
                        break;
                    default:
                        break;
                }

                switch (gender) {
                    case "female":
                        templist = templist.filter(model => model.G === 'Female');
                        break;
                    case "male":
                        templist = templist.filter(model => model.G === 'Male');
                        break;
                    case "non-binary":
                        templist = templist.filter(model => model.G === 'NonBinary');
                        break;
                    default:
                        break;
                }

                if (onlymine) {
                    templist = templist.filter(model => {
                        if (model.U.find(user => user.UserID === currentUser.UserID)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
            }

            //if it's package, remove models without books (if the option is selected)
            if (currentPackage && showOnlyModelsWithBooks) {
                if (playlistsCount && playlistsCount.length) {
                    const modelsWithBooksIDs = playlistsCount.map(pc => pc.ID)
                    templist = templist.filter(model => modelsWithBooksIDs.includes(model.ID))
                } else {
                    templist = []
                }
            }

            setModelsListNotFeaturesFiltered([...templist])
            if (listType === "all-models") {
                // quick filters on features active
                const { showFeaturesQuickFilters, featuresQuickFilters } = quickFilters;
                if (showFeaturesQuickFilters) {
                    templist = templist.filter(model => model.ST).filter(model => {
                        const modelFeature = model.F ? JSON.parse(model.F) : []
                        const features = [];
                        if (modelFeature) {
                            for (const feature of modelFeature) {
                                if (feature.Features.length) {
                                    for (const subFeature of feature.Features) {
                                        features.push(subFeature);
                                    }
                                }
                            }
                        }
                        let filtered = true;
                        for (const filterKey of Object.keys(featuresQuickFilters)) {
                            if (featuresQuickFilters[filterKey]) {
                                const feature = features.find(feature => feature.FeatureID === filterKey);
                                if (feature) {
                                    if (feature.TypeName === 'Choice' && !featuresQuickFilters[filterKey].find(value => value === feature.Value)) {
                                        filtered = false;
                                        break;
                                    }
                                    if (feature.TypeName === 'Conversion') {
                                        const featureValue = parseInt(feature.Value);
                                        if (featuresQuickFilters[filterKey][0] > featureValue || featuresQuickFilters[filterKey][1] < featureValue) {
                                            filtered = false;
                                            break;
                                        }
                                    }
                                    if (feature.TypeName === 'Numeric') {
                                        const featureValue = parseFloat(feature.Value);
                                        if (featuresQuickFilters[filterKey][0] > featureValue || featuresQuickFilters[filterKey][1] < featureValue) {
                                            filtered = false;
                                            break;
                                        }
                                    }
                                    if (feature.TypeName === 'Rating') {
                                        const modelRating = parseInt(feature.Value);
                                        if (modelRating < featuresQuickFilters[filterKey]) {
                                            filtered = false;
                                        }
                                    }
                                } else {
                                    filtered = false;
                                    break;
                                }
                            }
                        }
                        return filtered;
                    })
                }
            }

            const sortByName = (list) => {
                return list.sort((a, b) => modelFullName(a).localeCompare(modelFullName(b), 'en', {
                    caseFirst: 'upper'
                }));
            }

            //sorting models
            if (isMobile) {
                templist = sortByName(templist)
            } else {
                if (listType === 'folders') {
                    if (quickFilters.sortFolder === "Name") { //!editFolder && 
                        templist = sortByName(templist)
                    }
                } else {
                    switch (quickFilters.sort) {
                        case "MotherAgency":
                            templist = _.sortBy(templist, [function (o) { return o.MAN ? o.MAN.trim().toLowerCase() : 'zzz' }])
                            break
                        case 'ID':
                            templist = _.sortBy(templist, [function (o) { return o.ID }])
                            break
                        default:
                            templist = sortByName(templist)
                    }
                }
            }
            setItemsList([...templist])
        }
    }, [listType, fullList, filteredIDs, activeSet, fetchingItems, fetchingWallIds, quickFilters, debouncedNameFilter, folders, playlistsCount, showOnlyModelsWithBooks, projectItems])


    useEffect(() => {
        if (currentPackage) {
            if (itemsList && itemsList.length > 0) {
                if (playlistsCount && playlistsCount.length > 0) {
                    for (const pc of playlistsCount) {
                        const model = itemsList.find(model => model.ID === pc.ID)
                        if (model) {
                            model.FP = pc.FP
                            model.PL = pc.PL
                        }
                    }
                }
            }
        }
    }, [currentPackage, itemsList, playlistsCount])

    let tags = useMemo(() => {
        let tags = [];
        itemsList.map(model => {
            if (model.T) {
                model.T.map(tag => {
                    if (!tags.includes(tag.Name)) {
                        tags.push(tag.Name)
                    }
                    return null;
                })
            }
            return null;
        });

        tags.sort();
        return tags;
    }, [itemsList]);

    const getWallProps = (sidebar) => {
        const wallProps = {
            inSideBar: sidebar,
            isLoading: isLoading || fetchingWallIds,
            list: itemsList,
            listNotFeaturesFiltered: modelsListNotFeaturesFiltered,
            tags
        }
        return wallProps;
    }

    const getFiltersProps = {
        //navigateUrl,
        //onEditFolder: setEditFolder,
        projectModels: projectItems,
        tags,
        itemType: 'model',
        selectedItem: (selectedModel ? true : false)
    }

    if (!selectedModel && scope !== 'booking') {
        return (
            <>
                {/* <NavigationBack navigationTitle={navigationTitle} navigationUrl={navigateUrl} /> */}
                <StyledLayout>
                    {/*$navigation={navigateUrl}  */}
                    {(breakpoints > 2)
                        ?
                        <SiderStyled width={siderWidth}>
                            <ContactsFiltersSidebar {...getFiltersProps} />
                        </SiderStyled>
                        :
                        <NwDrawer
                            visible={false}
                        >
                            <ContactsFiltersSidebar {...getFiltersProps} />
                        </NwDrawer>
                    }
                    <SelectorLayout $size={layoutSize} $type={breakpoints < 3 && 'compact'}>
                        <Content>
                            <WallWrapper>
                                <ModelsWall {...getWallProps()} />
                            </WallWrapper>
                        </Content>
                    </SelectorLayout>
                </StyledLayout>
            </>
        );
    } else {
        return (
            <>
                <ContactsFiltersSidebar {...getFiltersProps} />
                <ModelsWall {...getWallProps(true)} />
            </>
        );
    }
};

export default ModelsSelector
