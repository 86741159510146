import React, { useState } from "react";
import { isArray } from "lodash";
import { findFeature } from "Components/Filters/FilterForm/FilterUtils";
import FeaturesBool from "Components/Gui/NwForm/FeaturesBool";
import RatingSelector from "Components/Gui/NwForm/RatingSelector";
import NumericRangeSelector from "Components/Gui/NwForm/NumericRangeSelector";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import styled from "styled-components";
import FilterClearButton from "Components/ContactsSelector/Common/FilterClearButton";
import FeaturesMultichoice from "Components/Gui/NwForm/FeaturesMultichoice";
import FeaturesChoice from "Components/Gui/NwForm/FeaturesChoice";
import FeaturesRangeFilter from "Components/Gui/NwForm/FeaturesRangeFilter";
import NwIcon from "Components/Gui/NwIcon";
import FeaturesRangeSliderFilter from "Components/Gui/NwForm/FeaturesRangeSliderFilter";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useUserGuiConfig, useUserGuiConfigDispatch } from "Contexts/UserGuiConfigContext";
import { Input } from "antd";


const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    .filter-field {
        flex: 1 1 0;
        padding-right: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;

        .slider-dropdown-selector {
            width: 3rem;
            text-align: center;
        }
        .slider-dropdown-control {
            flex: 1 1 0;
            width: 100%;
        }
    }
    .clear-button-wrapper {
        vertical-align: top;
    }
`

const ModelFilterItemFeature = ({ filter }) => {


    const { global, selection } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection
    const { featuresList } = global;

    const feature = findFeature(filter.name, featuresList)

    const featureNameNormalized = feature ? feature.Name.replace(/ /g, '_') : null

    const userGuiConfig = useUserGuiConfig()
    const ugc_dispatch = useUserGuiConfigDispatch()


    const formSettings = userGuiConfig.formSettings;
    let useSliderFromConfig = false
    if (featureNameNormalized && formSettings && formSettings[featureNameNormalized]) {
        if (formSettings[featureNameNormalized].useSlider) {
            useSliderFromConfig = true
        }
    }
    const [useSlider, setUseSlider] = useState(useSliderFromConfig)


    let filterValue = filter.defaultval

    const workingFilter = filters.working.find(f => f.name === filter.name)
    if (workingFilter) {
        filterValue = workingFilter.value
    }

    const onClearField = () => {
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === filter.name)
        if (filterIndex !== -1) {
            newWorkingFilters.splice(filterIndex, 1)
            cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
        }
    }

    const onChange = (val) => {
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === filter.name)
        if (val) {
            if (isArray(val)) {
                if (val.length > 0) {
                    if (filterIndex === -1) {
                        newWorkingFilters.push({ name: filter.name, value: val, source: 'feature' })
                    } else {
                        newWorkingFilters[filterIndex].value = val
                    }
                } else {
                    if (filterIndex !== -1) {
                        newWorkingFilters.splice(filterIndex, 1)
                    }
                }
            } else {
                if (filterIndex === -1) {
                    newWorkingFilters.push({ name: filter.name, value: val, source: 'feature' })
                } else {
                    newWorkingFilters[filterIndex].value = val
                }
            }
        } else {
            if (filterIndex !== -1) {
                newWorkingFilters.splice(filterIndex, 1)
            }
        }
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
    }

    const updateUserGuiConfigLocalAndRemote = (newUserGuiConfig) => {
        ugc_dispatch({ type: 'Update', value: newUserGuiConfig })
    }

    const removeUseSlider = () => {
        const usergui = { ...userGuiConfig };
        if (featureNameNormalized) {
            if (usergui.formSettings[featureNameNormalized]) {
                delete usergui.formSettings[featureNameNormalized]
                updateUserGuiConfigLocalAndRemote(usergui)
            }
            setUseSlider(!useSlider)
        }
    }

    const addUseSlider = () => {
        const usergui = { ...userGuiConfig };
        if (featureNameNormalized) {
            if (!usergui.formSettings) {
                usergui.formSettings = {}
            }
            if (!usergui.formSettings[featureNameNormalized]) {
                usergui.formSettings[featureNameNormalized] = {}
            }
            usergui.formSettings[featureNameNormalized].useSlider = true
            updateUserGuiConfigLocalAndRemote(usergui)
            setUseSlider(!useSlider)
        }
    }

    return (
        <FieldWrapper>
            <div className="filter-field">
                {feature.TypeName === 'Conversion' && (
                    useSlider
                        ?
                        <>
                            <div className="slider-dropdown-selector">
                                <NwIcon pointer icon={light("list-dropdown")} onClick={removeUseSlider} />
                            </div>
                            <div className="slider-dropdown-control">
                                <FeaturesRangeSliderFilter
                                    key={filter.name}
                                    feature={feature}
                                    value={filterValue || []}
                                    onChange={onChange}
                                />
                            </div>
                        </>
                        :
                        <>
                            <div className="slider-dropdown-selector">
                                <NwIcon pointer icon={light("sliders-simple")} onClick={addUseSlider} />
                            </div>
                            <div className="slider-dropdown-control">
                                <FeaturesRangeFilter
                                    key={filter.name}
                                    feature={feature}
                                    value={filterValue || []}
                                    onChange={onChange}
                                />
                            </div>
                        </>
                )}
                {feature.TypeName === 'Boolean' && (
                    <FeaturesBool
                        key={filter.name}
                        value={filterValue}
                        onChange={onChange}
                    />
                )}
                {feature.TypeName === 'Rating' && (
                    <RatingSelector
                        key={filter.name}
                        value={filterValue}
                        onChange={onChange}
                        simpleMode
                        fullWidth
                    />
                )}
                {feature.TypeName === 'Numeric' && (
                    <NumericRangeSelector
                        key={filter.name}
                        value={filterValue}
                        onChange={onChange}
                    />
                )}
                {feature.TypeName === 'Multichoice' && (
                    <FeaturesMultichoice
                        key={filter.name}
                        itemid={filter.name}
                        value={filterValue}
                        onChange={onChange}
                    />
                )}
                {feature.TypeName === 'Choice' && (
                    <FeaturesChoice
                        key={filter.name}
                        itemid={filter.name}
                        value={filterValue}
                        onChange={onChange}
                    />
                )}
                {feature.TypeName === 'Text' && (
                    <Input
                        key={filter.name}
                        itemid={filter.name}
                        type='text'
                        value={filterValue}
                        onChange={event => {
                            onChange(event.target.value)
                        }}
                    />
                )}
                {feature.TypeName === 'BigText' && (
                    <Input
                        key={filter.name}
                        itemid={filter.name}
                        type='text'
                        value={filterValue}
                        onChange={event => {
                            onChange(event.target.value)
                        }}
                    />
                )}

            </div>
            <div className="clear-button-wrapper">
                <FilterClearButton onClick={onClearField} />
            </div>
        </FieldWrapper>
    );
};

export default ModelFilterItemFeature;