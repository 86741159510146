import React, { useState, useContext, createContext, useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { useStateValue } from "Contexts/StateProvider";
import UserDropDown from './UserDropDown';
import PlusIconsAndPackages from "Components/_LayoutComponents/Header/PlusIconsAndPackages";
import AreaSelect from 'Components/_LayoutComponents/Layout/AreaSelect';
import GlobalSearch from 'Components/_LayoutComponents/Header/GlobalSearch/GlobalSearch';
import NwIcon from 'Components/Gui/NwIcon';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { UpdateCalendarContext } from "Contexts";
import EventDrawer from 'Components/EventDrawer/EventDrawer';
import { StyledHeader } from './NwHeaderUI';
import NwNavBarWrapper from 'Components/_LayoutComponents/Header/NavBar/NwNavBarWrapper';
import { fetchCustomersByArea, fetchModelsByArea  } from 'Hooks/Contact/UseContactsList'; //forceReloadModelsWall, forceReloadCustomersWall
import { getAreaUrl } from 'Components/_LayoutComponents/Header/NavBar/NwNavUtils';
import { BeatLoader } from 'react-spinners';
import StyleVariables from 'Components/Gui/StyleVariables';
import logo from '_img/nw.png';
import { Drawer } from 'antd';


const searchSources = ["Models", "Agencies", "Clients", "Services"]


export const HeaderContext = createContext(null)

const NwHeader = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [activeSearch, setActiveSearch] = useState(false)
    const [{ areaContext, loadingStatusContext }, dispatch] = useStateValue();

    const breakpoints = useNwBreakPoints();
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const updateCalendarContext = useContext(UpdateCalendarContext);
    const [createdEvent, setCreatedEvent] = useState(null);


    const device = useMemo(() => {
        return breakpoints
    }, [breakpoints])

    const openMobileNav = () => {
        setMobileNavOpen(true);
    }

    const closeMobileNav = () => {
        setMobileNavOpen(false);
    }

    const onSearchContact = useCallback((contactInfo) => {
        if (contactInfo.type === 'model') {
            navigate(`/model/${contactInfo.ID}/all-models/all`);  //?clear_qf=true
        } else {
            navigate(`/customer/${contactInfo.ID}/all-customers/all`); //?clear_qf=true
        }
    }, []);

    const onCloseEventDrawer = useCallback(() => {
        updateCalendarContext.setUpdateCalendar(true)
        setCreatedEvent(null)
    }, [updateCalendarContext])

    const onViewSearch = useCallback((search) => {
        setActiveSearch(search)
    }, []);

    const onEventCreated = useCallback((event) => {
        setCreatedEvent(event)
    }, []);

    const onChangeArea = useCallback(async (areaToSet) => {
        dispatch({
            type: 'updateArea',
            area: areaToSet
        })
        try {
            dispatch({
                type: 'updateContactsLoading',
                areaContactsLoading: true
            });
            //forceReloadModelsWall()
            //forceReloadCustomersWall()
            await fetchModelsByArea(areaToSet)
            await fetchCustomersByArea(areaToSet)
            const path = getAreaUrl(areaToSet)
            
            await navigate(path);
            dispatch({
                type: 'updateContactsLoading',
                areaContactsLoading: false
            });
        } catch (error) {
            console.log('ant : Fetch Area Customers and Models Error => ', error);
        }
    }, [dispatch, location.pathname]);

    const area = useMemo(() => {
        return areaContext.area
    }, [areaContext.area]);

    const closeNavDrawer = useCallback(() => {
        closeMobileNav()
    }, []);

    const headerContextValues = useMemo(() => ({
        activeSearch,
        area,
        closeNavDrawer,
        device,
        onCloseEventDrawer,
        onEventCreated,
        onChangeArea,
        onSearchContact,
        onViewSearch,
        searchSources,
    }), [
        activeSearch,
        area,
        closeNavDrawer,
        device,
        onCloseEventDrawer,
        onEventCreated,
        onChangeArea,
        onSearchContact,
        onViewSearch
    ]);

    return (
        <HeaderContext.Provider value={headerContextValues}>
            {(breakpoints === 1)
                ?
                // mobile only (< 720px)
                <StyledHeader>
                    <div className="nw-header">
                        <div className="nw-header-sections-row">
                            <div className="nw-header-section nw-header-logo-container">
                                <div className="nw-header-logo-holder">
                                    <img src={logo} alt="NETWALK4" />
                                </div>
                            </div>
                            <div className="nw-header-section nw-header-main-section">
                                {area
                                    ?
                                    <>
                                        <AreaSelect />
                                        {(breakpoints < 3)
                                            ?
                                            <div className='nw-main-nav-icon-wrapper'>
                                                {mobileNavOpen
                                                    ?
                                                    <NwIcon
                                                        icon={regular('times')}
                                                        pointer
                                                        onClick={closeMobileNav}
                                                    />
                                                    :
                                                    <NwIcon
                                                        icon={regular('bars')}
                                                        pointer
                                                        onClick={openMobileNav}
                                                    />
                                                }
                                            </div>
                                            :
                                            <div className="nw-main-nav-wrapper">
                                                <NwNavBarWrapper />
                                            </div>
                                        }
                                    </>
                                    :
                                    <BeatLoader color={StyleVariables.PrimaryColor} size={8} margin={4} />
                                }
                            </div>
                        </div>
                        <div className='nw-header-sections-row'>
                            <div className="nw-header-section nw-right-buttons">
                                {!activeSearch
                                    ?
                                    (area !== 'management' &&
                                        <PlusIconsAndPackages />
                                    )
                                    :
                                    <div />
                                }
                                <GlobalSearch
                                    sources={searchSources}
                                    closable
                                />
                            </div>

                            <div className="nw-header-section">
                                <UserDropDown />
                            </div>
                        </div>
                    </div>
                </StyledHeader>
                :
                // tablet and above (>= 720px)
                <StyledHeader>
                    <div className="nw-header">
                        <div className="nw-header-section nw-header-logo-container">
                            {!activeSearch
                                ?
                                <div className="nw-header-logo-holder">
                                    <img src={logo} alt="NETWALK4" />
                                </div>
                                :
                                <div />
                            }
                            <GlobalSearch
                                sources={searchSources}
                                closable
                            />
                        </div>
                        <div className="nw-header-section nw-header-main-section">
                            {area
                                ?
                                <>
                                    <AreaSelect />
                                    {(breakpoints < 3)
                                        ?
                                        <div className='nw-main-nav-icon-wrapper'>
                                            {mobileNavOpen
                                                ?
                                                <NwIcon
                                                    icon={regular('times')}
                                                    pointer
                                                    onClick={closeMobileNav}
                                                />
                                                :
                                                <NwIcon
                                                    icon={regular('bars')}
                                                    pointer
                                                    onClick={openMobileNav}
                                                />
                                            }
                                        </div>
                                        :
                                        <div className="nw-main-nav-wrapper">
                                            <NwNavBarWrapper />
                                        </div>
                                    }
                                </>
                                :
                                <BeatLoader color={StyleVariables.PrimaryColor} size={8} margin={4} />
                            }
                        </div>
                        {area !== 'management' &&
                            <div className="nw-header-section nw-right-buttons">
                                <PlusIconsAndPackages />
                            </div>
                        }
                        <div className="nw-header-section">
                            <UserDropDown />
                        </div>
                    </div>
                </StyledHeader>
            }
            {mobileNavOpen &&
                <Drawer
                    className='mobile-nav-drawer'
                    placement="top"
                    onClose={closeMobileNav}
                    height={(device > 1) ? "6.75rem" : "100vh"}
                    open
                    zIndex={1030}
                >
                    <NwNavBarWrapper drawer />
                </Drawer>
            }
            {(createdEvent && createdEvent.ShowDrawer) &&
                <EventDrawer
                    drawerOrigin="header"
                    eventType={createdEvent.TypeName}
                    eventID={createdEvent.ID}
                    onClose={onCloseEventDrawer}
                    afterUpdate={() => { }}
                    afterRemove={() => { }}
                />
            }
        </HeaderContext.Provider>
    )
}

export default NwHeader;

