import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Row, Col } from 'antd';
import Axios from 'axios';
import { NwForm } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInput, NWTextAreaCharCounter, NWDatePicker } from "Components/Gui/NwForm/NwFormItems";
import useConfigurations from 'Hooks/UseConfigurations';
import { ProjectTransactionFormContainer } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { Loading } from 'Components/Gui/Loading';
import ModelSelectorNonBookingDates from './Common/ModelSelectorNonBookingDates';
import AmountLine from './Common/AmountLine';
import ServiceLine from './Common/ServiceLine';
import useTransactionLabels from 'Hooks/UseTransactionLabels';
import TransactionLabelsSelector from './Common/TransactionLabelsSelector';
import FixedSaveAndCancelBar from './Common/FixedSaveAndCancelBar';
import { datePickerToApi, getNow } from 'Libs/NwMoment';
import { getMoment } from "Libs/NwMoment";


const ExpenseFormNonBookingEvent = ({ event, onCloseDrawer, eventType, onSave }) => {  //onChangeForm
    
    const transactionProperties = useTransactionLabels("Expense");
    const defaultTypeQuantity = transactionProperties.startLabel.TypeQuantityName ? transactionProperties.startLabel.TypeQuantityName : "Units"
    const configurations = useConfigurations();

    const eventTempModels = [{ ModelID: event.ModelID, active: true, cancel: event.Cancel, eventID: event.ID}]
    if (event.RelatedEvents) {
        for (const relEvent of event.RelatedEvents) {
            eventTempModels.push({ ModelID: relEvent.ModelID, active: true, cancel: relEvent.Cancel, eventID: relEvent.ID})
        }
    }

    const [eventModels, setEventModels] = useState(eventTempModels);

    const getFirstDateForExpense = () => {
        switch (eventType.toLowerCase()) {
            case 'followup':
                return getMoment(event.StartDate)
            case 'travel':
                return getMoment(event.StartDateTime)
            case 'accommodation':
                return getMoment(event.FromDate)
            default:
                return getNow()
        }
    }



    const saveTransaction = async (values, setSubmitting) => {

        //TODO: validazione: se c'è evento ci deve essere modella

        let apiData = {
            StatementType: 'Model',
            TransactionLabelID: values.TransactionLabelID,
            Title: values.Title,
            Description: values.Description,
            Quantity: values.Quantity[0],
            TypeQuantity: values.Quantity[1],
            Currency: values.Amount[0],
            SingleFee: values.Amount[1],
            RefDate: values.RefDate && datePickerToApi(values.RefDate)
        }
        if (values.ServiceID) {
            apiData.ServiceID = values.ServiceID;
            if (values.AgencyCost) {
                apiData.AgencyCost = values.AgencyCost[1];
            } else {
                apiData.AgencyCost = values.SingleFee[1];
            }
        }

        let models = [];
        for (const eventModel of eventModels) {
            if (eventModel.active) {
                const modelData = {
                    ContactID: eventModel.ModelID,
                    ContactBillingInfoID: null
                }
                modelData[eventType + "ID"] = eventModel.eventID;
                models.push(modelData)
            }
        }

        apiData = {
            ...apiData,
            Contacts: [...models]
        }

        try {
            await Axios.post('/transactions/expense', apiData);
            setSubmitting(false);
            onSave();
        } catch (error) {
            console.log('ant : Create Expense Error => ', error);
            setSubmitting(false);
        }
    };

    const initialValues = {
        TransactionLabelID: transactionProperties.startLabel.ID,
        Title: transactionProperties.startLabel.Label,
        ServiceID: null,
        SelectedEvent: null,
        Description: '',
        Amount: [configurations.defaultCurrency, null],
        AgencyCost: [configurations.defaultCurrency, null],
        Quantity: [1, defaultTypeQuantity],
        RefDate: getFirstDateForExpense()
    }

    return (
        event
            ?
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Title is required"),
                    TransactionLabelID: Yup.string().required("Label is required")
                })}
                validateOnBlur={true}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveTransaction(values, setSubmitting);
                }}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit, touched, isValid }) => (
                    <NwForm
                        values={values}
                        //onTouchForm={onChangeForm}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <ProjectTransactionFormContainer>
                            <Row gutter={32}>
                                <Col span={14} className='rate-form'>
                                    <TransactionLabelsSelector 
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        transactionProperties={transactionProperties} 
                                        updateTitle
                                    />
                                    <Field
                                        component={NWDatePicker}
                                        label="Date"
                                        name="RefDate"
                                        format="MMM DD, YYYY"
                                        allowClear={false}
                                        value={values.RefDate}
                                    />
                                    <Field
                                        component={NWInput}
                                        label="Title"
                                        name="Title"
                                        type="text"
                                        value={values.Title}
                                        maxLength={255}
                                    />
                                    <Field
                                        component={NWTextAreaCharCounter}
                                        label="Description"
                                        name="Description"
                                        type="text"
                                        value={values.Description}
                                        maxLength={2500}
                                        autoSize={{ minRows: 3, maxRows: 10 }}
                                    />
                                    <AmountLine
                                        values={values}
                                        touched={touched}
                                        onChangeAmount={
                                            (value) => {
                                                if (!touched.AgencyCost) {
                                                    setFieldValue('AgencyCost', [value[0], value[1]])
                                                }
                                            }
                                        }
                                        amountLabel="Expense Amount"
                                    />
                                    <ServiceLine values={values} setFieldValue={setFieldValue} hasAgencyCost />
                                </Col>
                                <Col span={10}>
                                    <ModelSelectorNonBookingDates
                                        models={eventModels}
                                        selectedEvent={event}
                                        updateModels={setEventModels}
                                    />
                                </Col>
                            </Row>
                        </ProjectTransactionFormContainer>
                        <FixedSaveAndCancelBar
                            isSubmitting={isSubmitting}
                            isValid={isValid}
                            onCancel={() => {
                                //onChangeForm(false);
                                onCloseDrawer();
                            }}
                        />
                    </NwForm>
                )}
            </Formik>
            :
            <Loading />
    )
};

export default ExpenseFormNonBookingEvent;