import React, { useState, useEffect } from "react";
import { useMatch, useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { Result } from "antd";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ModelDetailsCalendar from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsCalendar";
import ModelDetailsMobileTabs from 'Components/ContactDetails/ModelDetails/ModelDetailsMobile/ModelDetailsMobileTabs';
import FlexContainer from 'Components/Gui/FlexContainer';
import ContactDetailsDocuments from 'Components/ContactDetailsDocuments/ContactDetailsDocuments';
import ContactDetailsNotes from 'Components/ContactDetailsNotes/ContactDetailsNotes';
import { useStateValue } from 'Contexts/StateProvider';
import { addModelLog } from "Libs/NwLogs";
import NwDivider from "Components/Gui/NwDivider";
import NwIcon from 'Components/Gui/NwIcon';
import Comments from "Components/Comments/Comments";
import ModelDetailsMobileHeader from "./ModelDetailsMobile/ModelDetailsMobileHeader";
import ModelDetailsBooksMobile from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooksMobile";

const Wrapper = styled.div`
    //height: calc(100vh - 116px);
    background: white;
    margin-top: 3rem;
    .container {
        position: relative;
        padding: .5rem 1rem;
        /* height: ${props => props.$isDrawer ? 'calc(100vh - 126px)' : 'calc(100vh - 176px)'};
        overflow: auto; */
    }

    .back-models {
        font-size: .8rem;
        cursor: pointer;
    }
`;

const Label = styled.div`
    font-size: 1.6rem;
`;

const ModelDetailsIndexMobile = ({ isDrawer, currentContact }) => {
    
    const [{areaContext}] = useStateValue();
    const [viewType, setViewType] = useState('profile');   
    const navigate = useNavigate();
    const [contactCalendarStyle, setContactCalendarStyle] = useState('agenda');

    useEffect(() => {
        currentContact && currentContact.NetworkAgencies && !currentContact.NetworkAgencies[0].StorageBooking && addModelLog(currentContact, areaContext.area)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContact]);

    const handleChangeView = viewType => () => {
        setViewType(viewType);
    };

    const handleBackToModels = () => {
        navigate('/models');
    }

    return (
        <Wrapper $isDrawer={isDrawer}>
            <ModelDetailsMobileTabs 
                viewType={viewType}
                onViewModelProfile={handleChangeView('profile')}
                onViewCalendar={handleChangeView('calendar')}
                onViewModelBooks={handleChangeView('books')}
                onViewFiles={handleChangeView('files')}
                onViewComments={handleChangeView('comments')}
                isDrawer={isDrawer}
                //onViewStatement={handleChangeView('statement')}    
            />
            {currentContact && 
                <>
                    {currentContact.NetworkAgencies[0].StorageBooking ? 
                        <Result
                            title="This model has been archived"
                        />
                    : 
                        <div className='container'>
                            {(viewType !== 'profile' && !isDrawer) && 
                                <FlexContainer mg='16px 0 8px' column alignItems='center' justifyContent='center'>
                                    <FlexContainer className='back-models' alignItems='center' onClick={handleBackToModels}>
                                        <NwIcon icon={light('arrow-left')}></NwIcon>&nbsp;back to models list
                                    </FlexContainer>
                                    <Label>{`${currentContact.Name} ${currentContact.Surname}`}</Label>
                                </FlexContainer>
                            }
                            {(() => {
                                switch(viewType) {
                                    case 'profile':
                                        return (
                                            <ModelDetailsMobileHeader 
                                                isDrawer 
                                                currentContact={currentContact} 
                                            />
                                        )
                                    case 'calendar':
                                        return (
                                            <ModelDetailsCalendar 
                                                isMobileDrawer={isDrawer}
                                                idmodel={currentContact.ID} 
                                                calendarStyle={contactCalendarStyle} 
                                                onChangeCalendarStyle={setContactCalendarStyle} 
                                            />
                                        )
                                    case 'books':
                                        return (
                                            <ModelDetailsBooksMobile 
                                                contactID={currentContact.ID} 
                                                modelPlaylists={currentContact.Playlists} 
                                            />
                                        )
                                    case 'files':
                                        return (
                                            <ContactDetailsDocuments 
                                                //isInCardTab 
                                                currentContact={currentContact} 
                                            />
                                        )
                                    case 'comments':
                                        return (
                                            <>
                                                <NwDivider>Comments</NwDivider>
                                                <Comments
                                                    api="ContactID"
                                                    drawerMode
                                                    isInCardTab
                                                    contactId={currentContact.ID}
                                                    ID={currentContact.ID}
                                                    comments={currentContact.Comments}
                                                />
                                                <NwDivider>Notes</NwDivider>
                                                <ContactDetailsNotes 
                                                    isInCardTab
                                                    currentContact={currentContact}
                                                    afterNotesCreation={() => {}} />
                                            </>
                                        )
                                    case 'statement':
                                        return <div />
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    }
                </>
            }
        </Wrapper>
    );
};

export default ModelDetailsIndexMobile;
