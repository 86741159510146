import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { NwEditButton } from "Components/Gui/NwButton";
import { ListContext } from 'Contexts/ListContext';
import { mergeCustomFields, parseCustomFieldValue } from 'Libs/NwUtils';
import NwDrawer from 'Components/Gui/NwDrawer';
import CustomFieldsForm from 'Components/CustomFields/CustomFieldsForm';
import Axios from 'axios';

const Wrapper = styled.div`
    border-top: ${props => props.theme.DefaultBorder};
    padding-top: 1rem;
    margin: 1rem .5rem 0 0;
    
    .title {
        font-weight: bold;
        margin-bottom: .5rem;
    }


    .item-container {
        font-size: ${(props) => props.theme.FontSizeBase};
        border-bottom: 1px solid ${(props) => props.theme.LighterBorderColor};
        padding: .5rem 0;
        margin: .5rem 0;

        &:last-of-type {
            border-bottom: none;
        }

        .item-label {
            font-size: ${(props) => props.theme.FontSizeSm};
            text-transform: uppercase;
        }

        .item-value {
            font-weight: bold;
            white-space: pre-wrap;
        }
    }
`;

const InvoiceCustomFields = ({ invoice, afterEditing }) => {
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const customFields = cachedList.customFields.items["Invoice"]
    const mergedCustomFields = mergeCustomFields(customFields.filter(field => !field.Disabled), invoice.CustomFields, true)

    const handleEditInfo = () => {
        setOpenEditDrawer(true);
    };

    const handleCloseEditInfo = () => {
        afterEditing();
        setOpenEditDrawer(false);
    };

    const onSaveCustomFields = async (customFieldsData) => {
        await Axios.put(`/invoices/customfields/${invoice.ID}`, customFieldsData)
        afterEditing();
    }

    if (!mergedCustomFields.length) return null;

    return (
        <Wrapper>
            <div className="title">Additional Info</div>
            {mergedCustomFields.map((field, index) => (
                <div className="item-container" key={`custom-field-${field.Name}`}>
                    <div className="item-label">{field.Name.replace(/_/g, ' ')}</div>
                    <div className="item-value">{parseCustomFieldValue(field) || "n.d."}</div>
                </div>
            ))}
            <NwEditButton size="small" primary  onClick={handleEditInfo} />
            {/* disabled={invoice.Posted} */}
            {openEditDrawer &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Additional Info'
                    onClose={handleCloseEditInfo}>
                    <CustomFieldsForm
                        object={invoice}
                        customFields={customFields}
                        onSave={onSaveCustomFields}
                        onClose={handleCloseEditInfo}
                        onTouchForm={setTouchForm}
                    />
                </NwDrawer>
            }
        </Wrapper>
    );
};

export default InvoiceCustomFields;