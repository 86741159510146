import React, { useState } from 'react';
import styled from 'styled-components';
import { NwButton } from 'Components/Gui/NwButton';
import useTransactionLockedStatus from 'Hooks/UseTransactionLockedStatus';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { NwTag } from 'Components/Gui/NwTag';
import { showDate, showDateNoTime } from 'Libs/NwMoment';
import InvoiceDrawer from 'Components/Invoices/InvoiceDrawer';


const Wrapper = styled.div`
    background-color: ${props => props.theme.LayoutBodyBackgroundTransparent};
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid ${props => props.theme.DarkerBorder};

    .header {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.4rem;
        padding: .5rem 1rem;
        border-bottom: 3px solid white;
        color: ${props => props.theme.DangerColor};

        h1 {
            margin: 0;
            font-size: ${props => props.theme.FontSizeLg};
            font-weight: 600;
        }
    }

    .content {
        padding: 1rem;
        display: flex;
        gap: 1rem;

        .content-left {
            flex: 1;

            small {
                cursor: ${props => props.$pointercursor ? 'pointer' : 'not-allowed'};
            }

            h3 {
                font-size: ${props => props.theme.FontSizeSm};
                text-transform: uppercase;
                font-weight: 400;
                color: ${props => props.theme.TextColorMuted};
            }

            ul {
                margin: 0 0 0 2rem;
                padding: 0;
                list-style: none;

                li {
                    font-size: ${props => props.theme.FontSizeBase};
                    margin-bottom: .5rem;

                    small {
                        padding-left: .5rem;
                        text-transform: uppercase;
                        font-size: ${props => props.theme.FontSizeSm};
                        /* color: ${props => props.theme.TextColorMuted}; */
                    }
                }
            }
        }

        .content-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: space-between;
            align-items: flex-end;
            
            p {
                font-size: ${props => props.theme.FontSizeSm};
                color: ${props => props.theme.TextColorMuted};
                text-transform: uppercase;
                margin-bottom: 1rem;
                text-align: right;

                &.unlocked-text {
                    color: ${props => props.theme.DangerColor};
                    font-weight: 600;
                }

            }
        }
    }
`;

const TransactionLockStatus = ({
    transaction,
    accountingUnlock,
    onUnlockingTransaction,
    onLockingTransaction
}) => {

    const [openInvoice, setOpenInvoice] = useState(null)

    const {
        isLocked,
        cantBeUnlocked,
        isAccountant,
        deduplicatedClientInvoices,
        deduplicatedModelInvoices,
        deduplicatedAgencyInvoices,
        deduplicatedModelPayments,
        deduplicatedAgencyPayments,
        statementsLocked
    } = useTransactionLockedStatus(transaction);

    if (!isLocked) {
        return null
    }

    const handleOpenInvoice = (invoiceID) => {
        if (isAccountant) {
            setOpenInvoice(invoiceID)
        }
    }

    const InvoiceTag = ({ invoice }) => {
        return (
            <>
                <NwTag
                    type="primary"
                    clickaction={() => handleOpenInvoice(invoice.invoiceID)}
                >
                    {invoice.invoiceNumber
                        ?
                        <>
                            {invoice.invoiceNumber} &bull; {showDate(invoice.invoiceDate, "short", true)}
                        </>
                        :
                        "DRAFT"
                    }
                </NwTag>
                {invoice.postedDate
                    ?
                    <small>posted on {showDateNoTime(invoice.postedDate)}</small>
                    :
                    (invoice.readyToPost &&
                        <small>in transit</small>
                    )
                }
            </>
        )
    }

    return (
        <Wrapper $pointercursor={isAccountant}>
            <div className="header">
                <NwIcon icon={solid('lock')} />
                <h1>Locked transaction</h1>
            </div>
            <div className="content">
                <div className="content-left">
                    {deduplicatedClientInvoices.length > 0 &&
                        <>
                            <h3>In {deduplicatedClientInvoices.length > 1 ? deduplicatedClientInvoices.length : "a"} client invoice{deduplicatedClientInvoices.length > 1 ? "s" : ""}</h3>
                            <ul>
                                {deduplicatedClientInvoices.map(invoice => (
                                    <li key={invoice.invoiceNumber}>
                                        <InvoiceTag invoice={invoice} />
                                    </li>
                                ))}
                            </ul>
                        </>
                    }
                    {deduplicatedModelInvoices.length > 0 &&
                        <>
                            <h3>In {deduplicatedModelInvoices.length > 1 ? deduplicatedModelInvoices.length : "a"} model invoice{deduplicatedModelInvoices.length > 1 ? "s" : ""}</h3>
                            <ul>
                                {deduplicatedModelInvoices.map(invoice => (
                                    <li key={invoice.invoiceNumber}>
                                        <InvoiceTag invoice={invoice} />
                                    </li>
                                ))}
                            </ul>
                        </>
                    }
                    {deduplicatedAgencyInvoices.length > 0 &&
                        <>
                            <h3>In {deduplicatedAgencyInvoices.length > 1 ? deduplicatedAgencyInvoices.length : "a"} agency invoice{deduplicatedAgencyInvoices.length > 1 ? "s" : ""}</h3>
                            <ul>
                                {deduplicatedAgencyInvoices.map(invoice => (
                                    <li key={invoice.invoiceNumber}>
                                        <InvoiceTag invoice={invoice} />
                                    </li>
                                ))}
                            </ul>
                        </>
                    }
                    {statementsLocked.length > 0 &&
                        <h3>There are {statementsLocked.length} statements that are locked (imported from Netwalk 3)</h3>
                    }
                    {deduplicatedModelPayments.length > 0 &&
                        <>
                            <h3>In {deduplicatedModelPayments.length > 1 ? deduplicatedModelPayments.length : "a"} model payment{deduplicatedModelPayments.length > 1 ? "s" : ""}</h3>
                            <ul>
                                {deduplicatedModelPayments.map(payment => (
                                    <li key={payment.PaymentID}>{payment.PaymentNumber} - {payment.PaymentDate}</li>
                                ))}
                            </ul>
                        </>
                    }
                    {deduplicatedAgencyPayments.length > 0 &&
                        <>
                            <h3>In {deduplicatedAgencyPayments.length > 1 ? deduplicatedAgencyPayments.length : "a"} agency payment{deduplicatedAgencyPayments.length > 1 ? "s" : ""}</h3>
                            <ul>
                                {deduplicatedAgencyPayments.map(payment => (
                                    <li key={payment.PaymentID}>{payment.PaymentNumber} - {payment.PaymentDate}</li>
                                ))}
                            </ul>
                        </>
                    }
                </div>
                <div className="content-right">
                    {accountingUnlock ?
                        <>
                            <p className="unlocked-text">This transaction has been unlocked.<br/>Please note that any change made to the transaction will not be reflected in generated invoices and in data posted to external systems.</p>
                            <NwButton icon={solid('lock')} primary onClick={onLockingTransaction} label="Lock" />
                        </>
                        :
                        (!cantBeUnlocked
                            ?
                            <>
                                <p>you can unlock the transaction<br/>by clicking the button below</p>
                                <NwButton icon={solid('lock-open')} primary onClick={onUnlockingTransaction} label="Unlock" />
                            </>
                            :
                            (isAccountant
                                ?
                                <p>This transaction cannot be unlocked<br/>because it has payments to models or agencies.</p>
                                :
                                <p>This transaction can be unlocked<br/>only by an accountant.</p>
                            )
                        )
                    }
                </div>
            </div>
            {openInvoice &&
                <InvoiceDrawer
                    invoiceId={openInvoice}
                    onClose={() => setOpenInvoice()}
                />
            }
        </Wrapper>
    );
};

export default TransactionLockStatus;
