
import React, { useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon from 'Components/Gui/NwIcon';
import NwObjectCounter from "Components/Gui/NwObjectCounter";
import { NwStyledCard, GetNwCardStyle, FilterBadge, TabsContentWrapper } from 'Components/Gui/NwCard/NwCardUi';

const NwTabParsed = (icon, name, counter, prefix, suffix) => (
    <div className="tab-title-with-icon-and-counter">
        {prefix
            ?
            <span className="tab-title-prefix">{prefix.map(p => p)}</span>
            :
            (icon
                ?
                <NwIcon icon={icon} className="nw-card-header-icon" style={{ marginRight: ".5em" }} />
                :
                null
            )
        }
        {name}
        {(counter !== undefined && counter !== null)
            ?
            <NwObjectCounter count={counter} style={{ marginLeft: ".5em" }} />
            :
            (suffix
                ?
                <span className="tab-title-suffix">{suffix.map(s => s)}</span>
                :
                null
            )
        }
    </div>
);

const NwTabsForCard = tabs => {
    return tabs.map(mytab => {
        if (mytab.icon || mytab.counter || mytab.prefix || mytab.suffix) {
            return { key: mytab.key, tab: NwTabParsed(mytab.icon, mytab.name, mytab.counter, mytab.prefix, mytab.suffix) };
        } else {
            return { key: mytab.key, tab: mytab.name };
        }
    });
};

const NwCardTabs = (props) => {
    const cardtabs = NwTabsForCard(props.tabs);
    const { ClassN, Style, BodyStyle, ScrollStyle } = GetNwCardStyle(props, true)
    const { filtersContent, activeFilters, fixedFilters, info } = props;
    const [showFiltersContent, setShowFiltersContent] = useState(false);

    const handleShowFiltersContent = () => {
        setShowFiltersContent(prevShowFiltersContent => !prevShowFiltersContent);
    };

    return (
        <NwStyledCard
            $filters={activeFilters}
            $filterscontent={showFiltersContent ? true : undefined}
            size="default"
            className={ClassN}
            actions={props.actions}
            tabList={cardtabs}
            style={Style}
            bodyStyle={BodyStyle}
            activeTabKey={props.activeTab}
            tabBarExtraContent={props.tabBarExtraContent}
            onTabChange={key => {
                props.onTabChange(key);
            }}
        >
            {fixedFilters &&
                <div className='fixed-filters'>
                    {fixedFilters}
                </div>
            }
            {info &&
                <div className='nw-card-info'>
                    <span className='nw-card-info-icon'><NwIcon icon={solid('circle-info')}/></span>
                    <p className='nw-card-info-text'>{info}</p>
                </div>
            }
            {(activeFilters || filtersContent) &&
                <div className='active-filters'>
                    {activeFilters ?
                        <div className='active-filters-label' onClick={handleShowFiltersContent}>
                            <div>{activeFilters.join(', ')}</div>
                            <FilterBadge
                                count={activeFilters.length}>
                                <NwIcon icon={light('filter')} />
                            </FilterBadge>
                        </div>
                        : filtersContent
                    }
                    {showFiltersContent && filtersContent &&
                        <div className='filters-content'>
                            {filtersContent}
                        </div>
                    }
                </div>
            }
            {props.scrollable ?
                <div style={ScrollStyle}>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        {props.children}
                    </PerfectScrollbar>
                </div>
                : props.children
            }
        </NwStyledCard>
    );
};

const NwCardTabsContent = (props) => {
    const { ScrollStyle } = GetNwCardStyle(props);
    const { filtersContent, activeFilters } = props;
    const [showFiltersContent, setShowFiltersContent] = useState(false);

    const handleShowFiltersContent = () => {
        setShowFiltersContent(prevShowFiltersContent => !prevShowFiltersContent);
    };

    return (
        <TabsContentWrapper
            $filters={activeFilters}
            $filterscontent={showFiltersContent ? true : undefined}>
            <div className={'tabs-content'}>
                {props.fixedFilters &&
                    <div className='fixed-filters'>
                        {props.fixedFilters}
                    </div>
                }
                {props.info &&
                    <div className='nw-card-info'>
                        <span className='nw-card-info-icon'><NwIcon icon={solid('circle-info')} /></span>
                        <p className='nw-card-info-text'>{props.info}</p>
                    </div>
                }
                {(props.scrollable && props.children) ? (
                    <div style={ScrollStyle}>
                        <PerfectScrollbar options={{ suppressScrollX: true }}>
                            {props.children}
                            {/* {getCardContent(props)} */}
                        </PerfectScrollbar>
                    </div>
                ) : (
                    <>{props.children}</>
                )}
            </div>
        </TabsContentWrapper>
    );
};

export { NwCardTabs, NwCardTabsContent };