import React, { useState, useMemo } from 'react';
import { Badge, Popover } from 'antd';
import ls from 'local-storage';
import _ from "lodash";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import ContactsFiltersSmartListsForm from "Components/ContactsSelector/Common/ContactsFiltersSmartListsForm";
import { NwAddButton } from 'Components/Gui/NwButton';
import FolderDrawer from 'Components/ContactsFolders/FolderDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import { StyledTabs, StyledSelect, FiltersWrapper } from "Components/Filters/FilterSidebarStyle";
import NwIcon from 'Components/Gui/NwIcon';
import ModelQuickFilters from 'Components/ContactsSelector/ModelsSelector/ModelQuickFilters';
import { useNavigate } from 'react-router';
import { useContactsSelector, useContactsSelectorDispatch } from './ContactsSelectorContext';
import NwDrawer from 'Components/Gui/NwDrawer';
import FolderContactsList from 'Components/ContactsFolders/FolderContactsList';
import { useFolders, useFoldersDispatch } from 'Contexts/FoldersContext';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import CustomerQuickFilters from 'Components/ContactsSelector/CustomersSelector/CustomerQuickFilters';
import ContactsSelectedFilters from 'Components/ContactsSelector/Common/ContactsSelectedFilters';


const SelectWrapper = styled.div`
    position: relative;
    width: 100%;

    .prefix-icon-wrapper {
        position: absolute;
        z-index: 1;
        width: 2.5rem;
        padding-top: .5rem;
        padding-bottom: .25rem;
        border-right: ${props => props.theme.DefaultBorder};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    && .ant-select .ant-select-selector {
        padding-left: calc(3rem);

        .ant-select-selection-search {
            left: 45px;
        }
    }
`;

const ContactsFiltersSidebar = ({
    itemType,
    projectModels,
    tags,
    customOptionsProp,
    staticOptionsProp,
    folderOptionsProp,
    selectedItem,
}) => {

    const [editFolder, setEditFolder] = useState(false);

    const navigate = useNavigate()

    const breakpoints = useNwBreakPoints();

    const { onSelectListType, onSelectActiveSet } = useContactsSelectorDispatch();

    const { selection, global, context, additional } = useContactsSelector();

    const cs_dispatch = useContactsSelectorDispatch();

    const {
        activeSet,
        filters,
        listType
    } = selection;
    const { sets } = global;

    const workingSetUnsaved = useMemo(() => {
        if (listType === 'smart-lists') {
            const activeSetObj = sets.find(set => set.id === activeSet);
            return activeSetObj && activeSetObj.unsaved;
        }
        return false;
    }, [activeSet, listType, sets]);

    const folders = useFolders()
    const folders_dispatch = useFoldersDispatch()

    const { scope, area } = context;
    const { startOnProjectModels, } = additional;

    const [isRenameFolder, setIsRenameFolder] = useState(false);
    const [addNewFolder, setAddNewFolder] = useState(false);


    const allItemsName = itemType === 'model' ? 'all-models' : 'all-customers';
    const allItemsLabel = itemType === 'model' ? 'all models' : 'all customers';
    const urlSection = itemType === 'model' ? 'models' : 'customers';

    //TEMPORARY: to be removed when we will have filters for scouting models
    const enableSmartLists = (area !== "accounting")
    const enableFolders = (area !== "scouting" && area !== "accounting")

    const backToList = () => {
        let urlToNavigate = `/${urlSection}/${listType}/${activeSet}`;
        if (area === "accounting") {
            urlToNavigate = `/accounting/${urlSection}`;
        }
        navigate(urlToNavigate);
    }

    const goToBooking = () => {
        navigate(`/schedule/${listType}/${activeSet}`);
    }

    const customOptions = useMemo(() => {
        return customOptionsProp || sets.filter(s => !s.fixed).map(set => {
            return { value: set.id, label: set.name };
        });
    }, [sets, customOptionsProp]);

    const staticOptions = useMemo(() => {
        return staticOptionsProp || sets.filter(s => s.fixed).map(set => {
            return { value: set.id, label: set.name };
        });
    }, [sets, staticOptionsProp]);

    const folderOptions = useMemo(() => {
        if (!folders.openFolders) {
            return [];
        }
        return folderOptionsProp || folders.openFolders.filter(folder => folder.type === itemType).map(folder => {
            const fol = {
                value: folder.ID.toString(),
                label: folder.title,
                items: [...folder.items],
                color: folder.color
            };
            return fol
        })
    }, [folders, folderOptionsProp]);

    const onSelectSet = value => {
        ls.set(`nw_config_${itemType}swall_listScrollPosition`, 0);
        cs_dispatch({
            type: "ChangeActiveSet",
            activeSet: value.toString()
        })
        if (onSelectActiveSet) {
            onSelectActiveSet(value.toString());
        }
    };

    const selectFolder = (folderid, foldername) => {
        cs_dispatch({
            type: "SelectFolder",
            folderID: folderid,
            folderName: foldername
        })
    };

    const handleFolderCreated = (folderid, foldername) => {
        cs_dispatch({
            type: "SelectFolder",
            folderID: folderid.toString(),
            folderName: foldername
        })
    };

    const handleChangeTab = async (tab) => {
        cs_dispatch({
            type: "ChangeListType",
            listType: tab
        })
        if (onSelectListType) {
            onSelectListType(tab);
        }


        setEditFolder(false);

        if (tab === allItemsName) {
            onSelectSet('all');
        }
        if (tab === 'folders') {
            const itemFolders = folders.openFolders.filter(folder => folder.type === itemType);
            if (itemFolders.length) {
                cs_dispatch({
                    type: "SelectFolder",
                    folderID: itemFolders[0].ID.toString(),
                    folderName: itemFolders[0].title
                })
            }
        }
    }

    let filterOptions = []
    if (itemType === 'model' && startOnProjectModels && projectModels.length > 0) {
        filterOptions.push({ label: breakpoints < 4 ? 'prj' : 'project', value: 'project' })
    }
    
    filterOptions.push({ label: breakpoints < 4 ? 'all' : allItemsLabel, value: allItemsName })
    
    if (enableSmartLists) {
        filterOptions.push({ label: breakpoints < 4 ? 'lists' : 'search', value: 'smart-lists' })
    }
    if (enableFolders) {
        var folderstab = { label: 'folders', value: 'folders' }
        if (!folders.openFolders || folders.openFolders.length === 0) {
            if (selectedItem) {
                folderstab.disabled = true
            }
        }
        filterOptions.push(folderstab)
    }

    const handleRenameFolder = () => {
        setIsRenameFolder(true);
    };

    const handleCloseDrawer = () => {
        setIsRenameFolder(false);
    };

    const handleAddNewFolder = () => {
        setAddNewFolder(true);
    };

    const handleCloseNewFolder = () => {
        setAddNewFolder(false);
    };

    const handleCloseEditFolder = () => {
        setEditFolder(false);
    };

    const handleRemoveFolder = async () => {
        const otherFolders = folders.openFolders.filter(folder => folder.ID !== Number(activeSet));
        if (otherFolders.length) {
            cs_dispatch({
                type: "SelectFolder",
                folderID: otherFolders[0].ID.toString(),
                folderName: otherFolders[0].title
            })
        } else {
            cs_dispatch({
                type: "ChangeListType",
                listType: allItemsName
            })
            if (onSelectListType) {
                onSelectListType(allItemsName);
            }
        }

        folders_dispatch({
            type: 'RemoveFolder',
            folderID: activeSet
        });
    };

    const folder = listType === 'folders' && folders.openFolders.find(folder => folder.ID === Number(activeSet));
    const leftList = selectedItem || scope === "booking"

    return (
        <FiltersWrapper
            $breakpoints={breakpoints}
            alignItems={'flex-start'}>
            <StyledTabs
                size='small'
                activeKey={listType}
                onChange={handleChangeTab}
                items={
                    filterOptions.map(tab => (
                        {
                            label: tab.label,
                            key: tab.value,
                            disabled: tab.disabled,
                            children: (
                                <div className="filters-tab-content">
                                    {listType === 'smart-lists' &&
                                        <>
                                            <div className={`filters-tab-content-wrapper${workingSetUnsaved ? ' active-set-changed' : ''}`}>
                                                {leftList
                                                    ?
                                                    <SelectWrapper>
                                                        <div className="prefix-icon-wrapper">
                                                            <Popover
                                                                placement="rightTop"
                                                                title="Smart List Filters"
                                                                overlayClassName={`nw-popover${workingSetUnsaved ? ' nw-popover-danger' : ''}`}
                                                                content={
                                                                    filters.active && filters.active.length > 0
                                                                        ?
                                                                        <ContactsSelectedFilters />
                                                                        :
                                                                        <span>no filters applied</span>
                                                                }
                                                                trigger="click"
                                                            >
                                                                {workingSetUnsaved
                                                                    ?
                                                                    <NwIcon danger pointer icon={solid('filters')} />
                                                                    :
                                                                    <NwIcon pointer icon={light('filters')} />
                                                                }
                                                            </Popover>
                                                        </div>
                                                        <StyledSelect
                                                            autoComplete='off'
                                                            showSearch={true}
                                                            placeholder={'Please select a smart list'}
                                                            dropdownStyle={{ minWidth: breakpoints < 4 ? "250px" : '0' }}
                                                            value={activeSet}
                                                            onChange={onSelectSet}
                                                            options={[
                                                                {
                                                                    label: "Custom Smart Lists",
                                                                    options: customOptions
                                                                },
                                                                {
                                                                    label: "Standard Smart Lists",
                                                                    options: staticOptions
                                                                }
                                                            ]}
                                                        />
                                                    </SelectWrapper>
                                                    :
                                                    <StyledSelect
                                                        autoComplete='off'
                                                        showSearch={true}
                                                        placeholder={'Please select a smart list'}
                                                        dropdownStyle={{ minWidth: breakpoints < 4 ? "250px" : '0' }}
                                                        value={activeSet}
                                                        onChange={onSelectSet}
                                                        options={[
                                                            {
                                                                label: "Custom Smart Lists",
                                                                options: customOptions
                                                            },
                                                            {
                                                                label: "Standard Smart Lists",
                                                                options: staticOptions
                                                            }
                                                        ]}
                                                    />
                                                }
                                                {workingSetUnsaved && !leftList &&
                                                    <span className="unsaved-changes"><NwIcon icon={solid("triangle-exclamation")} /> unsaved changes on list</span>
                                                }
                                            </div>
                                            {(!leftList) &&
                                                <ContactsFiltersSmartListsForm
                                                    tags={tags}
                                                />
                                            }
                                        </>
                                    }
                                    {listType === 'folders' &&
                                        ((folderOptions && folderOptions.length > 0)
                                            ?
                                            <>
                                                <div className="filters-tab-content-wrapper">
                                                    <StyledSelect
                                                        popupClassName="ant-select-dropdown-areaselect"
                                                        autoComplete='off'
                                                        showSearch={true}
                                                        placeholder={'Please select a folder'}
                                                        dropdownStyle={{ minWidth: breakpoints < 4 ? "250px" : '0' }}
                                                        value={activeSet}
                                                        options={folderOptions.map(fl => ({
                                                            value: fl.value,
                                                            label: (
                                                                <FlexContainer onClick={() => selectFolder(fl.value, fl.label)}>
                                                                    <Badge color={fl.color} style={{ marginRight: ".5rem" }} />{fl.label}
                                                                </FlexContainer>
                                                            )
                                                        }))}
                                                    />
                                                </div>
                                                {!selectedItem &&
                                                    <>
                                                        {folder &&
                                                            <NwDropDownMenu
                                                                size="small"
                                                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                                                actions={[
                                                                    {
                                                                        name: 'rename',
                                                                        key: 'rename',
                                                                        icon: light("pen"),
                                                                        pinned: true,
                                                                        tooltip: "rename folder",
                                                                        action: handleRenameFolder
                                                                    },
                                                                    {
                                                                        name: 'edit',
                                                                        key: 'edit',
                                                                        icon: light("list"),
                                                                        pinned: true,
                                                                        tooltip: `edit ${itemType}s list`,
                                                                        action: () => setEditFolder(true)
                                                                    },
                                                                    {
                                                                        name: 'delete',
                                                                        key: 'delete',
                                                                        type: 'delete',
                                                                        icon: light("times"),
                                                                        pinned: true,
                                                                        tooltip: "delete folder",
                                                                        action: handleRemoveFolder
                                                                    },
                                                                ]}
                                                            />
                                                        }
                                                        <br />
                                                        <NwAddButton
                                                            ghost
                                                            size='small'
                                                            label='create a new folder'
                                                            onClick={handleAddNewFolder}
                                                        />
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <p>no folders</p>
                                                <NwAddButton
                                                    ghost
                                                    size='small'
                                                    label='create new folder'
                                                    onClick={handleAddNewFolder}
                                                />
                                            </>
                                        )
                                    }
                                    {(!leftList && listType === allItemsName) &&
                                        ((itemType === 'model')
                                            ?
                                            <ModelQuickFilters tags={tags} />
                                            :
                                            <CustomerQuickFilters tags={tags} />
                                        )
                                    }
                                    {listType === "project" &&
                                        <p>models in current project</p>
                                    }
                                </div>
                            )
                        }
                    ))
                }
            />
            {(selectedItem || scope === "booking") &&
                <span className="back-to-list">
                    <NwIcon icon={solid('grid')} onClick={backToList} />
                </span>
            }
            {(!selectedItem && scope === "models-wall" && area === "booking") &&
                <span className="go-to-booking">
                    <NwIcon icon={light('calendar')} onClick={goToBooking} />
                </span>
            }
            {isRenameFolder &&
                <FolderDrawer
                    folder={folder}
                    type={itemType}
                    onClose={handleCloseDrawer}
                />
            }
            {addNewFolder &&
                <FolderDrawer
                    type={itemType}
                    onClose={handleCloseNewFolder}
                    onCreated={handleFolderCreated}
                />
            }
            {editFolder &&
                <NwDrawer
                    width="40vw"
                    placement="right"
                    onClose={handleCloseEditFolder}
                    visible={true}
                    title={'Edit Folder'}
                >
                    <FolderContactsList />
                </NwDrawer>
            }
        </FiltersWrapper>
    );
};

export default ContactsFiltersSidebar