import React, { useState } from 'react';
import NwIcon from 'Components/Gui/NwIcon';
import StyleVariables from 'Components/Gui/StyleVariables';
import MiniLoading from "Components/Gui/MiniLoading";
import { useUpdateTravelAcknowledgment } from 'Hooks/Event/UseTravel';
import { useUpdateFollowupAcknowledgment } from 'Hooks/Event/UseFollowup';
import { useAcknowledgeEventDateModel } from 'Hooks/Event/UseEvent';
import { NwButton } from "Components/Gui/NwButton";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const AcknowledgementEvent = ({ eventId, button, type, previewMode, acknowledgmentValue, eventDateModelID, onStatusChanged }) => {
    const [isLoading, setLoading] = useState(false);
    const { mutateAsync: onUpdateTravelAcknowledgment } = useUpdateTravelAcknowledgment();
    const { mutateAsync: onUpdateFollowupAcknowledgment } = useUpdateFollowupAcknowledgment();
    const { mutateAsync: onAcknowledgeEventDateModel } = useAcknowledgeEventDateModel();

    console.log("acknowledgmentValue", acknowledgmentValue, type)

    const onUpdateAcknowledgment = async () => {
        let endpoint = "";
        switch (type) {
            case 'ExtJob':
                return null;
            case 'Travel':
                await onUpdateTravelAcknowledgment({ ID: eventId });
                break;
            case 'FollowUp':
                await onUpdateFollowupAcknowledgment({ ID: eventId });
                break;
            case 'Meeting':
                break;
            case 'Casting':
            case 'Job':
                const acknowledge = acknowledgmentValue === "NotChecked" ? 'Checked' : 'NotChecked';
                await onAcknowledgeEventDateModel({ eventDateModelId: eventDateModelID, acknowledge: acknowledge, eventId: eventId });
                break; 
            default:
                return null
        }
        return endpoint
    };

    const changeAcknowledgement = async (e) => {
        if (!previewMode) {
            setLoading(true);
            e.stopPropagation();
            try {
                await onUpdateAcknowledgment();
                if(onStatusChanged){
                    await onStatusChanged()
                }
                setLoading(false);
            } catch (error) {
                console.log("error", error);
                setLoading(false);
            }
        }
    };

    const getStyleIcon = { fontSize: "1.5rem", opacity: acknowledgmentValue === "NotChecked" ? ".25" : "1" }
    const color = acknowledgmentValue !== "NotChecked" ? StyleVariables.SuccessColor : StyleVariables.GreyedColor
    
    return (
        button
            ?
            <NwButton
                tooltip={(acknowledgmentValue !== "NotChecked") ? "Click here to remove the acknowledgement" : "Click here to mark as acknowledged"}
                loading={isLoading} 
                icon={solid('check')}
                onClick={changeAcknowledgement} 
                iconOpacity={(acknowledgmentValue !== "NotChecked") ? 1 : .25} 
                className={acknowledgmentValue !== "NotChecked" ? 'event-acknowledged-button' : ''}
            />
            :
            (isLoading
                ?
                <MiniLoading />
                :
                <NwIcon onClick={changeAcknowledgement} style={getStyleIcon} icon={solid('check')} color={color} />
            )
    );

};
export default AcknowledgementEvent;
