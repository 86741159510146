import { useUserPermission } from 'Contexts/UserContext';
import { uniqBy } from 'lodash';

const useTransactionLockedStatus = (transaction) => {
    
    const isAccountant = useUserPermission('AccessAccounting');

    //get all client invoices for the transaction, collecting the invoice number, date and posted date
    const clientStatementsInvoicedOrLocked = transaction.ClientStatements.filter(statement => statement.Status === 'Invoiced' || statement.Status === 'Locked')
    const clientInvoices = clientStatementsInvoicedOrLocked
        .map(statement => ({
            invoiceID: statement.InvoiceID,
            invoiceNumber: statement.InvoiceNumber,
            invoiceDate: statement.InvoiceDate,
            postedDate: statement.InvoicePostedDate,
            readyToPost: statement.InvoiceReadyToPost
        }))
    const deduplicatedClientInvoices = uniqBy(clientInvoices, 'invoiceID')

    const modelStatementsInvoicedOrLocked = transaction.ModelStatements.filter(statement => statement.Status === 'Invoiced' || statement.Status === 'Locked')
    const modelInvoices = modelStatementsInvoicedOrLocked
        .map(statement => ({
            invoiceID: statement.InvoiceID,
            invoiceNumber: statement.InvoiceNumber,
            invoiceDate: statement.InvoiceDate,
            postedDate: statement.InvoicePostedDate,
            readyToPost: statement.InvoiceReadyToPost
        }))
    const deduplicatedModelInvoices = uniqBy(modelInvoices, 'invoiceID')

    const agencyStatementsInvoicedOrLocked = transaction.AgencyStatements.filter(statement => statement.Status === 'Invoiced' || statement.Status === 'Locked')
    const agencyInvoices = agencyStatementsInvoicedOrLocked
        .map(statement => ({
            invoiceID: statement.InvoiceID,
            invoiceNumber: statement.InvoiceNumber,
            invoiceDate: statement.InvoiceDate,
            postedDate: statement.InvoicePostedDate,
            readyToPost: statement.InvoiceReadyToPost
        }))
    const deduplicatedAgencyInvoices = uniqBy(agencyInvoices, 'invoiceID')

    const modelStatementsPaid = transaction.ModelStatements.filter(statement => statement.PaidStatusName === "Paid" || statement.PaidStatusName === "Partially Paid")
    const modelPayments = modelStatementsPaid
        .map(statement => ({
            PaymentID: statement.PaymentID,
            PaymentDate: statement.PaymentDate,
            PaymentNumber: statement.PaymentNumber,
        }))
    const deduplicatedModelPayments = uniqBy(modelPayments, 'PaymentID')

    const agencyStatementsPaid = transaction.AgencyStatements.filter(statement => statement.PaidStatusName === "Paid" || statement.PaidStatusName === "Partially Paid")
    const agencyPayments = agencyStatementsPaid
        .map(statement => ({
            PaymentID: statement.PaymentID,
            PaymentDate: statement.PaymentDate,
            PaymentNumber: statement.PaymentNumber,
        }))
    const deduplicatedAgencyPayments = uniqBy(agencyPayments, 'PaymentID')

    //all kind of statements that are locked (nw3locked o nw3payment)
    const statementsLocked = [...transaction.ClientStatements, ...transaction.ModelStatements, ...transaction.AgencyStatements].filter(statement => statement.Nw3Locked || statement.Nw3Payment)

    //transaction is locked if it has statements locked (legacy) or payments to models or agencies or client invoices
    const isLocked = statementsLocked.length > 0 || deduplicatedModelPayments.length > 0 || deduplicatedAgencyPayments.length > 0 || deduplicatedClientInvoices.length > 0 || deduplicatedModelInvoices.length > 0 || deduplicatedAgencyInvoices.length > 0

    //transaction can't be unlocked if it has statements locked (legacy) or payments to models or agencies or if user not an accountant
    const cantBeUnlocked = statementsLocked.length > 0 || deduplicatedModelPayments.length > 0 || deduplicatedAgencyPayments.length > 0 || !isAccountant

    return {
        isAccountant,
        isLocked,
        cantBeUnlocked,
        deduplicatedClientInvoices,
        deduplicatedModelInvoices,
        deduplicatedAgencyInvoices,
        deduplicatedModelPayments,
        deduplicatedAgencyPayments,
        statementsLocked,
    }

}

export default useTransactionLockedStatus;