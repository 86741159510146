import React, { useState, useContext, useEffect, Suspense } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import ls from 'local-storage';

//not used
//import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import 'react-vis/dist/style.css';
import { ConfigProvider } from 'antd';
import Axios from 'axios';
import 'moment/locale/en-nz';
import 'moment/locale/en-gb';
import en_GB from 'antd/lib/locale-provider/en_GB';

import NwLayout from 'Components/_LayoutComponents/Layout/NwLayout';
import DocumentLayout from 'Components/_LayoutComponents/Layout/DocumentLayout';
import { RouteLoading } from "Components/Gui/Loading";
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';

import { isDocumentPage } from 'Libs/NwUtils';
import DocEditor from 'Containers/Booking/DocEditor/DocEditor';
import './App.less';
import Interceptor from 'Libs/Interceptors';
import { prefetchModels, prefetchCustomers, prefetchContacts as prefetchContactList, prefetchPlaylistsCount } from 'Hooks/Contact/UseContactsList';
import { prefetchActivePackages } from 'Hooks/Packages/UsePackages';
import { prefetchFeatures, prefetchFeatureSets, prefetchConversions } from 'Hooks/Features/UseFeatures';
import { prefetchRoomConnections } from 'Hooks/Contact/UseContactConnections';
import { useStateValue } from 'Contexts/StateProvider';

import { ThemeProvider } from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables';
import QueryClientProvider from 'QueryClientProvider';
import constants from 'Constants';
import { MODELS_FILTERS_SET } from 'Configs/Filters/MODELS_FILTERS_SETS';
import { CUSTOMERS_FILTERS_SET } from 'Configs/Filters/CUSTOMERS_FILTERS_SETS';
import { useUserGuiConfig, useUserGuiConfigDispatch } from 'Contexts/UserGuiConfigContext';
import {  useFoldersDispatch } from 'Contexts/FoldersContext';
import { usergui } from 'Configs/USERGUI';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkZiWH9bdHFTRGlf');

// import ModelDetailsMobile from 'Components/ContactDetails/ModelDetails/ModelDetailsIndexMobile';
// import ModelDetailsIndex, { ModelDetailsBook, ModelDetailsHome, ModelDetailsMedia } from 'Components/ContactDetails/ModelDetails/ModelDetailsIndex';

const User = React.lazy(() => import('Containers/User'));
const Login = React.lazy(() => import('Containers/Authentication/Login'));
const ForgotPassword = React.lazy(() => import('Containers/Authentication/ForgotPassword'));
const ResetPassword = React.lazy(() => import('Containers/Authentication/ResetPassword'));
const PersonalModelsReport = React.lazy(() => import('Containers/User/Reports/PersonalModelsReport'));
const PersonalClientsReport = React.lazy(() => import('Containers/User/Reports/PersonalClientsReport'));



const MailReportPage = React.lazy(() => import('Components/MailReport/MailReportPage'));


const BookingDashboard = React.lazy(() => import('Containers/Booking/Dashboard/Dashboard'));
const SearchTasks = React.lazy(() => import('Containers/Booking/SearchTasks/SearchTasks'));

const ModelsPage = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelsPage'));
const ModelDetailsResponsive = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelDetailsResponsive'));
//const ModelDetailsMobile = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelDetailsIndexMobile'));
//const ModelDetailsHome = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelDetailsIndex').then(module => ({ default: module.ModelDetailsHome })));

const ModelDetailsMedia = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelDetailsIndex').then(module => ({ default: module.ModelDetailsMedia })));

const ModelsArchive = React.lazy(() => import('Containers/ContactsArchive/ModelsArchive'));
const Rooms = React.lazy(() => import('Containers/Booking/Rooms'));
const ModelsLegalSearch = React.lazy(() => import('Containers/Booking/ModelsLegalSearch/ModelsLegalSearch'));

const ProjectPage = React.lazy(() => import('Components/Project/ProjectPage'));
const ScheduleContainerWrapper = React.lazy(() => import('Containers/Booking/Schedule/ScheduleContainerWrapper'));
const EventsSearch = React.lazy(() => import('Containers/Booking/SearchEvents/EventsSearch'));
const EventsReportPage = React.lazy(() => import('Containers/Booking/Reports/EventsReport/EventsReportPage'));
const UsagesSearchPage = React.lazy(() => import('Components/UsagesSearch/UsagesSearchPage'));

const Packages = React.lazy(() => import('Containers/Booking/Packages/Packages'));

const CustomersPage = React.lazy(() => import('Components/ContactDetails/CustomerDetails/CustomersPage'));
const CustomerDetailsResponsive = React.lazy(() => import('Components/ContactDetails/CustomerDetails/CustomerDetailsResponsive'));
// const CustomerDetailsHome = React.lazy(() => import('Components/ContactDetails/CustomerDetails/CustomerDetailsIndex').then(module => ({ default: module.CustomerDetailsHome })));
// //const CustomerDetailsIndex = React.lazy(() => import('Components/ContactDetails/CustomerDetails/CustomerDetailsIndex'));
// const CustomerDetailsMobile = React.lazy(() => import('Components/ContactDetails/CustomerDetails/CustomerDetailsMobile/CustomerDetailsMobile'));
const MailingLists = React.lazy(() => import('Components/MailingLists'));
//const AddConnectionsToMailingList = React.lazy(() => import('Components/MailingLists/AdvancedSearch'));
const CustomersArchive = React.lazy(() => import('Containers/ContactsArchive/CustomersArchive/'));



const ScoutingDashboard = React.lazy(() => import('Containers/Scouting/Dashboard'));
const ScoutingMeetings = React.lazy(() => import('Containers/Scouting/ScoutingMeetings/ScoutingMeetingsSearch'));
const TravelPlansSearch = React.lazy(() => import('Containers/Scouting/TravelPlans/TravelPlansSearch'));
const TravelPlan = React.lazy(() => import('Containers/Scouting/TravelPlans/TravelPlan/TravelPlan'));

const AccountingDashboard = React.lazy(() => import('Containers/Accounting/Dashboard'));
const BillingProjects = React.lazy(() => import('Containers/Accounting/Billing/Projects/BillingProjects'));
const BillingExpenses = React.lazy(() => import('Containers/Accounting/Billing/Expenses/BillingExpenses'));
const BillingCommissions = React.lazy(() => import('Containers/Accounting/Billing/Commissions/BillingCommissions'));
const Invoices = React.lazy(() => import('Containers/Accounting/Invoices/Invoices'));
const PaymentsWrapper = React.lazy(() => import('Containers/Accounting/Payments/PaymentsWrapper'));
const InvoicePage = React.lazy(() => import('Containers/Accounting/Invoices/InvoicePageWrapper'));
const BalanceReport = React.lazy(() => import('Containers/Accounting/BalanceReport/BalanceReport'));
const ClientsReport = React.lazy(() => import('Containers/Accounting/Reports/ClientsReport'));
const ModelsReport = React.lazy(() => import('Containers/Accounting/Reports/ModelsReport'));
const BookersReport = React.lazy(() => import('Containers/Accounting/Reports/BookersReport'));
const ModelsAccountingPage = React.lazy(() => import('Components/ContactDetails/ModelDetails/ModelsAccountingPage'));
const AccountingAdmin = React.lazy(() => import('Containers/Accounting/Admin'));

//const ManagementDashboard = React.lazy(() => import('Containers/Management/Dashboard'));
const ManagementModelsPage = React.lazy(() => import('Containers/Management/Models/ManagementModelsPage'));
const ManagementCustomersPage = React.lazy(() => import('Containers/Management/Customers/ManagementCustomersPage'));
const WebsiteManagement = React.lazy(() => import('Containers/Management/WebsiteManagement'));
const UserManagement = React.lazy(() => import('Containers/Management/UserManagement'));
const UserPoliciesPage = React.lazy(() => import('Containers/Management/UserPolicies/UserPoliciesPage'));
const ConfigurationsPage = React.lazy(() => import('Containers/Management/Configuration/ConfigurationsPage'));
const ImportDataPage = React.lazy(() => import('Containers/Management/ImportData/ImportDataPage'));
const AgenciesDepartments = React.lazy(() => import('Containers/Management/AgenciesDepartments'));

const ImageEditorTest = React.lazy(() => import('Components/MediaDrawer/EditImageNew'));


const App = () => {
    const userContext = useContext(UserContext)
    const listContext = useContext(ListContext)

    const [isLoading, setIsLoading] = useState(false)

    const [prefetched, setPrefetched] = useState(false)
    

    const [{ areaContext }, dispatch] = useStateValue();

    const foldersDispatch = useFoldersDispatch()

    const userGuiConfig = useUserGuiConfig()
    const ugc_dispatch = useUserGuiConfigDispatch();

    useEffect(() => {
        if (prefetched) {
            listContext.LoadData().catch((error) => { console.log("ERROR", error) })
        }
    }, [prefetched])

    useEffect(() => {
        if (listContext.cachedListLoaded) {
            setIsLoading(false)
        }
    }, [listContext.cachedListLoaded]);

    const getUserClientConfigurations = async conversions => {
        try {
            const userGuiConfigurations = await Axios.get('/userclientconfigurations');
            if (userGuiConfigurations && userGuiConfigurations.length > 0) {
                const foldersData = userGuiConfigurations.find(configuration => configuration.Name === 'folders');
                if (foldersData && foldersData.JsonData.openFolders.length > 0) {
                    foldersDispatch({
                        type: 'UpdateFolders',
                        openFolders: foldersData ? [...foldersData.JsonData.openFolders] : []
                    });
                }

                const settingData = userGuiConfigurations.find(configuration => configuration.Name === 'settings');
                
                if (settingData && settingData.JsonData && settingData.JsonData.settings) {
                    var savedSettings = { ...settingData.JsonData.settings }
                    //if there are no metrics, set them as default values: all metrics
                    if (!savedSettings.metrics || savedSettings.metrics.length === 0) {
                        savedSettings.metrics = conversions ? conversions.map(conversion => {
                            return {
                                ...conversion,
                                name: conversion.Name,
                                metrics: conversion.AvailableMetrics.split(',')
                            }
                        }) : []
                    }
                    ugc_dispatch({ type: "Update", value: savedSettings })
                } else {
                    //if there are no settings at all, set at least the metrics as default values: all metrics
                    const newsettings = {
                        ...usergui,
                        metrics: conversions ? conversions.map(conversion => {
                            return {
                                ...conversion,
                                name: conversion.Name,
                                metrics: conversion.AvailableMetrics.split(',')
                            }
                        }) : []
                    }
                    ugc_dispatch({ type: "Update", value: newsettings })
                }

                const alertsData = userGuiConfigurations.find(configuration => configuration.Name === 'alerts');
                if (alertsData) {
                    for (const area of constants.AREAS) {
                        ls.set(`nw_config_${area}-read-alerts`, alertsData.JsonData[area] || []);
                    }
                }

                const modelSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'model-custom-smart-lists');
                const modelSmartLists = [...MODELS_FILTERS_SET.staticSets]
                if (modelSmartListData) {
                    if (modelSmartListData.JsonData.customSmartLists && modelSmartListData.JsonData.customSmartLists.length > 0) {
                        modelSmartLists.push(...modelSmartListData.JsonData.customSmartLists);
                    }
                    if (modelSmartListData.JsonData && modelSmartListData.JsonData.length > 0) {
                        modelSmartLists.push(...modelSmartListData.JsonData);
                    }
                }
                const modelSmartListsWithOriginal = modelSmartLists.map((smartList) => {
                    return { ...smartList, originalFilters: [...smartList.filters], unsaved: false }
                })
                ls.set("nw_config_model_smartlists", modelSmartListsWithOriginal);

                const customerSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'customer-custom-smart-list');
                const customerSmartLists = [...CUSTOMERS_FILTERS_SET.staticSets]
                if (customerSmartListData) {
                    customerSmartLists.push(...customerSmartListData.JsonData);
                }
                const customerSmartListsWithOriginal = customerSmartLists.map((smartList) => {
                    return { ...smartList, originalFilters: [...smartList.filters], unsaved: false }
                })
                ls.set("nw_config_customer_smartlists", customerSmartListsWithOriginal);

                const eventSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'event-smart-list');
                if (eventSmartListData) {
                    dispatch({
                        type: "EventSaveCustomSets",
                        customSets: eventSmartListData.JsonData.smartList
                    });
                }

                const jobSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'job-smart-list');
                if (jobSmartListData) {
                    dispatch({
                        type: "JobSaveCustomSets",
                        customSets: jobSmartListData.JsonData.smartList
                    });
                }

                const travelPlanSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'travelplan-smart-list');
                if (travelPlanSmartListData) {
                    dispatch({
                        type: "TravelPlanSaveCustomSets",
                        customSets: travelPlanSmartListData.JsonData.smartList
                    });
                }
            } else {
                ugc_dispatch({ type: "Update", value: {
                    ...userGuiConfig,
                    metrics: conversions ? conversions.map(conversion => {
                        return {
                            ...conversion,
                            name: conversion.Name,
                            metrics: conversion.AvailableMetrics.split(',')
                        }
                    }) : []
                }})
            }
        } catch (error) {
           console.log('ant : Get User Client Configurations Error => ', error);
        }
    };

    useEffect(() => {
        const prefetchContacts = async () => {
            setIsLoading(true);

            if (!prefetched) {
                await prefetchModels();
                await prefetchPlaylistsCount();
                await prefetchContactList();
                await prefetchActivePackages(userContext.currentUser.UserID);
                await prefetchCustomers();
                await prefetchFeatures();
                await prefetchFeatureSets();
                await prefetchRoomConnections();
                const conversions = await prefetchConversions();
                await getUserClientConfigurations(conversions);
                setPrefetched(true);
            }
        }

        if (userContext.currentUser) {
            prefetchContacts();
        } else {
            if (prefetched) {
                setPrefetched(false);
            }
        }
    }, [userContext.currentUser])

    const RequireAuth = ({ children }) => {
        let isAuthenticated = userContext.currentUser;
        return isAuthenticated ? children : <Navigate to={"/login"} />;
    }

    return (
        <QueryClientProvider>
            {/* locale={userGuiConfig.calendar.weekFormat === 'monday' ? en_GB : en_US} */}
            <ConfigProvider locale={en_GB}>
                <ThemeProvider theme={StyleVariables}>
                    <Interceptor />
                    {isDocumentPage() ? (
                        isLoading && !listContext.cachedListLoaded ?
                            <DocumentLayout>
                                <RouteLoading />
                            </DocumentLayout>
                            : <Route path="/document/:documentId/objectId/:objectId" element={<RequireAuth><DocEditor /></RequireAuth>} />
                    ) :
                        ((isLoading && !listContext.cachedListLoaded) || areaContext.areaContactsLoading) ?
                            <NwLayout>
                                <RouteLoading />
                            </NwLayout>
                            :
                            <div>
                                <NwLayout>
                                    <Suspense fallback={<RouteLoading />}>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={<RequireAuth><BookingDashboard /></RequireAuth>}
                                            />

                                            {/* not authenticated routes */}
                                            <Route path="login" element={<Login />} />
                                            <Route path="forgot-password" element={<ForgotPassword />} />
                                            <Route path="reset-password" element={<ResetPassword />} />

                                            {/* authentication and user profile */}
                                            <Route
                                                path="user/*"
                                                element={<RequireAuth><User /></RequireAuth>}
                                            />

                                            <Route
                                                path="user/personal-reports/models"
                                                element={<RequireAuth><PersonalModelsReport /></RequireAuth>}
                                            />

                                            <Route
                                                path="user/personal-reports/clients"
                                                element={<RequireAuth><PersonalClientsReport /></RequireAuth>}
                                            />

                                            {/* e-mail report (links from user icon and management navbar */}

                                            <Route
                                                path="email-report"
                                                element={<RequireAuth><MailReportPage /></RequireAuth>}
                                            />

                                            {/* dashboard and dashboard links */}
                                            <Route
                                                path="dashboard"
                                                element={<RequireAuth> <BookingDashboard /> </RequireAuth>}
                                            />
                                            <Route
                                                path="search-tasks/:contactId?"
                                                element={<RequireAuth><SearchTasks /></RequireAuth>}
                                            />

                                            {/* models */}
                                            <Route
                                                path="models/:listType?/:activeSet?"
                                                element={
                                                    <RequireAuth>
                                                        <ModelsPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="models/actions/:action/:modelToCompare?" ///:listType?/:activeSet?
                                                element={
                                                    <RequireAuth>
                                                        <ModelsPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="model/:modelId/:listType?/:activeSet?"
                                                element={
                                                    <RequireAuth>
                                                        <ModelDetailsResponsive />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="model/:modelId/media/:listType?/:activeSet?"
                                                element={
                                                    <RequireAuth>
                                                        <ModelDetailsMedia />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="model/:modelId/newbook/:listType?/:activeSet?"
                                                element={
                                                    <RequireAuth>
                                                        <ModelDetailsMedia newBook />
                                                    </RequireAuth>
                                                }
                                            />

                                            <Route
                                                path="models-archive"
                                                element={<RequireAuth><ModelsArchive /></RequireAuth>}
                                            />

                                            <Route
                                                path="rooms/*"
                                                // subsections are "reports" and "chart"
                                                element={<RequireAuth><Rooms /></RequireAuth>}
                                            />

                                            <Route
                                                path="models-legal"
                                                element={<RequireAuth><ModelsLegalSearch /></RequireAuth>}
                                            />

                                            {/* schedule and bookings (projects) */}
                                            <Route
                                                path="bookings/:projectId/:eventId?/:eventType?"
                                                element={<RequireAuth><ProjectPage /></RequireAuth>}
                                            />
                                            <Route
                                                path="schedule/:listType?/:activeSet?/:modelId?"
                                                element={<RequireAuth><ScheduleContainerWrapper /></RequireAuth>}
                                            />
                                            <Route
                                                path="events-search"
                                                element={<RequireAuth><EventsSearch /></RequireAuth>}
                                            />
                                            <Route
                                                path="events-report"
                                                element={<RequireAuth><EventsReportPage /></RequireAuth>}
                                            />
                                            <Route
                                                path="usages-search"
                                                element={<RequireAuth><UsagesSearchPage /></RequireAuth>}
                                            />

                                            {/* packages */}
                                            <Route
                                                path="packages/*" // * = active for active packages, otherwise sent packages
                                                element={<RequireAuth><Packages /></RequireAuth>}
                                            />

                                            {/* contacts (customers) */}
                                            <Route
                                                path="customers/:listType?/:activeSet?"
                                                element={
                                                    <RequireAuth>
                                                        <CustomersPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="customer/:customerId/:listType?/:activeSet?" //
                                                element={
                                                    <RequireAuth>
                                                        <CustomerDetailsResponsive />
                                                        {/* {breakpoint === 1
                                                            ?
                                                            <CustomerDetailsMobile />
                                                            :
                                                            <CustomerDetailsHome />
                                                        } */}
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="customers-archive"
                                                element={
                                                    <RequireAuth>
                                                        <CustomersArchive />
                                                    </RequireAuth>
                                                }
                                            />

                                            {/* mailing lists */}
                                            <Route
                                                path="mailing-lists/:mailingListId?"
                                                element={
                                                    <RequireAuth>
                                                        <MailingLists />
                                                    </RequireAuth>
                                                }
                                            />


                                            <Route
                                                path="scouting"
                                                element={
                                                    <RequireAuth>
                                                        <ScoutingDashboard />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="scouting/travels"
                                                element={
                                                    <RequireAuth>
                                                        <TravelPlansSearch />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="scouting/travel/:travelId"
                                                element={
                                                    <RequireAuth>
                                                        <TravelPlan />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="scouting/meetings/:contactId?"
                                                element={
                                                    <RequireAuth>
                                                        <ScoutingMeetings />
                                                    </RequireAuth>
                                                }
                                            />

                                            <Route
                                                path="accounting"
                                                element={
                                                    <RequireAuth>
                                                        <AccountingDashboard />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="billing/projects"
                                                element={
                                                    <RequireAuth>
                                                        <BillingProjects />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="billing/expenses"
                                                element={
                                                    <RequireAuth>
                                                        <BillingExpenses />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="billing/commissions"
                                                element={
                                                    <RequireAuth>
                                                        <BillingCommissions />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="invoices"
                                                element={
                                                    <RequireAuth>
                                                        <Invoices />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="invoice/:invoiceId?"
                                                element={
                                                    <RequireAuth>
                                                        <InvoicePage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="payments/:type?"
                                                element={
                                                    <RequireAuth>
                                                        <PaymentsWrapper />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="/balance-report/:type?"
                                                element={
                                                    <RequireAuth>
                                                        <BalanceReport />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting-reports"
                                                element={
                                                    <RequireAuth>
                                                        <ClientsReport />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting-reports/clients"
                                                element={
                                                    <RequireAuth>
                                                        <ClientsReport />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting-reports/bookers"
                                                element={
                                                    <RequireAuth>
                                                        <BookersReport />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting-reports/models"
                                                element={
                                                    <RequireAuth>
                                                        <ModelsReport />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting/models"
                                                element={
                                                    <RequireAuth>
                                                        <ModelsAccountingPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            {/* contacts (customers) */}
                                            <Route
                                                path="accounting/customers"
                                                element={
                                                    <RequireAuth>
                                                        <CustomersPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="accounting/admin/:section"
                                                element={
                                                    <RequireAuth>
                                                        <AccountingAdmin />
                                                    </RequireAuth>
                                                }
                                            />

                                            {/* TEMP: Removed dashboard as it's empty */}
                                            {/* <Route
                                                path="management"
                                                element={
                                                    <RequireAuth>
                                                        <ManagementDashboard />
                                                    </RequireAuth>
                                                }
                                            /> */}
                                            <Route
                                                path="management"
                                                element={
                                                    <RequireAuth>
                                                        <AgenciesDepartments />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/models"
                                                element={
                                                    <RequireAuth>
                                                        <ManagementModelsPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/customers"
                                                element={
                                                    <RequireAuth>
                                                        <ManagementCustomersPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/configuration/:section"
                                                element={
                                                    <RequireAuth>
                                                        <ConfigurationsPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/users"
                                                element={
                                                    <RequireAuth>
                                                        <UserManagement />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/user-policies/:section"
                                                element={
                                                    <RequireAuth>
                                                        <UserPoliciesPage />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/website"
                                                element={
                                                    <RequireAuth>
                                                        <WebsiteManagement />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/structure"
                                                element={
                                                    <RequireAuth>
                                                        <AgenciesDepartments />
                                                    </RequireAuth>
                                                }
                                            />
                                            <Route
                                                path="management/import/:section"
                                                element={
                                                    <RequireAuth>
                                                        <ImportDataPage />
                                                    </RequireAuth>
                                                }
                                            />



                                            {/* 
                                            <PrivateRoute exact path="/management" component={ManagementDashboard} />
                                            <PrivateRoute path="/management-dashboard" component={ManagementDashboard} />
                                            
                                            <PrivateRoute path="/user-management" component={UserManagement} />
                                            <PrivateRoute path="/user-policies" component={UserPolicies} />
                                            <PrivateRoute path="/configuration" component={Configuration} />
                                            <PrivateRoute path="/import" component={ImportData} />
                                            <PrivateRoute path="/agencies-departments" component={AgenciesDepartments} /> */}
                                        </Routes>
                                    </Suspense>
                                </NwLayout>
                            </div>}
                </ThemeProvider>
            </ConfigProvider>
        </QueryClientProvider>
    )
}
export default App;
