import { useContext } from 'react';
import { ListContext } from 'Contexts/ListContext';
//import { UserContext } from 'Contexts/UserContext';

const useNetworkAgencyName = (networkAgencyId) => {
    const { cachedList } = useContext(ListContext);
    //const { currentUser } = useContext(UserContext);
    if (!networkAgencyId) {
        return ""
    }
    for (const accGroup of cachedList.accountingGroups.items) {
        for (const nwAg of accGroup.NetworkAgencies) {
            if (networkAgencyId) {
                if (nwAg.ID === networkAgencyId) {
                    return nwAg.Name
                }
            }
        }
    }
    return ""
}

export default useNetworkAgencyName