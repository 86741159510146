import { getLastLogs } from "Libs/NwLogs"

const deviceNames = ['mobile', 'tablet', 'laptop', 'wide', 'xwide', 'xxwide']

const getDeviceName = (device) => {
    return deviceNames[device - 1]
}

export const getNavClassName = (device, navItemsLength) => {
    let cl = `nw-main-nav-${getDeviceName(device)}`
    if (navItemsLength > 5) {
        cl += ' nw-main-nav-smaller'
    }
    return cl
}

export const getNavMenuMode = (drawer, device) => {
    return (drawer && (device === 1)) ?  'inline' : 'horizontal'
}

export const redirectOnAreaChange = (allLogs, location, current, pageurl) => {
    const modelLinks = allLogs.modelLogs.map((log) => {
        return { name: log.name, link: `/models/${log.id}` }
    })
    const customerLinks = allLogs.customerLogs.map((log) => {
        return { name: log.name, link: `/customers/${log.id}` }
    })
    const projectLinks = allLogs.projectLogs.map((log) => {
        return { name: log.name, link: `/bookings/${log.id}` }
    })
    const travelPlanLinks = allLogs.travelPlanLogs.map((log) => {
        return { name: log.name, link: `/travel-plans/${log.id}` }
    })
    const statementLinks = allLogs.statementLogs.map((log) => {
        return { name: log.name, link: `/invoice-drawer/${log.id}` }
    })

    
    const basepageurl = `/${pageurl.split("/")[1]}`

    const containLink = (p) => {
        return l => l.link.toLowerCase() === p.toLowerCase()
    }

    if (current !== pageurl) {
        let currentToSet = basepageurl;
        switch (basepageurl) {
            case "/models":
            //case "/scouting-models":
                if (modelLinks.filter(containLink(pageurl)).length > 0) {
                    currentToSet = pageurl
                }
                break;
            case "/customers":
                if (customerLinks.filter(containLink(pageurl)).length > 0) {
                    currentToSet = pageurl
                }
                break;
            case "/bookings":
                if (projectLinks.filter(containLink(pageurl)).length > 0) {
                    currentToSet = pageurl
                }
                break;
            case "/scouting/travels":
                if (travelPlanLinks.filter(containLink(pageurl)).length > 0) {
                    currentToSet = pageurl
                }
                break;
            case "/invoices":
                if (statementLinks.filter(containLink(pageurl)).length > 0) {
                    currentToSet = pageurl
                }
                break;
            default:
                break;
        }
        return currentToSet
    }
    return null
}

export const getNavLogs = (area) => {
    const modelLogs = getLastLogs(`${area}_model`, 10);
    const customerLogs = getLastLogs(`${area}_customer`, 10);
    const projectLogs = getLastLogs("project", 5).map(log => {
        const names = log.name.split('|');
        return { ...log, name: names[0], customerName: names[1], product: names[2] };
    })
    const statementLogs = getLastLogs("statement", 5).map(log => {
        const names = log.name.split('|');
        return { ...log, name: names[0], customerName: names[1] };
    })
    const travelPlanLogs = getLastLogs("travelPlan", 5).map(log => {
        const names = log.name.split('|');
        return { ...log, name: names[0], scouterName: names[1] };
    })
    
    const allLogs = {
        modelLogs,
        customerLogs,
        projectLogs,
        statementLogs,
        travelPlanLogs
    }
    return allLogs
}

// export const getContactUrl = (pathName, area, viewType, customers, models) => {
//     const paths = pathName.split('/');
//     let restPaths = [...paths];
//     restPaths.splice(0, 2);
//     const restPath = restPaths.join('/');
//     if (viewType === 'scouting') {
//         return '/scouting-dashboard';
//     }
//     if (viewType === 'management') {
//         return '/management-dashboard';
//     }
//     if (paths[1] === `${area}-models` || paths[1] === 'models') {
//         if (models.find(model => model.ID === parseInt(restPath))) {
//             return viewType === 'booking' ? `/models/${restPath}` : `/${viewType}-models/${restPath}`;
//         }
//     } else if (paths[1] === `${area}-contacts` || paths[1] === 'contacts') {
//         if (customers.find(customer => customer.ID === parseInt(restPath))) {
//             return viewType === 'booking' ? `/customer/${restPath}` : `/${viewType}-customer/${restPath}`;
//         }
//     }
//     return viewType === 'booking' ? '/dashboard' : `/${viewType}-dashboard`;
// }

export const getAreaUrl = (newarea) => {
    if (newarea === 'booking') {
        return '/dashboard';
    }
    return `/${newarea}`;
}