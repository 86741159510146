import React from "react";
import { Modal } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import NwDivider from "Components/Gui/NwDivider";
import FlexContainer from 'Components/Gui/FlexContainer';
import AddressItem from "Components/Connection/AddressItem";
import NwIcon from 'Components/Gui/NwIcon';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { forceReloadContactBillingInfos } from "Hooks/Contact/UseContactBillingInfos";
import { useContext } from "react";
import { ListContext } from "Contexts/ListContext";
import { useUserPermission } from "Contexts/UserContext";

const Wrapper = styled.div`
    .ant-card {
        border-right: ${props => props.isMobile && 'none'};
        border-left: ${props => props.isMobile && 'none'};
        border-radius: ${props => props.isMobile && 0};
        opacity: ${props => props.disabled && 0.5};

        .ant-card-head {
            padding-right: ${props => props.isMobile && 0};
            padding-left: ${props => props.isMobile && 0};

            .billinginfo-header {
                display: flex;
                font-size: 1rem !important;
                font-weight: ${props => props.isDefault ? 'bold !important' : 'normal'};
            }

            h1 {
                position: ${props => props.isMobile && 'unset'};
                left: ${props => props.isMobile && 'unset'};
            }
        }

        .ant-card-body-content {
            padding: ${props => props.isMobile && '8px 0'};

            .billinginfo-row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                
                p {
                    flex: 1 1 0;
                    strong {
                        padding-left: .5rem;
                    }
                }
            }
        }
    }
`;

const BillingInfoItem = ({ item, contactId, type, isDefault, onEditDoc }) => {
    const breakpoints = useNwBreakPoints();
    const { cachedList } = useContext(ListContext);

    const invoicetypedefinitions = (type === 'customer' ? cachedList.taxmethods.items.CustomerInvoiceTypes :                                     cachedList.taxmethods.items.ModelInvoiceTypes)
    const invoiceType = invoicetypedefinitions.find(invtype => invtype.Code === item.InvoiceType);

    const paymentTerm = item.PaymentTermCode ? cachedList.paymentTerms.items.find(pt => pt.Code === item.PaymentTermCode) : null;

    const canChangeBillingInfos = useUserPermission("ChangeBillingInfos")

    const handleDisableItem = async () => {
        const data = {
            BillingInfoID: item.ID,
            ContactID: contactId
        }
        try {
            await Axios.put(`/BillingInfos/DisableForContact`, data);
            forceReloadContactBillingInfos(contactId)
            forceReloadContact(contactId)
        } catch (error) {
            console.log('ant : disable billing info error => ', error);
        }
    };

    const handleEnableItem = async () => {
        const data = {
            BillingInfoID: item.ID,
            ContactID: contactId
        }
        try {
            await Axios.put(`/BillingInfos/EnableForContact`, data);
            forceReloadContactBillingInfos(contactId)
            forceReloadContact(contactId)
        } catch (error) {
            console.log('ant : enable billing info error => ', error);
        }
    };

    const handleSetDefault = async () => {
        const data = {
            BillingInfoID: item.ID,
            ContactID: contactId
        }
        try {
            await Axios.put(`/BillingInfos/SetAsDefaultForContact`, data)
            forceReloadContactBillingInfos(contactId)
        } catch (error) {
            console.log('ant : set default billing info error => ', error)
        }
    };

    const handleUnlinkFromContact = async () => {
        const data = {
            BillingInfoID: item.ID,
            ContactID: contactId
        }
        try {
            await Axios.put(`/BillingInfos/UnLinkFromContact`, data)
            forceReloadContactBillingInfos(contactId)
            forceReloadContact(contactId)
        } catch (error) {
            Modal.error({
                title: error.response.data.Message
            });
            console.log('ant : set default billing info error => ', error.response.data)
        }
    }

    const renderActions = () => {
        if (!canChangeBillingInfos) {
            return <></>
        }
        return (
            <NwDropDownMenu
                size='small'
                actions={[
                    { name: "", key: "edit", pinned: true, icon: light("pen"), action: onEditDoc},
                    { name: item.Disabled ? 'enable' : 'disable', key: "disabled", icon: item.Disabled ? light('check-circle') : light('ban'), disabled: item.isDefault, action: item.Disabled ? handleEnableItem : handleDisableItem },
                    { name: 'set as default', key: "default", icon: light("bookmark"), disabled: item.isDefault, action: handleSetDefault },
                    { name: "remove from contact", type: "delete", key: "remove", icon: light("trash-alt"), disabled: item.isDefault, confirmationPlacement: "left", action: handleUnlinkFromContact }
                ]}
            />
        )
    };

    const getNameLabel = () => {
        if (type.toLowerCase() === "model") {
            return "Billing Name";
        }
        return "Company Billing Name"
    }

    return (
        <Wrapper isMobile={breakpoints === 1} disabled={item.Disabled}>
            <NwCard
                title={
                    <div className="billinginfo-header">
                        {isDefault && <><NwIcon primary icon={solid('bookmark')} />&nbsp;</>}
                        {item.Disabled && <><NwIcon icon={light('ban')} />&nbsp;</>}
                        {item.CompanyName}
                    </div>
                }
                extra={renderActions()}>
                <NwDivider>General Info</NwDivider>
                <div className="billinginfo-row">
                    <p>Billing Code: <strong>{item.BillingCode}</strong></p>
                </div> 
                <div className="billinginfo-row">
                    <p>{getNameLabel()}: <strong>{item.CompanyName}</strong></p>
                </div> 
                <div className="billinginfo-row">
                    <p>VAT #:<strong>{item.VatNumber}</strong></p>
                </div>
                <div className="billinginfo-row">
                    <p>SSN / Tax ID / CF:<strong>{item.FiscalID}</strong></p>
                </div>
                <div className="billinginfo-row">
                    <p>Billing Type:<strong>{invoiceType.Code} - {invoiceType.Name}</strong></p>
                </div>
                {paymentTerm &&
                <div className="billinginfo-row">
                    <p>Default Payment Terms:<strong>{paymentTerm.Code} {(paymentTerm.Name !== paymentTerm.Code) ? ` - ${paymentTerm.Name}` : ''}</strong></p>
                </div>
                }
                {(item.PEC || item.SDI) ?
                    <>
                        <div className="billinginfo-row">
                            <p>PEC:<strong>{item.Pec}</strong></p>
                        </div>
                        <div className="billinginfo-row">
                            <p>SDI:<strong>{item.SDI}</strong></p>
                        </div>
                    </>
                    :
                    null
                }
                <NwDivider>Address</NwDivider>
                <AddressItem address={item} contactID={contactId} readOnly />
                <NwDivider>Contact</NwDivider>
                <div className="billinginfo-row">
                    <p>Name:<strong>{item.ContactName}</strong></p>
                </div>
                <div className="billinginfo-row">
                    <p>Email:<strong>{item.ContactEmail}</strong></p>
                </div>
                <div className="billinginfo-row">
                    <p>Phone:<strong>{item.ContactPhone}</strong></p>
                </div>
                {(item.ContactName2 || item.ContactEmail2 || item.ContactPhone2)
                ?
                    <>
                        <NwDivider>Alternative contact</NwDivider>
                        <div className="billinginfo-row">
                            <p>Name:<strong>{item.ContactName2}</strong></p>
                        </div>
                        <div className="billinginfo-row">
                            <p>Email:<strong>{item.ContactEmail2}</strong></p>
                        </div>
                        <div className="billinginfo-row">
                            <p>Phone:<strong>{item.ContactPhone2}</strong></p>
                        </div>
                    </>
                :
                    null
                }
            </NwCard>
        </Wrapper>
    );
};

export default BillingInfoItem;
