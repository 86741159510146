import React from "react";
import styled from "styled-components";
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { showLog } from "Libs/NwMoment";
import NwIcon from "Components/Gui/NwIcon";
import { NwButton } from "Components/Gui/NwButton";
import Axios from "axios";
import { forceReloadInvoice } from "Hooks/Invoices/UseInvoices";
import { useUserPermission } from "Contexts/UserContext";

const Wrapper = styled.div`
    padding: 1rem 0 2rem 0;
    .top-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: .5rem 2rem;
        background: ${props => props.theme.SelectedBackgroundColor};
        border: ${props => props.theme.DefaultBorder};
        border-radius: .5rem;

        .top-message-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            
            .top-message-icon {
                font-size: 1.5em;
                padding-right: 1rem;
                border-right: ${props => props.theme.DefaultBorder};
            }
            .top-message-text {
                font-size: 1em;
                font-weight: bold;
            }
        }
        .top-message-button {
        }

        &.warning {
            .top-message-left {
                .top-message-icon {
                    --fa-primary-color: ${props => props.theme.DangerColor}; 
                    --fa-secondary-color: ${props => props.theme.WarningColor};
                    --fa-primary-opacity: 1;
                    --fa-secondary-opacity: 1;
                }
            }
        }
        &.success {
            .top-message-left {
                .top-message-icon {
                    --fa-primary-color: ${props => props.theme.SuccessColor}; 
                    --fa-secondary-color: ${props => props.theme.SuccessColorLight};
                    --fa-primary-opacity: 1;
                    --fa-secondary-opacity: 1;
                }
            }
        }
    }
`;

const InvoiceSentStatus = ({ invoiceData }) => {

    const setAsNotReadyToPost = async () => {
        await Axios.put(`/Invoices/SetReadyToPost`, { InvoiceID: invoiceData.ID, ReadyToPost: false });
        forceReloadInvoice(invoiceData.ID);
    }

    const canPost = useUserPermission("PostInvoice")

    return (
        <Wrapper>
            {invoiceData.Posted
                ?
                <div className="top-message success">
                    <div className="top-message-left">
                        <div className="top-message-icon">
                            <NwIcon icon={duotone("file-export")} />
                        </div>
                        <div className="top-message-text">This invoice has been sent on {showLog(invoiceData.PostedDate)}</div>
                    </div>
                </div>
                :
                (invoiceData.ReadyToPost
                    ?
                    <div className="top-message warning">
                        <div className="top-message-left">
                            <div className="top-message-icon">
                                <NwIcon icon={duotone("file-lock")} />
                            </div>
                            <div className="top-message-text">This invoice is ready to be sent and can't be updated</div>
                        </div>
                        {canPost &&
                            <div className="top-message-button">
                                <NwButton type="primary" onClick={setAsNotReadyToPost} label="Unlock" />
                            </div>
                        }
                    </div>
                    :
                    <></>
                )
            }
        </Wrapper >
    )
}

export default InvoiceSentStatus;