export const PAYMENTS_FILTERS_SETS = {
    activeSet: "v1",
    customSets: [],
    staticSets: [
        {
            id: "v1",
            name: "to post",
            fixed: true,
            filters: [
                { name: "PostedStatus", value: "NotReadyToPost"},
            ]
        }
    ]
}