import React, { useState, useMemo } from "react";
import styled from 'styled-components';
import ContactsSelectedFilterItem from "./ContactsSelectedFilterItem";
import { useContactsSelector } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import { sortBy } from "lodash";
import ModelAgencySelectedFilterItems from "Components/ContactsSelector/Common/ModelAgencySelectedFilterItems";

const Wrapper = styled.div`
    width: 100%;
    padding: 0 .25rem;
`;

const ContactsSelectedFilters = () => {

    const { selection } = useContactsSelector();
    const { filters } = selection;

    const activeFiltersSortedByArea = useMemo(() => {
        let filtersToSort = [];
        if (filters && filters.active.length > 0) {
            filtersToSort.push(...filters.active.filter(f => f.area !== "agency"));
            filtersToSort = sortBy(filtersToSort, ['areaIndex', 'area']);
        }
        return filtersToSort;
    }, [filters.active]);

    return (
        <Wrapper>
            <ModelAgencySelectedFilterItems />
            {(activeFiltersSortedByArea && activeFiltersSortedByArea.length > 0)
                ?
                (activeFiltersSortedByArea.map(el => (
                    <ContactsSelectedFilterItem
                        key={el.name}
                        filter={el}
                    />
                )))
                :
                <></>
            }
        </Wrapper>
    );
};

export default ContactsSelectedFilters;
