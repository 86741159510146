import React, { useContext } from 'react';
import { HeaderContext } from 'Components/_LayoutComponents/Header/NwHeader';
import { NavMenu, NwNavIcon } from 'Components/_LayoutComponents/Header/NwHeaderUI';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getNavClassName, getNavMenuMode } from './NwNavUtils';
import { useUserPermission } from 'Contexts/UserContext';

const NwNavBarBooking = ({
    allLogs,
    createNewItemMenu,
    current,
    drawer,
    handleChangeOpenKeys,
    handleClick,
    isLoaded,
    isOpen,
    lastSeenMenu,
    openKeys,
}) => {

    const headerContextValues = useContext(HeaderContext);
    const { device } = headerContextValues;
    const ViewModelLegals = useUserPermission("ViewModelLegals");
    const { modelLogs, customerLogs, projectLogs } = allLogs;

    const navItems = [
        { key: "/dashboard", label: "Dashboard", disabled: !isLoaded },
        {
            key: "/menu-models",
            label: "Models",
            popupClassName: !isOpen('/menu-models') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/models' }),
            children: [
                lastSeenMenu(modelLogs, 'model', 'no models'),
                {
                    type: "group",
                    key: `/menu-tools-models`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/models',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("radar")} />Browse Models
                                </div>
                            )
                        },
                        {
                            key: '/models-archive',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("archive")} />Models Archive
                                </div>
                            )
                        },
                        {
                            key: '/models-legal',
                            disabled: !ViewModelLegals,
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("passport")} />Models Documents Search
                                </div>
                            )
                        },
                        {
                            key: '/rooms/chart',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("house-user")} />Rooms Management
                                </div>
                            )
                        }
                    ]
                },
                createNewItemMenu('Model')
            ]
        },
        {
            key: "/menu-schedule",
            label: "Bookings",
            popupClassName: !isOpen('/menu-schedule') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/schedule' }),
            children: [
                lastSeenMenu(projectLogs, "bookings", "no projects", "customerName"),
                {
                    type: "group",
                    key: `/menu-tools-projects`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/schedule',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("calendar")} />Schedule
                                </div>
                            )
                        },
                        {
                            key: '/events-search',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("business-time")} />Events Search
                                </div>
                            )
                        },
                        {
                            key: '/events-report',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("file-spreadsheet")} />Events Report
                                </div>
                            )
                        },
                        {
                            key: '/usages-search',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("handshake")} />Usages Search
                                </div>
                            )
                        },
                    ]
                },
                createNewItemMenu('Project')
            ]
        },
        {
            key: "/menu-contacts",
            label: "Contacts",
            popupClassName: !isOpen('/menu-contacts') ? 'main-nav-submenu menu-disabled' : 'main-nav-submenu menu-double',
            disabled: !isLoaded,
            onTitleClick: () => handleClick({ key: '/customers' }),
            children: [
                lastSeenMenu(customerLogs, 'customer', 'no contacts'),
                {
                    type: "group",
                    key: `/menu-tools-contacts`,
                    label: (
                        <span className="submenu-title-wrapper">
                            <NwNavIcon icon={light("bolt")} />Tools
                        </span>
                    ),
                    children: [
                        {
                            key: '/customers',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("radar")} />Browse Contacts
                                </div>
                            )
                        },
                        {
                            key: '/customers-archive',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("archive")} />Customers Archive
                                </div>
                            )
                        },
                        {
                            key: '/mailing-lists' || '/mailing-lists/:mailingListId',
                            label: (
                                <div className="nw-main-nav-subitems">
                                    <NwNavIcon icon={light("mailbox")} />Mailing List
                                </div>
                            )
                        },
                    ]
                },
                createNewItemMenu('Customer'),
            ]
        }
    ]

    const keysWithSubmenus = navItems.filter(item => item.children).map(item => item.key);

    return (
        <NavMenu
            $device={device}
            className={getNavClassName(device, navItems.length)}
            inlineIndent={10}
            items={navItems}
            mode={getNavMenuMode(drawer, device)}
            onClick={handleClick}
            onOpenChange={(k) => handleChangeOpenKeys(k, keysWithSubmenus)}
            openKeys={openKeys}
            selectedKeys={[current]}
        />
    )
}

export default NwNavBarBooking;