import React, { useMemo } from "react";
import styled from 'styled-components';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwIcon from 'Components/Gui/NwIcon';
import { NwButton, NwLinkButton } from "Components/Gui/NwButton";
import { useUserPermission } from "Contexts/UserContext";
import NotAllowedFeature from "Components/Utils/NotAllowedFeature";


const BillingInfoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    margin-top: .5rem;

    .billinginfo-container-details {
        flex: 1 1 0;
        padding-left: 1rem;
        p {
            strong {
                font-weight: 600;
            }
        }
    }

    p {
        font-size: .9rem;
        margin: 0;
        line-height: 1.15em;
    }
`

const BillingInfoContainerExtended = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 2.5rem;
    margin-top: 1rem;
    padding-bottom: .75rem;
    border-bottom: ${props => props.theme.DefaultBorder};
    padding-left: 1rem;

    svg {
        opacity: .6;
    }

    .billinginfo-container-details {
        flex: 1 1 0;
        padding-left: 2rem;
        p {
            font-size: ${props => props.theme.FontSizeBase};
            line-height: 1.25em;
            margin: 0 0 .25rem 0;
            strong {
                font-weight: 600;
            }
        }
    }
`

const BillingInfo = ({ data, editable, onClick, buttonLabel, extended }) => {

    const canViewBillingInfos = useUserPermission("ViewBillingInfos")
    const canChangeBillingInfos = useUserPermission("ChangeBillingInfos")

    const AddressText = useMemo(() => {
        let addresstext = "";
        if (data.City) {
            addresstext += data.City;
        }
        if (data.Country) {
            if (addresstext) {
                addresstext += ", ";
            }
            addresstext += data.Country;
        }
        return addresstext;
    }, [data.City, data.Country]);
    
    if (!canViewBillingInfos) {
        return <NotAllowedFeature />
    }

    if (extended) {
        return (
            <BillingInfoContainerExtended className="billinginfo-container">
                <NwIcon icon={light("file-invoice-dollar")} />
                <div className="billinginfo-container-details">
                    <p><strong>{data.CompanyName}</strong></p>
                    <p>Invoice Type: {data.InvoiceType}</p>
                    <p>{AddressText}</p>
                    {editable &&
                        <p>
                            <NwButton disabled={!canChangeBillingInfos} primary label={buttonLabel} onClick={onClick} />
                        </p>
                    }
                </div>
            </BillingInfoContainerExtended>
        )
    }
    return (
        <BillingInfoContainer className="billinginfo-container">
            <NwIcon icon={light("file-invoice-dollar")} />
            <div className="billinginfo-container-details">
                <p><strong>{data.CompanyName} - {data.InvoiceType}</strong></p>
                <p>{AddressText}</p>
            </div>
            {editable &&
                <div>
                    <p>
                        <NwLinkButton disabled={!canChangeBillingInfos} label={buttonLabel} onClick={onClick} />
                    </p>
                </div>
            }
        </BillingInfoContainer>
    );
};

export default BillingInfo;
