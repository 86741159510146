import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { getContactBillingInfo } from "Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils";
import ContactDropdown from "Components/ContactDropdown/ContactDropdown";
import NwIcon from "../NwIcon";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { NwLinkButton } from "../NwButton";
import { Loading } from "../Loading";

const BillingInfoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    margin-top: .5rem;

    &.selected {
        border: 1px solid red;
    }

    .billinginfo-container-details {
        flex: 1 1 0;
        padding-left: 1rem;
        p {
            strong {
                font-weight: 600;
            }
        }
    }

    p {
        font-size: .9rem;
        margin: 0;
        line-height: 1.15em;
    }
`


const ContactWithBillingInfoExtended = ({ value, onChange, contacts }) => {

    const [contactBillingInfos, setContactBillingInfos] = useState([])
    const [selectedContactID, setSelectedContactID] = useState(value ? value.ContactID : null)
    const [selectedBillingInfoID, setSelectedBillingInfoID] = useState();
    const [loadingBillingInfo, setLoadingBillingInfo] = useState(false)

    const getSelectedBillingInfo = () => {
        const selectedBillingInfo = contactBillingInfos.find(item => (item.ID === value.ContactBillingInfoID))
        return selectedBillingInfo
    }

    const getDefaultBillingInfo = () => {
        const defaultBillingInfo = contactBillingInfos.find(item => (item.isDefault))
        return defaultBillingInfo
    }

    useEffect(() => {
        const fetchBillingInfo = async (contactID) => {
            setLoadingBillingInfo(true)
            const fetchedBillingInfos = await getContactBillingInfo(contactID)
            setContactBillingInfos(fetchedBillingInfos)
            setLoadingBillingInfo(false)
        }
        if (selectedContactID) {
            // onChange(
            //     {
            //         ...value,
            //         ContactID: selectedContactID,
            //     }
            // )
            fetchBillingInfo(selectedContactID)
        }
    }, [selectedContactID])

    useEffect(() => {
        if (selectedBillingInfoID) {
            
        }
    }, [selectedBillingInfoID])

    useEffect(() => {
        if (contactBillingInfos && contactBillingInfos.length > 0) {
            if (value.ContactID === selectedContactID) {
                const selectedBillingInfo = getSelectedBillingInfo()
                setSelectedBillingInfoID(selectedBillingInfo.ID)
            } else {
                const defaultBillingInfo = getDefaultBillingInfo()
                setSelectedBillingInfoID(defaultBillingInfo.ID)
            }
        }
    }, [contactBillingInfos])



    const onChangeContact = (id) => {
        setSelectedContactID(id)
    }

    const handleSelectBillingInfo = (billingInfo) => {
        onChange(
            {
                ContactID: selectedContactID,
                ContactBillingInfoID: billingInfo.ID
            }
        )
    }

    const BillingInfoSelector = ({ data, editable, onClick, buttonLabel, selected }) => {
        return (
            <BillingInfoContainer className={`billinginfo-container${selected ? ' selected' : ''}`}>
                <NwIcon icon={light("file-invoice-dollar")} />
                <div className="billinginfo-container-details">
                    <p><strong>{data.CompanyName} - {data.InvoiceType}</strong></p>
                    <p>{data.City}, {data.Country}</p>
                </div>
                {editable &&
                    <div>
                        <p>
                            <NwLinkButton label={buttonLabel} onClick={onClick} />
                        </p>
                    </div>
                }
            </BillingInfoContainer>
        );
    };

    return (
        <div>
            <ContactDropdown
                value={value.ContactID}
                activeTypes={['cli', 'age', 'srv']}
                onChange={onChangeContact}
                topListContacts={contacts}
            />
            {loadingBillingInfo 
            ?
                <Loading />
            :
                (contactBillingInfos.map(billingInfo => (
                    <BillingInfoSelector 
                        selected={billingInfo.ID === selectedBillingInfoID}
                        key={billingInfo.ID}
                        data={billingInfo}
                        editable={true}
                        buttonLabel="select"
                        onClick={() => handleSelectBillingInfo(billingInfo)} 
                    />
                )))
            }
        </div>
    )
};

export default ContactWithBillingInfoExtended;
