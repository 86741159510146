import React, { useContext } from "react";
import { ListContext } from "Contexts/ListContext";
import useDepartmentName from "Hooks/UseDepartmentName";
import { getUserName } from "Libs/NwUsers";
import useNetworkAgencyName from "Hooks/UseNetworkAgencyName";

const AuthorAndDepartmentForEventCard = ({ object, textBefore }) => {
    const { cachedList, cachedListLoaded } = useContext(ListContext)
    const deptID = object.DepartmentID || object.DepartmentCreated || null
    const netAgID = object.NetworkAgencyID || object.NetworkAgencyCreated || null
    const deptname =  useDepartmentName(deptID, netAgID)
    const agname = useNetworkAgencyName(netAgID)
    

    return (
        cachedListLoaded 
        ?
            <><strong>{getUserName(object.UserCreated, cachedList.users.items)}</strong> @ {deptname.toUpperCase()} / {agname.toUpperCase()}</>
        :
            <></>
    )
};

export default AuthorAndDepartmentForEventCard;
