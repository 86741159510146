import React, { useState } from 'react';
import styled from 'styled-components';
import NwDrawer from 'Components/Gui/NwDrawer';
import Money from 'Components/Gui/Money';
import NwDivider from 'Components/Gui/NwDivider';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import TransactionAmountForm from './TransactionDetailForms/TransactionAmountForm';
import ExchangeRateForm from './TransactionDetailForms/ExchangeRateForm';
import NwLink from 'Components/Gui/NwLink';
import useConfigurations from 'Hooks/UseConfigurations';
import useTransactionLockedStatus from 'Hooks/UseTransactionLockedStatus';

const Container = styled.div`
    padding: .5rem 0;
    .transaction-amount-line {
        display: flex;
        justify-content: space-between;
        align-items: center;



        .transaction-amount {
            p {
                font-size: ${props => props.theme.FontSizeSm};
                margin-bottom: 0;
            }
        }
        .transaction-amount-actions {
            text-align: right;
        }
    }
`;

const TransactionAmount = ({ transaction, onUpdateTransaction, accountingUnlock }) => {
    const [editable, setEditable] = useState(false);
    const [touchForm, setTouchForm] = useState(false);

    const configurations = useConfigurations();

    //const isTransactionLocked = isLocked(transaction);

    const lock = useTransactionLockedStatus(transaction)
    const canEdit = !lock.isLocked || (lock.isAccountant && accountingUnlock)

    return (
        <Container>
            <NwDivider dark icon={light("sack-dollar")} textSize="medium">Amount</NwDivider>
            {transaction &&
                <div className='transaction-amount-line'>
                    <div className='transaction-amount'>
                        {(!['Flat','Units'].includes(transaction.TypeQuantity) || transaction.Quantity > 1) &&
                            <p><Money amount={transaction.SingleFee} currency={transaction.Currency} /> x {transaction.Quantity} {transaction.TypeQuantity}</p>
                        }
                        <h3><Money highlightCurrency redIfNegative amount={transaction.TotalFee} currency={transaction.Currency} /></h3>
                    </div>
                    <div className='transaction-amount-actions'>
                        <NwLink disabled={!canEdit} onClick={() => { setEditable(true) }} icon={light("sack-dollar")}>Change Amount</NwLink>
                        <br /><br />
                        {transaction.Currency !== configurations.defaultCurrency &&
                            <ExchangeRateForm disabled={!canEdit} transaction={transaction} />
                        }
                    </div>
                </div>
            }
            {editable &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    onClose={() => {
                        setEditable(false);
                        setTouchForm(false);
                    }}
                    title='Edit Amount'>
                    <TransactionAmountForm 
                        transaction={transaction} 
                        onSave={onUpdateTransaction} 
                        onCancel={() => setEditable(false)} 
                    />
                </NwDrawer>
            }
        </Container>
    );
};

export default TransactionAmount;