import React, { useContext, useState } from 'react';
import { Formik, Field } from "formik";
import { Row, Col } from 'antd';
import * as Yup from "yup";
import Axios from 'axios';
import { getMoment } from "Libs/NwMoment";

import NwDrawer from "Components/Gui/NwDrawer";
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { NWInput, NWTextAreaCharCounter, NWSelect, NWDatePicker, NWAmountInput, NWContactDropdown } from 'Components/Gui/NwForm/NwFormItems';
import { ListContext } from 'Contexts/ListContext';
import { datePickerBeforeOnChange, getNow, toISODate } from "Libs/NwMoment";
import useConfigurations from 'Hooks/UseConfigurations';
import { USAGE_AREAS } from 'Constants/UsageAreas';

const AddEditUsageDrawer = ({ projectId, plainMode, usage, usageTypes, onUpdate, onClose }) => {
    const [touchForm, setTouchForm] = useState(false);
    const { cachedList } = useContext(ListContext);

    const config = useConfigurations();
    const defaultCurrency = config.defaultCurrency;

    const saveUsage = async (values, setSubmitting) => {

        const data = {
            ...values,
            ProjectID: projectId
        };
        if (values.Fee && Array.isArray(values.Fee)) {
            if (values.Fee.length === 2) {
                data.Currency = values.Fee[0];
                if (values.Fee[1]) {
                    data.PrevisionalTotalFee = values.Fee[1];
                } else {
                    data.PrevisionalTotalFee = 0;
                }
            }
        } else {
            data.PrevisionalTotalFee = 0;
        }
        delete data.Fee;

        setSubmitting(true);
        if (!usage) {
            try {
                const newUsage = await Axios.post("/Usages", data);
                setTouchForm(false);
                onUpdate(newUsage);
                setSubmitting(false);
                onClose();
            } catch (error) {
                console.log('ant : Create Usage Error => ', error);
                setSubmitting(false);
            }
        } else {
            data.ID = usage.ID;
            try {
                await Axios.put("/Usages", data);
                setTouchForm(false);
                onUpdate();
                setSubmitting(false);
                onClose();
            } catch (error) {
                console.log('ant : Update Usag Error => ', error);
                setSubmitting(false);
            }
        }
    };

    const initialFee = usage ? (usage.PrevisionalTotalFee ? [usage.Currency, usage.PrevisionalTotalFee] : [defaultCurrency, 0]) : [defaultCurrency, 0];

    const initialValues = {
        UsageTypes: usage ? usage.UsageTypes.map(usageType => usageType.ID) : [],
        Duration: usage ? usage.Duration : '',
        Description: usage ? usage.Description : "",
        Areas: usage ? usage.Areas : [],
        StartDate: usage ? usage.StartDate : getNow(true),
        ExpirationDate: usage ? usage.ExpirationDate : getNow(true),
        ModelID: usage ? usage.ModelID : null,
        Fee: initialFee,
    };

    const disableCheckOutDate = beforeDate => date => {
        if (beforeDate && toISODate(beforeDate) > toISODate(date)) {
            return true;
        }
        return false;
    }



    const renderForm = () => {
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        saveUsage(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        UsageTypes: Yup.string().required("Type is required"),
                        Duration: Yup.string().required("Duration is required")
                    })}
                >
                    {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                        <NwForm
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit}
                            layout="vertical">
                            <Field
                                component={NWContactDropdown}
                                label="Model"
                                name="ModelID"
                                activeTypes={['mod']}
                                value={values.ModelID}
                            />
                            <Field
                                component={NWSelect}
                                label="Type"
                                mode='multiple'
                                name="UsageTypes"
                                placeholder='Select usage types'
                                value={values.UsageTypes}
                                filterOption={(input, option) => {
                                    if (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                        return true
                                    }
                                    return false
                                    //return option.props.value && option.props.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                options={
                                    usageTypes.filter(type => !type.Disabled).map(type => {
                                        return { key: type.ID, label: type.Name, value: type.ID };
                                    })}
                            />
                            <Field
                                component={NWSelect}
                                label='Areas'
                                name="Areas"
                                value={values.Areas}
                                placeholder="Select Areas"
                                filterOption={(input, option) => {
                                    if (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                        return true
                                    }
                                    if (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                        return true
                                    }
                                    return false
                                }}
                                mode='multiple'
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                options={[...USAGE_AREAS.map(area => ({ key: area, label: area, value: area })),
                                ...cachedList.countries.items.map(country => {
                                    return { key: country.Code, label: country.Name, value: country.Code };
                                })]}
                            />
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        component={NWDatePicker}
                                        label='StartDate'
                                        name="StartDate"
                                        format="MMM DD, YYYY"
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        style={{ width: "100%" }}
                                        value={values.StartDate ? getMoment(values.StartDate) : null}
                                        manipulateValue={datePickerBeforeOnChange}
                                        onAfterChange={date => setFieldValue('ExpirationDate', date)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        label='Expiration Date'
                                        component={NWDatePicker}
                                        name="ExpirationDate"
                                        format="MMM DD, YYYY"
                                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                                        style={{ width: "100%" }}
                                        value={values.ExpirationDate ? getMoment(values.ExpirationDate) : null}
                                        manipulateValue={datePickerBeforeOnChange}
                                        disabledDate={disableCheckOutDate(values.StartDate)}
                                    />
                                </Col>
                            </Row>
                            <Field
                                component={NWInput}
                                label="Duration"
                                name="Duration"
                                type="text"
                                value={values.Duration}
                                id='Duration'
                                maxLength={500}
                            />
                            <Field
                                component={NWAmountInput}
                                value={values.Fee}
                                //validate={validateAmount}
                                label="Previsional Fee"
                                name="Fee"
                            />
                            <Field
                                component={NWTextAreaCharCounter}
                                label="Description"
                                name="Description"
                                type="text"
                                value={values.Description}
                                maxLength={4000}
                                autoSize={{ minRows: 4, maxRows: 10 }}
                            />
                            <NwFormButtonsBar
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </>
        )
    }

    return (plainMode ? renderForm()
        : <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title={usage ? 'Edit Usage' : `New Usage`}
        >
            {renderForm()}
        </NwDrawer>
    )
};

export default AddEditUsageDrawer;