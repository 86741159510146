import React, { useContext } from 'react'
import { Layout } from 'antd';
import styled from 'styled-components';
import { UserContext } from "Contexts/UserContext";
import NwHeader from 'Components/_LayoutComponents/Header/NwHeader';

const { Content } = Layout;

const StyledContent = styled(Content)`
    margin-top: 60px;
    @media (max-width: 768px) {
        margin-top: 114px;
    }
    &.no-current-user {
        margin-top: 0;
    }
`;

const NwLayout = ({children}) => {
    const { currentUser } = useContext(UserContext);
    const contentClassName = currentUser ? '' : 'no-current-user';
    return (
        <Layout className="nw-page">
            {currentUser && <NwHeader />}                        
            <StyledContent className={contentClassName}>
                {children}
            </StyledContent>
        </Layout>
    )
}

export default NwLayout

