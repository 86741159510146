import { TRAVEL_PLANS_FILTERS_SETS } from "Configs/Filters/TRAVEL_PLANS_FILTERS_SETS";
import { PACKAGES_FILTERS_SET } from "Configs/Filters/PACKAGES_FILTERS_SETS";
import { BILLING_PROJECTS_FILTERS_SETS } from "Configs/Filters/BILLING_PROJECTS_FILTERS_SETS";
import { EVENTS_FILTERS_SETS } from "Configs/Filters/EVENTS_FILTERS_SETS";
import { STATEMENT_FILTERS_SETS } from "Configs/Filters/STATEMENTS_FILTERS_SETS";
import { TASKS_FILTERS_SETS } from "Configs/Filters/TASKS_FILTERS_SETS";
import { INVOICES_FILTERS_SETS } from "Configs/Filters/INVOICES_FILTERS_SETS";
import { PAYMENTS_FILTERS_SETS } from "Configs/Filters/PAYMENTS_FILTERS_SETS";
import ls from "local-storage";

export const AREA_DEFAULT = {
    area: 'booking'
};

const NavigationContext = {
    billingProjectsFiltersContext: ls.get('nw_config_jobwallfilter') || { ...BILLING_PROJECTS_FILTERS_SETS },
    invoicesFiltersContext: ls.get('nw_config_invoicewallfilter') || { ...INVOICES_FILTERS_SETS },
    paymentsFiltersContext: ls.get('nw_config_paymentwallfilter') || { ...PAYMENTS_FILTERS_SETS },
    eventFiltersContext: ls.get('nw_config_eventwallfilter') || { ...EVENTS_FILTERS_SETS },
    travelPlanFiltersContext: ls.get('nw_config_travelplanwallfilter') || { ...TRAVEL_PLANS_FILTERS_SETS },
    statementFiltersContext: ls.get('nw_config_statementwallfilter') || { ...STATEMENT_FILTERS_SETS },
    taskFiltersContext: ls.get('nw_config_taskwallfilter') || { ...TASKS_FILTERS_SETS },
    packageFiltersContext: ls.get('nw_config_packagewallfilter') || { ...PACKAGES_FILTERS_SET },
    areaContext: ls.get('nw_config_area') || { ...AREA_DEFAULT },

    loadingStatusContext: {
        loaded: false,
    },
}

export default NavigationContext
