import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NwDrawer from 'Components/Gui/NwDrawer';
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import EventInfoEditForm from 'Components/EventDrawer/BookingEvent/EventInfoEditForm';
import EditModelDatesForm from 'Components/EventDrawer/BookingEvent/EditForms/EditModelDatesForm';
import BookingEventCustomFields from 'Components/EventDrawer/BookingEvent/BookingEventCustomFields';
import AddEventDate from 'Components/EventDrawer/BookingEvent/AddEventDate/AddEventDate';
import EditEventDayForm from 'Components/EventDrawer/BookingEvent/EditForms/EditEventDayForm';
import { forceReloadEvent } from 'Hooks/Event/UseEvent';
import { editDrawerTypes, getEditDrawerTitle } from './bookingEventDetailsUtils'
import { forceReloadProjectTimeline } from 'Hooks/Project/UseProjectTimeline';


const BookingEventDrawers = ({ eventData, afterUpdate, editDrawerType, onCloseDrawers, customFields, selectedWorkingDate, selectedWorkingDay }) => {
  const [touchForm, setTouchForm] = useState(false);

  const handleUpdateEvent = async () => {
    onCloseDrawers();
    await forceReloadEvent(eventData.ID);
    await forceReloadProjectTimeline(eventData.Project.ID);
    await afterUpdate();
  };

  return (
    <NwDrawer
        width='45rem'
        minWidth='45rem' 
        touchForm={touchForm}
        onTouchForm={setTouchForm}
        title={getEditDrawerTitle(editDrawerType)} 
        onClose={onCloseDrawers}
        className="event-edit-drawers"
    >
      {
        editDrawerType === editDrawerTypes.editGeneralDetails && (
          <EventInfoEditForm 
            event={eventData} 
            customFields={customFields}
            onCancel={onCloseDrawers} 
            onTouchForm={setTouchForm}
            onUpdate={handleUpdateEvent} />
        )
      }
      {
        editDrawerType === editDrawerTypes.editCustomFields && (
          <BookingEventCustomFields 
            event={eventData} 
            customFields={customFields} 
            onTouchForm={setTouchForm}
            onClose={onCloseDrawers} />
        )
      }
      {
        editDrawerType === editDrawerTypes.addDates && (
          <AddEventDate 
            event={eventData} 
            onSave={handleUpdateEvent} 
            onCancel={onCloseDrawers}
            onTouchForm={setTouchForm} />
        )
      }
      {editDrawerType === editDrawerTypes.history && (
        <ObjectLogHistories objectId={eventData.ID} type="Event" />
      )}
      {editDrawerType === editDrawerTypes.editDates && selectedWorkingDate && (
        <EditModelDatesForm 
            date={selectedWorkingDate} 
            eventData={eventData} 
            onSave={handleUpdateEvent} 
            onClose={onCloseDrawers}
            onTouchForm={setTouchForm} />
      )}
      {editDrawerType === editDrawerTypes.editWorkingDay && selectedWorkingDay && (
        <EditEventDayForm 
            date={selectedWorkingDay} 
            eventData={eventData} 
            onSave={handleUpdateEvent} 
            onClose={onCloseDrawers}
            onTouchForm={setTouchForm} />
      )}
    </NwDrawer>
  )
}

// BookingEventDrawers.propTypes = {
//   eventData: PropTypes.shape({
//     ID: PropTypes.number.isRequired,
//   }),
//   afterUpdate: PropTypes.func.isRequired,
//   onCloseDrawers: PropTypes.func.isRequired,
//   selectedWorkingDate: PropTypes.shape({
//     ID: PropTypes.number
//   }),
//   selectedWorkingDay: PropTypes.shape({
//     ID: PropTypes.number
//   }),
//   editDrawerType: PropTypes.oneOfType(Object.keys(editDrawerTypes)),
// }

// BookingEventDrawers.defaultProps = {
//   editDrawerType: null,
//   selectedWorkingDate: null,
// }

export default BookingEventDrawers;