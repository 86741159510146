import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import { List } from 'antd';
import { light, solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import { NwAddButton } from 'Components/Gui/NwButton';
import { NwCard } from "Components/Gui/NwCard/NwCard";
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import EmptyState from 'Components/Gui/EmptyState';
import AddEditUsageDrawer from 'Components/Project/ProjectTransactions/Usages/AddEditUsageDrawer';
import { forceReloadProject, useProject } from 'Hooks/Project/UseProject';
import { ListContext } from 'Contexts/ListContext';
import Axios from 'axios';
import { showPeriodByDay } from 'Libs/NwMoment';
import NwIcon from 'Components/Gui/NwIcon';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import Money from 'Components/Gui/Money';
// import TransactionCreateDrawer from 'Components/Transactions/TransactionsDrawer/TransactionCreateDrawer';

const NwStyledCard = styled(NwCard)`
    .ant-list {
        margin-right: 1rem;
    }
    .ant-list-item {
        align-items: flex-start;
    }
    
    .usages-block {
        width: 100%;
        padding-left: 1.5rem;

        h2 {
            font-size: ${props => props.theme.FontSizeBase};
            font-weight: 600;
        }

        .usages-type, .usages-area, .usages-period, .usages-duration, .usages-fee {
            margin: .25rem 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon-wrapper {
                margin-right: .5rem;
                width: 2rem;
                text-align: center;
                font-size: 1.1rem;
            }

            .usages-area-names {
                font-weight: 600;
                line-height: 1.1em;
            }
        }
        p {
            margin: .5rem;
            font-size: ${props => props.theme.FontSizeSm};
            font-weight: 400;

            /* &.usages-description {
                white-space: pre-wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            } */
        }
    }
`;

const ProjectUsagesCard = ({ readOnly, projectId }) => {
    const { data: currentProject } = useProject(projectId);
    const [showNewUsage, setShowNewUsage] = useState(false);
    const { cachedList, GetCountry } = useContext(ListContext);
    const [usage, setUsage] = useState(null);
    // const [showAddTransaction, setShowAddTransaction] = useState(null);


    const getCountryName = code => {
        const country = GetCountry(code);
        return country ? country.Name : code;
    }

    const RenderAddButton = () => {
        return (
            <NwAddButton
                ghost
                size='small'
                label='create usage'
                onClick={() => {
                    setUsage(null);
                    setShowNewUsage(true)
                }}
            />
        );
    };

    const deleteUsage = async (usage) => {
        try {
            await Axios.delete(`/Usages/${usage.ID}`);
            await forceReloadProject(projectId);
        } catch (e) {

        }
    }

    const actions = (usage) => {
        return [
            <NwDropDownMenu
                size='small'
                actions={[
                    {
                        name: "", key: "edit", pinned: true, icon: light("pen"), action: () => {
                            setUsage({
                                ...usage,
                                UsageTypes: cachedList.usageTypes.items.filter(item => usage.UsageTypes.find(el => item.Name === el))
                            });
                            setShowNewUsage(true);
                        }
                    },
                    { name: "delete", type: "delete", key: "delete", icon: light("trash-alt"), confirmationPlacement: "left", action: (() => deleteUsage(usage)) }
                ]}
            />
        ];
    }

    const getAreasAsString = (areas) => {
        return areas.map(area => getCountryName(area)).join(', ');
    }

    return (
        currentProject
            ?
            <NwStyledCard
                title='Usages'
                extra={!readOnly && RenderAddButton()}
                icon={light("tv-retro")}
            >
                {currentProject.Usages.length > 0 ?
                    <List
                        itemLayout="horizontal"
                        dataSource={currentProject.Usages}
                        renderItem={(usage, index) => (
                            <List.Item actions={!readOnly && actions(usage)} className="ant-list-item-top">
                                <div className="usages-block">
                                    {usage.ModelID &&
                                        <h2>{getContactName(usage.ModelID)}</h2>
                                    }
                                    <div className="usages-type">
                                        <div className="icon-wrapper">
                                            <NwIcon icon={regular("broadcast-tower")} />
                                        </div>
                                        {usage.UsageTypes.map((type, index) =>
                                            <Tag key={index} color={StyleVariables.PrimaryColor}>{type}</Tag>
                                        )}
                                    </div>
                                    <div className="usages-area">
                                        <div className="icon-wrapper">
                                            <NwIcon icon={regular("globe")} />
                                        </div>
                                        <div className="usages-area-names">{getAreasAsString(usage.Areas)}</div>
                                    </div>
                                    <div className="usages-duration">
                                        <div className="icon-wrapper">
                                            <NwIcon icon={regular("hourglass-start")} />
                                        </div>
                                        <div>{usage.Duration}</div>
                                    </div>
                                    <div className="usages-period">
                                        <div className="icon-wrapper">
                                            <NwIcon icon={regular("calendar")} />
                                        </div>
                                        <div>{showPeriodByDay(usage.StartDate, usage.ExpirationDate)}</div>
                                    </div>
                                    <div className="usages-fee">
                                        <div className="icon-wrapper">
                                            <NwIcon icon={regular("comment-dollar")} />
                                        </div>
                                        <div>
                                            {usage.PrevisionalTotalFee
                                                ?
                                                <Money amount={usage.PrevisionalTotalFee} currency={usage.Currency} />
                                                :
                                                <strong>no previsional fees</strong>
                                            }
                                        </div>
                                    </div>
                                    {/* ellipsis on description */}
                                    <p className="usages-description">{usage.Description}</p>
                                </div>
                                {/* <div className="usages-actions">
                                    <NwAddButton
                                        primary
                                        label='add fee'
                                        onClick={() => setShowAddTransaction(usage.ID)}
                                    />
                                </div> */}
                            </List.Item>
                        )}
                    />
                : <EmptyState
                    message="No usages" />
                }
                {showNewUsage &&
                    <AddEditUsageDrawer
                        projectId={projectId}
                        usageTypes={cachedList.usageTypes.items}
                        usage={usage}
                        onUpdate={async () => {
                            await forceReloadProject(projectId);
                        }}
                        onClose={() => setShowNewUsage(false)} />
                }
                {/* TODO: Add proper jobfee drawer  and check if this is needed*/}
                {/* {showAddTransaction &&
                    <TransactionCreateDrawer
                        plainMode
                        modelSelectionEnabled
                        projectId={projectId}
                        usageId={showAddTransaction}
                        transactionFamily={'JobFee'}
                        onClose={() => setShowAddTransaction(null)}
                        onTransactionCreatedOrUpdated={
                            async () => {
                                await forceReloadProject(projectId);
                                setShowAddTransaction(null);
                            }
                        }
                    />
                } */}
            </NwStyledCard>
            :
            <div></div>
    );
}

export default ProjectUsagesCard;