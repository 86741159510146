import React, { useState } from 'react';
import { NwAddButton } from 'Components/Gui/NwButton';
import { EventActionsToolbar } from 'Components/EventDrawer/EventDrawerUi/EventUI';
import { forceReloadTravel } from 'Hooks/Event/UseTravel';
import NwUpload from "Components/Gui/NwUpload";
import AddNonBookingEventTransactionDrawer from 'Components/Transactions/TransactionsDrawer/AddNonBookingEventTransactionDrawer';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { checkTenant } from 'Libs/NwUtils';
import { useUserPermission } from 'Contexts/UserContext';
const TravelActions = ({ plainMode, travelsData, onAddModels }) => {

    const [transactionDrawer, setTransactionDrawer] = useState(false);

    const accessAccounting = useUserPermission('AccessAccounting');

    const getUploadEndpoint = () => {
        let endpoint = `documents/upload/1?eventGroupGuid=${travelsData.GroupGuid}`;
        if (travelsData.Project) {
            endpoint = endpoint + `&projectID=${travelsData.Project.ID}`
        }
        return endpoint;
    };

    const onAfterAddingExpense = () => {
        forceReloadTravel(travelsData.ID);
        setTransactionDrawer(false)
    }

    const isEliteBooker = checkTenant('elitemodels', true) && !accessAccounting

    return (
        <>
            <EventActionsToolbar plainMode={plainMode}>
                {travelsData.GroupGuid &&
                    <NwUpload
                        size='small'
                        label='add document'
                        maxSize={20}
                        showList={false}
                        types={["all"]}
                        onUploadEnd={() => {
                            forceReloadTravel(travelsData.ID);
                        }}
                        endpoint={getUploadEndpoint()}
                    />
                }
                <NwAddButton 
                    size="small" 
                    ghost 
                    onClick={onAddModels} 
                    label="add models" 
                />
                {(!travelsData.ProjectID && !isEliteBooker) &&
                    <NwAddButton
                        ghost
                        onClick={() => { setTransactionDrawer(true) }}
                        label="add expense"
                        icon={solid("comment-plus")}
                    />
                }
            </EventActionsToolbar>
            {transactionDrawer &&
                <AddNonBookingEventTransactionDrawer
                    event={travelsData}
                    eventType="travel"
                    onClose={() => setTransactionDrawer(false)}
                    onSave={onAfterAddingExpense}
                />
            }
        </>
    )
};

export default TravelActions;