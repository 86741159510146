import React, { useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwSaveButton, NwCancelButton } from "Components/Gui/NwButton";
import { NWInput, NWRadioButtonGroup } from "Components/Gui/NwForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NwForm/NwFormWrapper';
import { ListContext } from "Contexts/ListContext";
import {useEditContactConnectionItem, useAddContactConnectionItem} from 'Hooks/Contact/UseContactConnections';

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PhoneForm = ({ phone, connectionID, onCancel, onSave, contactID, onTouchForm }) => {
  
    const { mutateAsync: editPhone } = useEditContactConnectionItem() 
    const { mutateAsync: addPhone } = useAddContactConnectionItem()

    const enumerators = [...useContext(ListContext).cachedList.enumerators.items];
    const phoneTypes = enumerators.find(el => {
        return el.Name === "PhoneTypeEnum";
    }).Values;
    
    const initialValues = {
        Type: "Land",
        Number: "",
        Note: ""
    };

    if (phone) {
        initialValues.ID = phone.ID;
        initialValues.Type = phone.Type;
        initialValues.Number = phone.Number;
        initialValues.Note = phone.Note;
    }

    const getTypeIcon = type => {
        switch(type) {
            case 'Land':
                return light('phone-office');
            case 'Mobile':
                return solid('mobile-alt');
            case 'Fax':
                return light('fax');
            case 'Other':
                return light('phone');
            default:
                break;
        }
    }

    const savePhone = async (values, setSubmitting) => {
        const ID = initialValues.ID;
        const data = {
            ConnectionID: connectionID,
            Number: values.Number,
            Note: values.Note,
            Type: values.Type
        };

        if (ID) {
            data.ID = ID
            await editPhone({itemType:"phones", data: data, contactID: contactID})
            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false)
            onSave()
        } else {
            const phone = await addPhone({itemType:"phones", data: data, contactID: contactID})
            if (onTouchForm) {
                onTouchForm(false);
            }
            setSubmitting(false)
            onSave(phone);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
            Type: Yup.string().required("Content is required"),
            Number: Yup.string()
                .required("Content is required")
                //.matches(phoneRegExp, "Phone number is not valid")
            })}
            onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            savePhone(values, setSubmitting);
            }}
        >
            {({ isSubmitting, values, handleSubmit }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    noValidate 
                    onFinish={handleSubmit} 
                    layout="vertical">
                    {!phone && 
                        <Field
                            component={NWRadioButtonGroup}
                            name="Type"
                            value={values.Type}
                            buttonStyle="solid"
                            options={phoneTypes.map(option => {
                                    return { key: option.Name, value: option.Name, label: option.Name, icon: getTypeIcon(option.Name) };
                                })
                            }
                        />
                    }
                    <Field
                        component={NWInput}
                        label="Number"
                        name="Number"
                        type="text"
                        value={values.Number}
                    />
                    <Field
                        component={NWInput}
                        label="Note"
                        name="Note"
                        type="text"
                        value={values.Note}
                    />
                    <NwFormButtonsBar
                        left={
                            <NwCancelButton
                                disabled={isSubmitting}
                                onClick={() => {
                                    if (onTouchForm) {
                                        onTouchForm(false);
                                    }
                                    onCancel();
                                }}
                            />
                        }
                        right={
                            <NwSaveButton
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                loading={isSubmitting}
                            />
                        }
                    />
                </NwForm>
            )}
        </Formik>
    );
};

export default PhoneForm;
