import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';


const emptyModelContextValue = {
    activeSet: null,
    backToModelPage: () => { },
    selectedModel: null,
    listType: null,
    onSelectModel: () => { },
    onSelectListType: () => { },
    onSelectActiveSet: () => { }
}

export const ModelContext = createContext(emptyModelContextValue);

export const ModelContextProvider = ({ listTypeSelected, activeSetSelected, modelSelected, children })  => {

    const navigate = useNavigate();
    const [listType, setListType] = useState(listTypeSelected || 'all-models');
    const [activeSet, setActiveSet] = useState(activeSetSelected || 'all');
    const [selectedModel, setSelectedModel] = useState(modelSelected);

    useEffect(() => {
        setSelectedModel(modelSelected);
    }, [modelSelected]);

    useEffect(() => {
        setListType(listTypeSelected);
        setActiveSet(activeSetSelected);
    }, [listTypeSelected, activeSetSelected]);

    const backToModelPage = useCallback(() => {
        if (activeSet && listType) {
            navigate(`/model/${selectedModel}/${listType}/${activeSet}`)
        } else {
            navigate(`/model/${selectedModel}`)
        }
    }, [activeSet, listType, selectedModel, navigate])

    const onSelectModel = useCallback((modelId) => {
        setSelectedModel(modelId);
    }, []);

    const onSelectListType = useCallback((type) => {
        setListType(type);
    }, []);

    const onSelectActiveSet = useCallback((set) => {
        setActiveSet(set);
    }, []);

    const ModelContextValue = useMemo(() => ({
        activeSet,
        backToModelPage,
        selectedModel,
        listType,
        onSelectModel,
        onSelectListType,
        onSelectActiveSet
    }), [
        activeSet,
        backToModelPage,
        selectedModel,
        listType,
        onSelectModel,
        onSelectListType,
        onSelectActiveSet
    ]);

    return (
        <ModelContext.Provider value={ModelContextValue}>
            {children}
        </ModelContext.Provider>
    );
}

export function useModelContext() {
    return useContext(ModelContext);
}