import React, { useState, useMemo } from "react";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import { useStateValue } from "Contexts/StateProvider";
import { NwButton, NwRemoveButton, NwEditButton } from "Components/Gui/NwButton";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import NwDrawer from "Components/Gui/NwDrawer";
import NwDivider from "Components/Gui/NwDivider";
import ContactsFilterDrawer from "Components/ContactsSelector/Common/ContactsFilterDrawer";
import ContactsFiltersSections from "./ContactsFiltersSections";
import ContactsSelectedFilters from "./ContactsSelectedFilters";
import ContactsFilterSetNameDrawer from "./ContactsFilterSetNameDrawer";
import styled from "styled-components";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import _ from "lodash";


const Wrapper = styled.div`
    width: 100%;
    display: block;
    
    div.filter-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding: .25rem 0;

        button {
            font-size: 0.8em;
            font-weight: bold;
        }
    }
`


const ContactsFiltersSmartListsForm = ({
    navigation,
    //onSave
}) => {


    const [{ areaContext }] = useStateValue();
    const breakpoint = useNwBreakPoints();

    const [openAddDrawer, setOpenAddDrawer] = useState(false);
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { global, selection, layout, context } = useContactsSelector();
    const { filtersSections, sets } = global;
    const {
        listType,
        activeSet,
        activeSetFixed,
        activeSetName,
        filters,
    } = selection;
    const { currentPackage, currentEvent } = context;
    const cs_dispatch = useContactsSelectorDispatch();

    const workingSetUnsaved = useMemo(() => {
        if (listType === 'smart-lists') {
            const activeSetObj = sets.find(set => set.id === activeSet);
            return activeSetObj && activeSetObj.unsaved;
        }
        return false;
    }, [activeSet, listType, sets]);

    const sectionName = useMemo(() => {
        if (filters.openSection) {
            const section = filtersSections.find(section => section.areaID === filters.openSection)
            if (section) {
                return section.areaName
            }
        }
        return ''
    }, [filters.openSection])


    const closeFilterSection = () => {
        cs_dispatch({ type: 'CloseFiltersSection' })
    }

    const revertChanges = () => {
        cs_dispatch({ type: 'RevertChanges' })
    }


    const onUpdateSetFilters = () => {
        setIsSaving(true);
        cs_dispatch({
            type: "SaveSetFilters",
        })
        setIsSaving(false)
        handleCloseEditDrawer()
        //onSave()
    };

    const onDeleteSet = () => {
        cs_dispatch({
            type: "DeleteSet",
            area: areaContext.area,
            setID: activeSet
        })
        handleCloseAddDrawer()
    };

    const handleOpenAddListDrawer = () => {
        setOpenAddDrawer(true)
    };

    const handleCloseAddDrawer = () => {
        setOpenAddDrawer(false)
    }

    const handleOpenEditListDrawer = () => {
        setOpenEditDrawer(true)
    };

    const handleCloseEditDrawer = () => {
        setOpenEditDrawer(false)
    }

    const drawerOnDrawer = currentPackage || currentEvent

    let fromLeft = 0
    if (!drawerOnDrawer) {
        if (breakpoint < 4) {
            fromLeft = 200
        } else {
            fromLeft = 300
        }
        if (layout.leftMargin) {
            fromLeft += layout.leftMargin
        }
    }
    let placement = drawerOnDrawer ? 'right' : 'left'
    let zIndex = drawerOnDrawer ? 1041 : 5
    let fromTop = drawerOnDrawer ? 0 : (navigation ? 110 : 60)

    let fromTop2 = drawerOnDrawer ? 0 : (navigation ? 95 : 60)

    return (
        <Wrapper>
            <NwDivider>active filters</NwDivider>
            <ContactsSelectedFilters />
            {activeSetName && (!activeSetFixed || workingSetUnsaved) &&
                <>
                    <NwDivider lessMargin>actions on current search</NwDivider>
                    <div className="filter-actions">
                        {workingSetUnsaved
                            ?
                            <>
                                <NwButton
                                    size='small'
                                    ghost
                                    icon={solid('undo')}
                                    label='reset'
                                    onClick={revertChanges}
                                />
                                <NwDividerVertical />
                                {!activeSetFixed &&
                                    <>
                                        <NwButton
                                            size='small'
                                            ghost
                                            icon={solid('save')}
                                            onClick={onUpdateSetFilters}
                                            label="save"
                                            loadingLabel="saving..."
                                        />
                                        <NwDividerVertical />
                                    </>
                                }
                                <NwButton
                                    size='small'
                                    icon={light('floppy-disk-circle-arrow-right')}
                                    label="save as..."
                                    ghost
                                    onClick={handleOpenAddListDrawer}
                                />
                            </>
                            :
                            (!activeSetFixed &&
                                <>
                                    <NwEditButton
                                        size='small'
                                        ghost
                                        onClick={handleOpenEditListDrawer}
                                        label="rename"
                                        loadingLabel="saving..."
                                    />
                                    <NwDividerVertical />
                                    <NwRemoveButton
                                        size='small'
                                        ghost
                                        onClick={onDeleteSet}
                                        label="delete"
                                        loadingLabel="deleting..."
                                    />
                                </>
                            )
                        }
                    </div>
                </>
            }
            <ContactsFiltersSections />
            {filters.openSection &&
                <NwDrawer
                    fromLeft={fromLeft}
                    fromTop={fromTop}
                    zIndex={zIndex}
                    placement={placement}
                    className="max-width-800"
                    width="calc(100vw - 340px)"
                    onClose={closeFilterSection}
                    title={sectionName}
                >
                    <ContactsFilterDrawer />
                </NwDrawer>
            }
            {openAddDrawer &&
                <ContactsFilterSetNameDrawer
                    fromLeft={fromLeft}
                    fromTop={fromTop2}
                    zIndex={zIndex}
                    placement={placement}
                    onClose={handleCloseAddDrawer}
                    isSaving={isSaving}
                    action="AddNew"
                />
            }
            {openEditDrawer &&
                <ContactsFilterSetNameDrawer
                    fromLeft={fromLeft}
                    fromTop={fromTop2}
                    zIndex={zIndex}
                    placement={placement}
                    onClose={handleCloseEditDrawer}
                    isSaving={isSaving}
                    action="Rename"
                />
            }
        </Wrapper>
    );
};

export default ContactsFiltersSmartListsForm;
