import React, { useState, useMemo, useContext } from "react";
import styled from 'styled-components';
import { Segmented } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import StyleVariables from 'Components/Gui/StyleVariables.js';
import TagManager from "Components/TagManager/TagManager";
import AddToFolderButton from "Components/ContactsFolders/AddToFolderButton";
import { NwButton } from "Components/Gui/NwButton";
import NwDrawer from 'Components/Gui/NwDrawer';
import ContactUserForm from "Components/ContactUsersDrawer/ContactUserForm";
import { forceReloadModels } from "Hooks/Contact/UseContactsList";
import ContactsExporter from 'Components/ContactsExporter/ContactsExporter';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { ListContext } from 'Contexts/ListContext';
import { useUserPermission } from 'Contexts/UserContext';
import { getAge, showShortDate } from 'Libs/NwMoment';
import { sortTags, mapTags } from 'Libs/NwUtils';
import PermissionBlock from "Components/PermissionBlock/PermissionBlock";
import MiniLoading from "Components/Gui/MiniLoading";
import { queryClient } from "QueryClientProvider";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import { useUserGuiConfig } from "Contexts/UserGuiConfigContext";

const FixedFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: ${props => props.$isDrawer ? "" : (props.$leftMargin ? props.$leftMargin : props.$size === 'small' ? "200px" : "300px")};
    background-color: #fff;
    padding: 16px 24px;
    box-sizing: border-box;
    z-index: 4;
    width: ${props => props.$isDrawer ? "" : (props.$leftMargin ? `calc(100vw - ${props.$leftMargin}px)` : props.size === 'small' ? 'calc(100vw - 200px)' : 'calc(100vw - 300px)')};
    border: ${StyleVariables.DefaultBorder};
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    flex-wrap: wrap;
    text-transform: uppercase;
    align-items: center;
`;

const FooterItems = styled.div`
    display: flex;
    align-items: center;
`;

const SelectContainer = styled.div`
    margin-left: 16px;
`;

const FolderContainer = styled.div`
    font-weight: normal;
    text-transform: initial;
    margin-left: 8px;
`;

const TagsPopoverHeader = styled.div`
    text-align: center;

    .ant-segmented {
        margin: .5rem 0;
    }
`;

const getFeatures = (FeatNwMain) => {
    if (FeatNwMain) {
        const features = FeatNwMain ? FeatNwMain : [];
        // Filter and order Main Features depending on User Metrics Config
        let filteredFeatures = [];
        for (const item of features) {
            for (const feature of item.Features) {
                filteredFeatures.push(feature);
            }
        }
        return filteredFeatures;
    }
    return []
}

export const ModelsWallFooter = ({
    models,
    onAddTag,
    onRemoveTag,
    isLoading
}) => {
    const breakpoint = useNwBreakPoints();

    const { actions, additional, layout } = useContactsSelector();
    const { selectedItemsIDs, multipleSelectionActive } = actions;
    const { disabledItems, } = additional;

    const cs_dispatch = useContactsSelectorDispatch();

    const userGuiConfig = useUserGuiConfig()
    const [showContactUsers, setShowContactUsers] = useState(false);
    const [tagMode, setTagMode] = useState('Add');
    const [showDownloadDrawer, setShowDownloadDrawer] = useState(false);
    const [showTagsDrawer, setShowTagsDrawer] = useState(false);
    const [touchForm, setTouchForm] = useState(false);
    const tagsOnContacts = useUserPermission('TagsOnContacts', []);

    const ExportModelsList = useUserPermission("ExportModelsList")
    

    const { cachedList } = useContext(ListContext)
    const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
    const allTags = useMemo(() => sortTags(mapTags(cachedtags, 'Model')), [cachedtags])

    const { removableTags, addibleTags } = useMemo(() => {
        const models = queryClient.getQueryData(['models']);
        const selectedModels = models.filter(model => selectedItemsIDs.includes(model.ID));

        const inAllModels = (tag) => {
            return selectedModels.every(model => model.T.some(modelTag => modelTag.Name.toLowerCase() === tag.Name.toLowerCase()));
        }

        const tagNames = selectedModels.map(model => model.T.map(tag => tag.Name.toLowerCase())).flat();
        const removableTags = allTags.filter(tag => tagNames.includes(tag.Name.toLowerCase()));
        const addibleTags = allTags.filter(tag => !inAllModels(tag));
        return { removableTags, addibleTags }
    }, [models, allTags, selectedItemsIDs]);

    const handleSelectAll = () => {
        let newmodels = disabledItems ? models.filter(model => !disabledItems.find(item => item === model.ID)) : models
        const newSelectedList = newmodels.map(model => model.ID);
        cs_dispatch({
            type: "UpdateSelectedItems",
            newlist: [...newSelectedList]
        })
    }

    const handleAddUsers = () => {
        setShowContactUsers(true);
    };

    const handleCloseContacUsersDrawer = () => {
        setShowContactUsers(false)
    };

    const handleUpdateContactUsers = () => {
        forceReloadModels()
        setShowContactUsers(false)
    };

    const renderTagHeader = () => {
        return (
            <TagsPopoverHeader>
                <Segmented
                    value={tagMode}
                    onChange={setTagMode}
                    options={[
                        { label: 'Add Tags', value: 'Add' },
                        { label: 'Remove Tags', value: 'Remove' }
                    ]}

                />
            </TagsPopoverHeader>
        )
    };

    const handleOpenDownloadDrawer = () => {
        setShowDownloadDrawer(true);
    };

    const handleCloseDownloadDrawer = () => {
        setShowDownloadDrawer(false);
    };

    const onClearSelection = () => {
        cs_dispatch({
            type: "ClearSelectedItems"
        })
    };

    const handleFolderCreated = (folderid, foldername) => {
        cs_dispatch({
            type: "SelectFolder",
            folderID: folderid.toString(),
            folderName: foldername
        })
    };
    
    const getModelFeatures = features => {
        const filteredFeatures = getFeatures(features ? JSON.parse(features) : null);

        if (filteredFeatures.length) {
            return (
                filteredFeatures.map(({ DisplayName, DisplayValues, FeatureID, ConversionName }, index) => {
                    if (ConversionName) {
                        const conversion = ConversionName && userGuiConfig.metrics.find(metric => metric.name === ConversionName);
                        const displayMetric = conversion && conversion.metrics[0];
                        const displayValue = DisplayValues.find(displayValue => displayValue.metric === displayMetric);
                        return `${DisplayName} ${displayValue ? displayValue.value : DisplayValues[0].value}`;
                    }
                    return `${DisplayName} ${DisplayValues[0].value}`;
                })
            );
        }
        return [];
    };

    const selectedModelsData = useMemo(() => {
        const selectedModels = selectedItemsIDs.map(modelId => {
            const model = models.find(model => model.ID === modelId);
            if (model) {
                return {
                    ...model,
                    id: model.ID,
                    email: model.E,
                    name: model.N.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                    surname: model.S.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
                    age: getAge(model.B),
                    features: getModelFeatures(model.F).join(' • '),
                    tags: model.T.map(tag => tag.Name).join(', '),
                    land: model.P,
                    mobile: model.M,
                    motherAgency: model.MAN,
                    birthdate: model.B ? showShortDate(model.B) : null,
                    nationality: model.NAT
                }
            }
            return null;
        });
        return selectedModels;
    }, [models, selectedItemsIDs]);

    return (
        <FixedFooter $size={breakpoint < 4 ? 'small' : 'medium'} $leftMargin={layout.leftMargin} $isDrawer={layout.drawer}>
            <FooterItems>
                <div>{selectedItemsIDs.length} Model{selectedItemsIDs.length > 1 && "s"} selected</div>&nbsp;
                {multipleSelectionActive &&
                    <SelectContainer>
                        <NwButton ghost icon={light('check-double')} label='select all' onClick={handleSelectAll} />
                        &nbsp;
                        <NwButton ghost icon={light('times')} label='clear selection' onClick={onClearSelection} />
                    </SelectContainer>
                }
            </FooterItems>
            <FooterItems>
                <NwButton
                    disabled={selectedItemsIDs.length === 0}
                    ghost
                    label='export'
                    icon={light('file-spreadsheet')}
                    onClick={handleOpenDownloadDrawer} />
                &nbsp;
                {selectedItemsIDs.length === 0 ?
                    <NwButton ghost disabled={selectedItemsIDs.length === 0 || !ExportModelsList} icon={light('tag')} label={'tags'} />
                    :
                    tagsOnContacts ?

                        <div onClick={() => setShowTagsDrawer(true)}>
                            <NwButton ghost icon={light('tag')} label={'tags'} />
                        </div>
                        :
                        <PermissionBlock
                            permission={false}
                            content={
                                <NwButton ghost icon={light('tag')} label={'tags'} />
                            }
                        />
                }
                &nbsp;
                <NwButton disabled={selectedItemsIDs.length === 0} ghost icon={light('user-circle')} label={'Booker'} onClick={handleAddUsers} />
                &nbsp;
                <FolderContainer>
                    <AddToFolderButton
                        ids={selectedItemsIDs}
                        type='model'
                        placement='topLeft'
                        text='folders'
                        onFolderCreated={handleFolderCreated}
                    />
                </FolderContainer>
                {showTagsDrawer &&
                    <NwDrawer
                        title={renderTagHeader()}
                        onClose={() => { setShowTagsDrawer(false) }}
                    >
                        {isLoading ?
                            <MiniLoading />
                            :
                            tagMode === 'Add'
                                ?
                                <TagManager noPopover itemType="Model" addOnly onAddTag={onAddTag} tags={addibleTags} selectedList={selectedItemsIDs} />
                                :
                                <TagManager noPopover itemType="Model" onRemoveTag={onRemoveTag} removeOnly tags={removableTags} selectedList={selectedItemsIDs} />
                        }
                    </NwDrawer>
                }
                {showContactUsers &&
                    <NwDrawer
                        touchForm={touchForm}
                        onTouchForm={setTouchForm}
                        title={'Add Contact User'}
                        onClose={handleCloseContacUsersDrawer}>
                        <ContactUserForm
                            isModel
                            contactUserIds={selectedItemsIDs}
                            onCancel={handleCloseContacUsersDrawer}
                            onUpdated={handleUpdateContactUsers}
                            onTouchForm={setTouchForm} />
                    </NwDrawer>
                }
                {showDownloadDrawer &&
                    <NwDrawer title={'Export Models'} onClose={handleCloseDownloadDrawer}>
                        <ContactsExporter
                            type='model'
                            headers={[
                                { label: 'Id', key: 'id' },
                                { label: "Name", key: "name" },
                                { label: "Surname", key: "surname" },
                                { label: "E-mail address", key: "email" },
                                { label: 'Mother Agency', key: 'motherAgency' },
                                { label: "Age", key: "age" },
                                { label: "Birthdate", key: "birthdate" },
                                { label: "Nationality", key: "nationality" },
                                { label: "Features", key: "features" },
                                { label: "Tags", key: 'tags' },
                                { label: 'Land', key: 'land' },
                                { label: 'Mobile', key: 'mobile' }
                            ]}
                            data={selectedModelsData}
                        />
                    </NwDrawer>
                }
            </FooterItems>
        </FixedFooter>
    )
};
