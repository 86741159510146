import React from "react";
import styled from 'styled-components';

import {
    Switch,
    Select,
    Popover,
} from "antd";
import MiniLoading from 'Components/Gui/MiniLoading';
import ModelFeaturesQuickFilters from 'Components/ContactsSelector/ModelsSelector/ModelsWall/ModelsWallHeader/ModelFeaturesQuickFilters';
import RadioButtonGroup from "Components/Gui/NwForm/RadioButtonGroup";
import ModelsEvents from "Components/ContactsSelector/ModelsSelector/ModelsEvents";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useStateValue } from "Contexts/StateProvider";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import ModelQuickFilters from "Components/ContactsSelector/ModelsSelector/ModelQuickFilters";

const StyledHeader = styled.div`
    margin: 0;
    padding: 0;
    position: relative;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em 2em;
    font-size: .9rem;

    @media (max-width: 480px) {
        border-radius: 0;
    }

    @media only screen and (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .header-summary {
        border-right: 1px solid #e8e8e8;
        flex: auto 0 0;
        padding: 0 1em 0 0;

        @media only screen and (max-width: 960px) {
            border-right: none;
            padding: 0;
        }

        .header-summary-text {
            font-size: 1em;
            display: flex;
            align-items: center;

            em.counter {
                font-size: 1.6em;
                font-weight: bold;
                font-style: normal;
                padding-right: .5em;
            }
        }
        small.hidden-models {
            display: block;
        }
    }

    .header-filters {
        // border-right: 1px solid #e8e8e8;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        padding: 0 1em 0 1em;
        margin-bottom: auto;

        @media only screen and (max-width: 960px) {
            flex-direction: column;
            padding: 0;
            flex: none;
            align-items: flex-start;
        }

        .header-filters-first-row, .header-filters-second-row  {
             .control {
                 padding-right: 1em;
             }

             @media only screen and (max-width: 640px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
            }
        }
    }

    .header-actions {
        display: flex;
        flex-direction: row;
        margin-bottom: auto;
        flex: auto 0 0;
        padding: 0 0 0 1em;

        @media only screen and (max-width: 960px) {
            flex-direction: column;
            padding: 0;
            min-width: 0;
        }
    }

    
    div.control {
        padding: .5em 0;
        span.label {
            font-size: .85em;
            text-transform: uppercase;
            padding: 0 .5em 0 0;

            &.on-right {
                padding: 0 0 0 .5em;
            }
        }
    }
`

const QuickFiltersContainer = styled.div`
    margin: 0;
    padding: 0;
    position: relative;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
`

const ModelsWallHeader = ({
    beforeChangeSort,
    filteredList,
    //filtersType,
    isLoading,
    modelList,
    //nItems,
    //onChangeMultiSelection,
    //onChangeSort,
    //onChangeSortFolder,
    //quickFilter,
    //searchType,
}) => {

    const [{ areaContext }] = useStateValue();
    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1

    const nItems = filteredList ? filteredList.length : 0;

    const { additional, layout, selection, actions } = useContactsSelector();

    const { listType, quickFilters, } = selection
    const { multipleSelection, multipleSelectionActive, } = actions
    const { enableShowOnlyModelsWithBooks, showOnlyModelsWithBooks, enableModelAvailability } = additional
    const { view } = layout

    const cs_dispatch = useContactsSelectorDispatch();

    const onChangeListSort = key => {
        beforeChangeSort()
        cs_dispatch({
            type: "ChangeQuickFilters",
            quickFilters: { ...quickFilters, sort: key }
        })
    };

    const onChangeListSortFolder = key => {
        beforeChangeSort();
        cs_dispatch({
            type: "ChangeQuickFilters",
            quickFilters: { ...quickFilters, sortFolder: key }
        })
    };

    const toggleMultipleSelection = value => {
        cs_dispatch({
            type: "ToggleMultipleSelection",
            value: value
        })
    };


    let sortOptionsFolder = [
        { label: 'Name', value: 'Name' },
        { label: 'Manual', value: 'Manual' }
    ];
    let sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Mother Agency', value: 'MotherAgency' },
    ];

    const handleChangeView = (selectedView) => {
        cs_dispatch({
            type: "ChangeView",
            view: selectedView,
            area: areaContext.area
        })
    }

    const hiddenModels = modelList ? modelList.filter(model => !model.ST) : [];

    return (
        <>
            <StyledHeader>
                {isMobile
                    ?
                        (!isLoading
                            ?
                            <>
                                <div className="mobile-header-summary">
                                    <div className="header-summary-text">
                                        <em className="counter">{nItems}</em> MODELS FOUND
                                    </div>
                                    {areaContext.area !== 'scouting' && areaContext.area !== 'accounting' && enableModelAvailability &&
                                        <div className="control">
                                            <ModelsEvents models={filteredList} />
                                        </div>
                                    }
                                </div>
                                <div className="mobile-quick-filters">
                                    <ModelQuickFilters isMini isMobile />
                                </div>
                            </>
                            :
                            <div className="loading-container">
                                <MiniLoading />
                            </div>
                        )
                    :
                    <>
                        <div className="header-summary">
                            {!isLoading
                                ?
                                <>
                                    <div className="header-summary-text">
                                        <em className="counter">{nItems}</em> MODELS FOUND
                                    </div>
                                    {hiddenModels.length ?
                                        <Popover
                                            trigger='click'
                                            placement='bottom'
                                            content={
                                                <>
                                                    {hiddenModels.map(model => <div key={`hidden-models-${model.ID}`}>{model.N}</div>)}
                                                </>
                                            }>
                                            <small className='hidden-models nw-link'>{`${hiddenModels.length} HIDDEN MODELS`}</small>
                                        </Popover>
                                        :
                                        <small className='hidden-models'>&nbsp;</small>
                                    }
                                </>
                                :
                                <>
                                    <div className="loading-container">
                                        <MiniLoading />
                                    </div>
                                    <p className="loading-title">Fetching Models...</p>
                                </>
                            }
                        </div>
                        <div className="header-filters">
                            {(!view.fixed && !isMobile) &&
                                <div className="control" style={{ marginRight: "10px" }}>
                                    <span className="label">show as</span>
                                    <RadioButtonGroup
                                        value={view.current}
                                        onChange={handleChangeView}
                                        size='small'
                                        options={[
                                            { label: 'Cards', value: 'cards', disabled: isMobile },
                                            { label: 'List', value: 'table' }
                                        ]} />
                                </div>
                            }
                            {!layout.drawer &&
                                ((listType === 'folders')
                                    ?
                                    <div className="control">
                                        <span className="label">sort by</span>
                                        {breakpoints < 5 ?
                                            <Select
                                                autoComplete='off'
                                                options={sortOptionsFolder}
                                                size='small'
                                                value={quickFilters.sortFolder}
                                                onChange={onChangeListSortFolder} />
                                            : <RadioButtonGroup
                                                value={quickFilters.sortFolder}
                                                onChange={onChangeListSortFolder}
                                                size='small'
                                                options={sortOptionsFolder} />
                                        }
                                    </div>
                                    :
                                    <div className="control">
                                        <span className="label">sort by</span>
                                        {breakpoints < 5 ?
                                            <Select
                                                autoComplete='off'
                                                options={sortOptions}
                                                size='small'
                                                value={quickFilters.sort}
                                                onChange={onChangeListSort} />
                                            : <RadioButtonGroup
                                                value={quickFilters.sort}
                                                onChange={onChangeListSort}
                                                size='small'
                                                options={sortOptions} />
                                        }
                                    </div>
                                )
                            }
                            {enableShowOnlyModelsWithBooks &&
                                <div className="control" style={{ marginRight: "10px" }}>
                                    <Switch
                                        checked={showOnlyModelsWithBooks}
                                        onChange={
                                            (value) => {
                                                cs_dispatch({
                                                    type: "ChangeShowOnlyModelsWithBooks",
                                                    value: value
                                                })
                                            }
                                        }
                                    />
                                    <span className="label on-right">only models with books</span>
                                </div>
                            }
                        </div>
                        <div className="header-actions" style={{ flexDirection: layout.drawer && "column" }}>
                            {multipleSelection && breakpoints !== 1 &&
                                <div className="control" style={{ marginRight: "10px" }}>
                                    <Switch
                                        checked={multipleSelectionActive}
                                        onChange={toggleMultipleSelection}
                                    />
                                    <span className="label on-right">multiple selection</span>
                                </div>
                            }
                            {areaContext.area !== 'scouting' && areaContext.area !== 'accounting' && enableModelAvailability &&
                                <div className="control">
                                    <ModelsEvents models={filteredList} />
                                </div>
                            }
                        </div>
                    </>
                }
            </StyledHeader>
            {
                (listType === "all-models" && quickFilters.showFeaturesQuickFilters && !isMobile) && 
                <QuickFiltersContainer>
                    <ModelFeaturesQuickFilters
                        modelList={modelList}
                        quickFilters={quickFilters.featuresQuickFilters}
                        onUpdateQuickFilters={(_featuresQuickFilters) => {
                            cs_dispatch({
                                type: "ChangeQuickFilters",
                                quickFilters: {
                                    ...quickFilters,
                                    featuresQuickFilters: _featuresQuickFilters
                                }
                            })
                        }}
                        onCloseQuickFilters={() => {
                            cs_dispatch({
                                type: "ChangeQuickFilters",
                                quickFilters: {
                                    ...quickFilters,
                                    quickFilters: {},
                                    showFeaturesQuickFilters: false
                                }
                            })
                        }} />
                </QuickFiltersContainer>
            }
        </>
    )
};

export default ModelsWallHeader;

