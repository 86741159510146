import React, { useState } from 'react'
import { DatePicker, Segmented } from 'antd';
import styled from 'styled-components';
import { getMoment } from "Libs/NwMoment";
import { icon, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButtonGroup, NwButton } from "Components/Gui/NwButton";
import { getContactName } from 'Hooks/Contact/UseContactsList';
import NwIcon from 'Components/Gui/NwIcon';
import { areEventsFiltered, isCurrentPeriod, isStandardParams } from 'Components/Schedule/ScheduleUtils';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import moment from 'moment';
import { useContactsSelector } from 'Components/ContactsSelector/Common/ContactsSelectorContext';
import ls from 'local-storage';

const CalendarHeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    background: white;
    z-index: 4;
    width: 100%;
    padding-left: ${props => props.isGeneral ? '4.5rem' : '8.25rem'};

    position: sticky;
    top: 7rem;
    background-color: white;
    z-index: 1001;

    div.calendar-header-left {
        display: ${props => props.isGeneral ? 'flex' : 'block'};
        align-items: flex-start;
        justify-content: flex-start;
        min-height:  ${props => props.isGeneral ? '2rem' : '2rem'};
        padding: .75rem 0 1rem 0;
        
        .calendar-header-left-dates {
            margin-top: -1.6rem;
            width: 20rem;

            h2 {
                font-size: 1.15rem;
                margin: 0;
                padding: 0;
                font-weight: 400;
                margin: 0;
                line-height: 1.15em;
            }
        }

        .calendar-header-left-expand-non-bookings {
            display: flex;
            padding-top: .75rem;
            align-items: center;
            small {
                margin-left: .25rem;
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeXSm};
            }
        }
        .calendar-header-left-expand-booking-boxes {
            display: flex;
            padding-top: .25rem;
            align-items: center;
            small {
                margin-left: .25rem;
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeXSm};
            }
        }
        /* .calendar-header-left-time-grid {
            display: flex;
            padding-top: .75rem;
            align-items: center;
            small {
                margin-left: .25rem;
                text-transform: uppercase;
                font-size: ${props => props.theme.FontSizeXSm};
            }
        } */

        .calendar-header-left-filters {
            cursor: pointer;
            margin-top: -3.25rem;
            padding: 0 1.5rem;
            font-size: .75rem;
            line-height: 1.25em;
            text-transform: uppercase;
            min-height: 4rem;
            border-left: ${props => props.theme.DefaultBorder};

            max-width: 30rem;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            p {
                margin: 0;

                &.event-type-filters-line {
                    span {
                        margin-right: .15rem;
                        display: inline-block;
                    }
                    
                    em {
                        opacity: .25;
                        text-decoration: line-through;
                        margin-right: .5rem;
                        display: inline-block;
                        font-style: normal;
                    }
                }
            }

            .filter-icon-container {
                padding-right: 1rem;
                font-size: 1.25rem;

                &.no-filters {
                    opacity: .5;
                }
            }

            .filter-contacts {
                p {
                    span {
                        padding-right: .5rem
                    }
                    em {
                        font-weight: bold;
                        font-style: normal;
                    }
                }
            }
        }
    }
    
    div.calendar-header-right {
        display: flex;
        align-items: flex-end;
        justify-content:flex-end;
        padding: 1rem 0;
        flex-wrap: wrap;
        font-size: 1rem;
        //margin-top: -1rem;
        gap: 1rem;
        
        div.current-period-button-container {
            width: auto;
        }

        div.date-navigator-container {
            min-width: 6rem;
            display: flex;
            align-items: center;
            .ant-calendar-picker-input.ant-input {
                display: none!important;
            }
            .anticon.anticon-calendar.ant-calendar-picker-icon{
                margin-top: -13px;
            }
            .anticon.anticon-close-circle.ant-calendar-picker-clear{
                display: none;
            }
        }

        div.calendar-type-selector-container {
            width: auto;
        }
    }
`;

const StyledDatePicker = styled(DatePicker)`
    width: 0;
    opacity: 0;
    border: none;
    box-shadow: none; 
    padding: 0;
    margin-left: -80px !important;
`;

const BookingCalendarHeader = ({
    calendarStyle,
    expandBookingBoxes,
    expandNonBookings,
    model,
    //modelsSelectorFilter,
    onChangeParams,
    onSwitchToAgenda,
    params,
    previewMode,
    setExpandBookingBoxes,
    setExpandNonBookings,
    // setTimeGrid,
    // timeGrid,
    toolbar,
}) => {

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const { selection } = useContactsSelector();
    const { activeSet, activeSetName, listType, } = selection


    const goToCurrent = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('date', new Date());
    };

    const handlePrev = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('PREV');
    };

    const handleNext = () => {
        setIsDatePickerOpen(false)
        toolbar.onNavigate('NEXT');
    }

    const showDatePicker = () => {
        if (!isDatePickerOpen) {
            setIsDatePickerOpen(true)
        } else {
            setIsDatePickerOpen(false)
        }
    }

    const RangePickerChange = (event) => {
        if (event && moment.isMoment(event)) {
            toolbar.onNavigate('date', event.toDate());
        }
        setIsDatePickerOpen(false);
    };

    const EventTypeFiltersLine = ({ }) => {
        return (
            <p className="event-type-filters-line">
                {params.inclJobs
                    ?
                    <span>Jobs</span>
                    :
                    <em>Jobs</em>
                }
                <span>/</span>
                {params.inclCastings
                    ?
                    <span>Castings</span>
                    :
                    <em>Castings</em>
                }
                <span>/</span>
                {params.inclExternalJobs
                    ?
                    <span>External Jobs</span>
                    :
                    <em>External Jobs</em>
                }
                {calendarStyle.toLowerCase() !== 'month' &&
                    <>
                        <span>/</span>
                        {params.inclTravels
                            ?
                            <span>Travels</span>
                            :
                            <em>Travels</em>
                        }
                        <span>/</span>
                        {params.inclFollowUps
                            ?
                            <span>Activities</span>
                            :
                            <em>Activities</em>
                        }
                        <span>/</span>
                        {params.inclMeetings
                            ?
                            <span>Meetings</span>
                            :
                            <em>Meetings</em>
                        }
                        <span>/</span>
                        {params.inclNotices
                            ?
                            <span>Notices</span>
                            :
                            <em>Notices</em>
                        }
                        <span>/</span>
                        {params.inclAccommodations
                            ?
                            <span>Accommodations</span>
                            :
                            <em>Accommodations</em>
                        }
                    </>
                }
            </p>
        )
    }

    const ModelsSelectorFilterDescription = ({ }) => (
        <p>
            {listType === "smart-lists" &&
                <strong><small>SMART LIST</small></strong>
            }
            {listType === "folders" &&
                <strong><small>folder</small></strong>
            }
            <br />{activeSetName}
            {/* {modelsSelectorFilter.type === "SAVED_SEARCH" &&
                <strong><small>SMART LIST</small></strong>
            }
            {modelsSelectorFilter.type === "FOLDER" &&
                <strong><small>folder</small></strong>
            }
            <br />{modelsSelectorFilter.name} */}
        </p>
    )

    const ParamsDescription = () => {
        return (
            <div className="calendar-header-left-params-description">
                <div className='filter-contacts'>
                    {(listType !== 'all-models' && activeSet !== 'all')
                        ?
                        <ModelsSelectorFilterDescription />
                        :
                        (params.idmodels.length ? <p><span>Models:</span><em>{params.idmodels.map(idmodel => getContactName(idmodel)).join(', ')}</em></p> : null)
                    }
                    {/* {(modelsSelectorFilter && modelsSelectorFilter.active)
                        ?
                        <ModelsSelectorFilterDescription />
                        :
                        (params.idmodels.length ? <p><span>Models:</span><em>{params.idmodels.map(idmodel => getContactName(idmodel)).join(', ')}</em></p> : null)
                    } */}

                    {params.idclient ? <p><span>Client:</span><em>{getContactName(params.idclient)}</em></p> : null}
                    {params.idservice ? <p><span>Service:</span><em>{getContactName(params.idservice)}</em></p> : null}
                    {params.idagency ? <p><span>Agency:</span><em>{getContactName(params.idagency)}</em></p> : null}
                </div>
                <div className="filter-event-types-list">
                    {areEventsFiltered(params)
                        ?
                        <EventTypeFiltersLine />
                        :
                        <p>all event types</p>
                    }
                </div>
            </div>
        )
    }

    const CalendarHeaderDates = () => {
        return <h2>{toolbar.label}</h2>
    }

    const saveCalendarStyleInLocalStorage = (calendarStyle) => {
        ls.set('schedule_calendarStyle', calendarStyle)
    }

    return (
        <CalendarHeaderContainer isGeneral={!model} className='calendar-header-container'>
            <div className="calendar-header-left">
                <div className="calendar-header-left-dates">
                    <CalendarHeaderDates />
                    {model
                        ?
                        <>
                            {/* <div className="calendar-header-left-time-grid">
                                <CustomSwitch size="small" checked={timeGrid} onChange={setTimeGrid} />
                                <small>use time grid</small>
                            </div> */}
                        </>
                        :
                        (calendarStyle && calendarStyle.toLowerCase() === 'week' &&
                            <>
                                <div className="calendar-header-left-expand-non-bookings">
                                    <CustomSwitch size="small" checked={expandNonBookings} onChange={setExpandNonBookings} />
                                    <small>expand non-bookings area</small>
                                </div>
                                <div className="calendar-header-left-expand-booking-boxes">
                                    <CustomSwitch size="small" checked={expandBookingBoxes} onChange={setExpandBookingBoxes} />
                                    <small>expand booking events</small>
                                </div>
                            </>
                        )
                    }
                </div>
                {!model &&
                    <>
                        <div className="calendar-header-left-filters" onClick={onChangeParams}>
                            {!isStandardParams(params, listType, activeSet) //modelsSelectorFilter
                                ?
                                <>
                                    <div className="filter-icon-container">
                                        <NwIcon primary icon={icon({ name: 'filter', family: 'sharp', style: 'solid' })} />
                                    </div>
                                    <ParamsDescription />
                                </>
                                :
                                <>

                                    {calendarStyle.toLowerCase() === 'month'
                                        ?
                                        <>
                                            <div className="filter-icon-container">
                                                <NwIcon primary icon={icon({ name: 'filter', family: 'sharp', style: 'solid' })} />
                                            </div>
                                            <p>in general monthly view, only casting, jobs and external jobs are shown</p>
                                        </>
                                        :
                                        <>
                                            <div className="filter-icon-container no-filters">
                                                <NwIcon icon={icon({ name: 'filter-slash', family: 'sharp', style: 'light' })} />
                                            </div>
                                            <p>all event types, clients and models</p>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </>
                }
            </div>
            <div className="calendar-header-right">
                <div className="current-period-button-container">
                    {!isCurrentPeriod(toolbar.date, toolbar.view) ? <NwButton ghost icon={light('arrow-to-right')} size='small' label={`current ${toolbar.view}`} onClick={goToCurrent} /> : null}
                </div>
                <div className="date-navigator-container">
                    <NwButtonGroup
                        size="small"
                        buttons={[
                            { action: handlePrev, icon: light("chevron-left") },
                            { action: showDatePicker, icon: light("calendar-alt") },
                            { action: handleNext, icon: light("chevron-right") },
                        ]}
                    />
                    <StyledDatePicker
                        autoComplete='off'
                        defaultValue={getMoment(toolbar.date)}
                        open={isDatePickerOpen}
                        size='small'
                        picker={toolbar.view}
                        onChange={RangePickerChange} />
                </div>
                {previewMode ? <div />
                    :
                    <div className="calendar-type-selector-container">
                        <Segmented
                            value={toolbar.view}
                            options={[
                                {
                                    label: "Agenda",
                                    value: 'agenda'
                                },
                                {
                                    label: "Week",
                                    value: 'week'
                                },
                                {
                                    label: "Month",
                                    value: 'month'
                                }
                            ]}
                            onChange={view => {
                                saveCalendarStyleInLocalStorage(view)
                                if (view !== "agenda") {
                                    setIsDatePickerOpen(false)
                                    toolbar.onView(view)
                                } else {
                                    onSwitchToAgenda()
                                }
                            }}
                        />
                    </div>
                }
            </div>
        </CalendarHeaderContainer>
    );
};

export default BookingCalendarHeader
