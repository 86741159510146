import React from 'react';
import { getMoment } from "Libs/NwMoment";
import Axios from 'axios';
import EventTypeTag from "Components/EventDetails/EventTypeTag";
import { getContactName } from "Hooks/Contact/UseContactsList";
import { getEventFieldName } from 'Libs/NwUtils';
import { EventLabelPanel, EventName } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { getModelsFromEventDates } from 'Components/EventDrawer/BookingEvent/bookingEventUtils';
import { toISODate } from 'Libs/NwMoment';


export const TRANSACTION_FAMILIES = {
    Rate: 'JobFee',
    JobRefund: 'JobRefund',
    JobExpense: 'JobExpense',
    Product: 'Product',
}

export const MODEL_FIELD_CONDITIONS = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden',
}

export const TRANSACTION_MODEL_OPTIONS = {
    [TRANSACTION_FAMILIES.JobFee]: MODEL_FIELD_CONDITIONS.Required,
    [TRANSACTION_FAMILIES.JobRefund]: MODEL_FIELD_CONDITIONS.Required,
    [TRANSACTION_FAMILIES.JobExpense]: MODEL_FIELD_CONDITIONS.Optional,
    [TRANSACTION_FAMILIES.Product]: MODEL_FIELD_CONDITIONS.Hidden,
}

export const getContacts = (currentProject, events) => {
    const callsheetContactIDs = currentProject.Callsheets.map(item => item.ContactID);
    const clientIds = [currentProject.CustomerID, ...callsheetContactIDs.filter(id => id !== currentProject.CustomerID)];
    const contactsFromEvents = [];
    events.forEach(event => {
        if (event.EventClientID && !contactsFromEvents.find(c => c.ID === event.EventClientID) && !clientIds.find(id => id === event.EventClientID)) {
            contactsFromEvents.push({
                ID: event.EventClientID,
                Name: getContactName(event.EventClientID)
            })
        }
    });
    const contacts = clientIds.map(clientId => {
        return {
            ID: clientId,
            Name: getContactName(clientId)
        }
    });
    return [...contacts, ...contactsFromEvents];
}

export const getContactBillingInfo = async (contactId) => {
    try {
        const billingInfo = await Axios.get(`/contacts/billinginfos/${contactId}`)
        //setClientBillingInfos(billingInfo.filter(item => !item.Disabled))
        return billingInfo.filter(item => !item.Disabled)
    } catch (error) {
        console.log("error fetching billing info", error);
    }
}

export const getModels = (event, currentProject, family, allEvents) => {

    const noModelOption = { ModelId: null, Name: 'No model' };
    if (!currentProject) {
        return [];
    }

    if (event && event.GroupGuid) {
        const groupedEvents = allEvents.filter(e => e.GroupGuid === event.GroupGuid);
        const models = groupedEvents.map(groupedEvent => {
            return {
                ModelId: groupedEvent.ModelID,
                Name: getContactName(groupedEvent.ModelID)
            }
        });
        return family === TRANSACTION_FAMILIES.JobExpense ? [noModelOption, ...models] : models;
    }

    if (!event || !event.EventDates) {
        const models = currentProject.ProjectModels.map(model => {
            return {
                ModelId: model.ModelID,
                Name: getContactName(model.ModelID)
            }
        });
        return family === TRANSACTION_FAMILIES.JobExpense ? [noModelOption, ...models] : models;
    }

    if (event.elType === 'Travel' || event.elType === 'FollowUp') {
        return family === TRANSACTION_FAMILIES.JobExpense ?
            [noModelOption, { ModelId: event.ModelID, Name: getContactName(event.ModelID) }] :
            [{ ModelId: event.ModelID, Name: getContactName(event.ModelID) }];
    }

    if (family === TRANSACTION_FAMILIES.JobExpense) {
        return [noModelOption, ...getModelsFromEventDates(event.EventDates)]
    }

    return getModelsFromEventDates(event.EventDates);
}

export const getGroupedModelEvent = (selectedEvent) => {
    const tempGroupedModels = []
    if (selectedEvent) {
        for (const model of selectedEvent.Models) {
            tempGroupedModels.push({
                ModelID: model.ModelID,
                active: !model.Cancel,
                cancel: model.Cancel ? model.Cancel : false
            })
        }
    }
    return tempGroupedModels
}

export const getGroupedModelProject = (currentProject) => {
    const projectModels = currentProject.ProjectModels.filter(model => model.isActive)
    const tempGroupedModels = projectModels.map(model => ({
        ModelID: model.ModelID,
        active: false,
        cancel: false
    }))
    //TODO: add confirmation and cancelled state for models to show in the transaction form
    return tempGroupedModels
}

const getEventName = event => {
    switch (event.elType) {
        case 'Event':
            return event.Name;
        case 'FollowUp':
            return event.Description;
        case 'Travel':
            return `${event.StartLocation} - ${event.ArrivalLocation}`;
        default: return '';
    }
};

export const getEvents = (list) => {
    const events = list.filter(event => {
        if (event.elType === 'Event' || event.elType === 'FollowUp' || event.elType === 'Travel') {
            return true;
        }
        return false;
    }).map(event => {
        return {
            Label: (
                <EventLabelPanel>
                    <EventTypeTag event={event} />
                    <EventName>{getEventName(event)}</EventName>
                </EventLabelPanel>
            ),
            Event: event,
            Value: event.elType + event.ID
        };
    });
    return events;
}

export const getInitialValues = (family, LABELS, event, project, modelId, modelOption, usageId, contactBillingInfo, modelBillingInfo, defaultCurrency, usage) => {

    let ServiceID = event && event.ServiceID ? event.ServiceID : '';
    let Quantity = [1, "Units"]; // Quantity + TypeQuantity
    let ModelID = modelId ? modelId : undefined;
    let WorkDate = getMoment(Date.now());
    if (family === TRANSACTION_FAMILIES.JobFee) {
        Quantity = [Quantity[0], LABELS[0].TypeQuantityName];
    }
    if (family === TRANSACTION_FAMILIES.JobFee) {
        ServiceID = undefined;
    }
    if (modelOption === MODEL_FIELD_CONDITIONS.Hidden) {
        ModelID = undefined;
    }
    if (usage && usage.ModelID) {
        ModelID = usage.ModelID;
    }
    let defaultCost = [defaultCurrency, 0];
    if (usage && usage.PrevisionalTotalFee && usage.Currency) {
        defaultCost = [usage.Currency, usage.PrevisionalTotalFee];
    }


    const defaultCustomerBillingInfo = contactBillingInfo && contactBillingInfo.find(item => item.isDefault);
    const defaultModelBillingInfo = modelBillingInfo && modelBillingInfo.find(item => item.isDefault);

    //   if (family === TRANSACTION_FAMILIES.JobFee) {
    //     WorkDate = null;
    //   }
    return {
        Family: family,
        Label: LABELS[0].ID,
        Title: LABELS[0].Label,
        Description: "",
        WorkDate,
        ServiceID,
        ClientID: project && project.CustomerID,
        Cost: defaultCost, // Currency + SingleFee
        Quantity,
        AgencyCost: 0,
        AgencyCostActive: false,
        PaymentDate: getMoment(Date.now()),
        ModelID,
        EventID: event && event.elType !== 'Meeting' ? event.uniqID : null,
        UsageID: usageId || undefined,
        ClientBillingInfoID: defaultCustomerBillingInfo ? defaultCustomerBillingInfo.ID : undefined,
        ModelBillingInfoID: defaultModelBillingInfo ? defaultModelBillingInfo.ID : undefined
    }
}

export const prepareTransactionData = (values, currentProject, event, allEvents) => {
    let selectedEvent = event;
    const data = {
        Family: values.Family,
        TransactionLabelID: values.Label,
        Title: values.Title,
        Description: values.Description,
        RefDate: values.WorkDate && values.WorkDate.format(),
        WorkDate: values.WorkDate && values.WorkDate.format(),
        Quantity: values.Quantity[0],
        TypeQuantity: values.Quantity[1],
        Currency: values.Cost[0],
        SingleFee: values.Cost[1],
        ServiceID: values.ServiceID,
        ProjectID: currentProject.ID,
        ClientID: values.ClientID,
        ModelID: values.ModelID,
        UsageID: values.UsageID,
        ClientBillingInfoID: values.ClientBillingInfoID,
        ServiceBillingInfoID: values.ServiceBillingInfoID,
        ModelBillingInfoID: values.ModelBillingInfoID
    };

    if (event && event.GroupGuid) {
        const groupedEvents = allEvents.filter(e => e.GroupGuid === event.GroupGuid);
        const eventByModelId = groupedEvents.find(e => e.ModelID === values.ModelID);
        selectedEvent = eventByModelId ? eventByModelId : event;
    }

    if (selectedEvent) {
        const type = selectedEvent.elType || selectedEvent.Type;
        const eventFieldName = getEventFieldName(type.toLowerCase());
        data[eventFieldName] = selectedEvent.ID;
        return {
            ...data
        };
    }

    return data;
}

export const isServiceToSet = (event) => {
    if (!event.ServiceID) {
        return false;
    }
    if (event.elType === 'Travel' || event.elType === 'FollowUp' || event.elType === 'Accommodation') {
        return true;
    }
    return false;
}

export const getCostLabel = (family) => {
    if (family === TRANSACTION_FAMILIES.Product) {
        return 'Amount';
    }
    if (family === TRANSACTION_FAMILIES.JobFee) {
        return 'Fee';
    }
    return 'Cost for the client';
}

export const checkDate = (values, events, rangeDate, family) => {
    const { EventID: eventID, ModelID: modelId, Quantity: quantity } = values;
    if (!eventID || !modelId || family !== TRANSACTION_FAMILIES.JobFee || (quantity[1] !== 'Days' && quantity[1] !== 'Hours')) {
        return false;
    }
    if (quantity[1] !== 'Days' && quantity[1] !== 'Hours') {
        return false;
    }
    const event = events.find(event => event.ID === parseInt(eventID.split('-')[1]));
    if (event) {
        const period = event.EventDates.find(eventDate => {
            if (!eventDate.EventDatesModels) {
                return false;
            }
            const model = eventDate.EventDatesModels.find(m => m.ModelID === modelId);
            if (toISODate(rangeDate) >= toISODate(eventDate.FromDate) && toISODate(rangeDate) <= toISODate(eventDate.ToDate) && model) {
                return true;
            }
            return false;
        });
        if (period) {
            return false;
        }
    }
    return true;
}

export const groupTransactionLabels = LABELS => {
    let groupedLabels = [{ groupName: '', options: [] }];

    for (const transactionLabel of LABELS) {
        const groupIndex = groupedLabels.findIndex(group => group.groupName === transactionLabel.Group);
        if (groupIndex > -1) {
            groupedLabels[groupIndex].options.push({
                value: transactionLabel.ID,
                label: transactionLabel.Label
            });
        } else {
            if (!transactionLabel.Group) {
                groupedLabels[0].options.push({
                    value: transactionLabel.ID,
                    label: transactionLabel.Label
                });
            } else {
                groupedLabels.push({
                    groupName: transactionLabel.Group,
                    options: [{
                        value: transactionLabel.ID,
                        label: transactionLabel.Label
                    }]
                });
            }
        }
    }

    return groupedLabels;
}

export const handleChangeEventForDate = (event, setFieldValue) => {
    switch (event.elType) {
        case 'Accommodation':
            setFieldValue('RefDate', getMoment(event.FromDate))
            break;
        case 'Travel':
            setFieldValue('RefDate', getMoment(event.StartDateTime))
            break;
        case 'FollowUp':
            setFieldValue('RefDate', getMoment(event.StartDate))
            break;
        case 'Meeting':
            setFieldValue('RefDate', getMoment(event.StartDate))
            break;
        case 'Event':
            setFieldValue('RefDate', getMoment(event.FirstDate))
            break;
        default:
            break;
    }
}