import React from "react";
import styled from "styled-components";
import { Segmented } from "antd";
import { useContactsSelector, useContactsSelectorDispatch } from "Components/ContactsSelector/Common/ContactsSelectorContext";
import { isArray } from "lodash";
import ContactDropdown from "Components/ContactDropdown/ContactDropdown";
import { AgencyTypeSelector } from "Components/Gui/NwForm/AgencyTypeSelector";
import FilterYesNoAllSelector from "Components/Gui/NwForm/FilterTypeSelector";
import NwDivider from "Components/Gui/NwDivider";
import BooleanSelector from "Components/Gui/NwForm/BooleanSelector";
import FilterClearButton from "Components/ContactsSelector/Common/FilterClearButton";

const Wrapper = styled.div`
`

const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .ant-form-item {
      margin-bottom: 1rem;
  }

  margin-bottom: 1rem;
`;
const FilterLabel = styled.div`
    width: 11rem;
    text-align: left;
    line-height: 1.1em;

    &.active {
        font-weight: bold;
    }

    small {
        display: block;
        font-size: ${props => props.theme.FontSizeSm};
        line-height: 1em;
        padding-top: .25rem;
    }
`;

const FilterWrapper = styled.div`
    flex: 1 0 0;
    padding: 0 2rem 0 1rem;
`;

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
`

const ModelFilterDrawerAgencySection = ({ section }) => {

    const { selection } = useContactsSelector()
    const cs_dispatch = useContactsSelectorDispatch()
    const { filters } = selection

    //specific method for represented
    //should remove the filter if the value is null or set true or false
    const handleRepresentedChange = (val) => {
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === 'Represented')
        if (val === null) {
            if (filterIndex !== -1) {
                newWorkingFilters.splice(filterIndex, 1)
            }
        } else {
            if (filterIndex === -1) {
                newWorkingFilters.push({ name: "Represented", value: val, source: 'filter' })
            } else {
                newWorkingFilters[filterIndex].value = val
            }
        }
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
    }

    const handleChangeFilterValue = (name, val) => {
        const filter = section.areaFilters.find(f => f.name === name)
        let newWorkingFilters = [...filters.working]
        const filterIndex = newWorkingFilters.findIndex(f => f.name === name)
        
        if (val) {
            if (filterIndex === -1) {
                newWorkingFilters.push({ name: filter.name, value: val, source: 'filter' })
            } else {
                newWorkingFilters[filterIndex].value = val
            }
        } else {
            if (filterIndex !== -1) {
                newWorkingFilters.splice(filterIndex, 1)
            }
        }
        if (name === "AgencyNotIn" && val === true) {
            newWorkingFilters = newWorkingFilters.filter(f => f.name !== "AgencyID")
        }
        cs_dispatch({ type: 'ChangeFilters', filters: newWorkingFilters })
    }

    const getFilterValue = (name) => {
        const filter = section.areaFilters.find(f => f.name === name)
        let filterValue = filter.defaultval
        const workingFilter = filters.working.find(f => f.name === filter.name)
        if (workingFilter) {
            filterValue = workingFilter.value
        }
        return filterValue;
    }

    const isFilterActive = (name) => {
        const filter = filters.working.find(f => f.name === name)
        if (filter) {
            return true
        }
        return false
    }

    let currentValueForNotIn = getFilterValue('AgencyNotIn');
    if (typeof currentValueForNotIn === "undefined" || currentValueForNotIn === null) {
        currentValueForNotIn = false;
    }

    return (
        <Wrapper>
            <FilterRow>
                <FilterLabel>
                    Include only models:
                </FilterLabel>
                <FilterWrapper>
                    <Segmented
                        value={currentValueForNotIn}
                        options={[
                            {
                                label: 'With',
                                value: false
                            },
                            {
                                label: 'Without',
                                value: true
                            }
                        ]}
                        onChange={value => handleChangeFilterValue('AgencyNotIn', value)}
                    />
                </FilterWrapper>
            </FilterRow>
            {!currentValueForNotIn &&
                <FilterRow>
                    <FilterLabel className={isFilterActive('AgencyID') ? 'active' : ''}>
                        Agency:
                    </FilterLabel>
                    <FilterWrapper>
                        <ContactDropdown
                            activeTypes={['age']}
                            value={getFilterValue('AgencyID')}
                            onChange={value => handleChangeFilterValue('AgencyID', value)}
                        />
                    </FilterWrapper>
                </FilterRow>
            }
            <FilterRow>
                <FilterLabel className={isFilterActive('AgencyType') ? 'active' : ''}>
                    Agency Type:
                </FilterLabel>
                <FilterWrapper>
                    <AgencyTypeSelector
                        value={getFilterValue('AgencyType')}
                        onChange={value => handleChangeFilterValue('AgencyType', value)}
                        emptyValue
                    />
                </FilterWrapper>
            </FilterRow>
            <br />
            <NwDivider>Represented Status</NwDivider>
            <br />
            <FilterRow>
                <FilterLabel className={isFilterActive('Represented') ? 'active' : ''}>
                    Represented:
                </FilterLabel>
                <FilterWrapper>
                    <FieldWrapper>
                        <BooleanSelector
                            emptyValue
                            value={getFilterValue('Represented')}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            onChange={value => {
                                handleRepresentedChange(value)
                            }}
                        />
                        <FilterClearButton onClick={() => handleRepresentedChange(null)} />
                    </FieldWrapper>
                    {/* <FilterYesNoAllSelector
                        value={getFilterValue('Represented')}
                        allowClear
                        onChange={value => handleChangeFilterValue('Represented', value)}
                    /> */}
                </FilterWrapper>
            </FilterRow>
        </Wrapper>
    );
};

export default ModelFilterDrawerAgencySection;