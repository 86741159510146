import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from 'Components/Gui/NwButton';
import { Loading } from 'Components/Gui/Loading';
import DocumentLayout from 'Components/_LayoutComponents/Layout/DocumentLayout';
import { UserContext } from "Contexts/UserContext";
import { Segmented } from 'antd';

//import SendDrawer from 'Components/SendDrawer/SendDrawer';
//import NwDrawer from 'Components/Gui/NwDrawer';

const Wrapper = styled.div`
    .actions-on-documents {
        margin: 2rem auto;
        padding: 0 1rem;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        .document-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1rem;
        }
    }
    
    .editor-wrapper {
        margin: 1rem auto;
        width: 90%;
        
        @media only screen and (max-width: 992px) {
            margin-left: 15%;
            margin-right: 15%;
        }
        @media only screen and (max-width: 768px) {
            margin-left: 10%;
            margin-right: 10%;
        }
        @media only screen and (max-width: 600px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
`;

const PdfPrintDrawer = ({ docVersion, saveButton, onClose }) => {
    const { currentUser } = useContext(UserContext);
    const [contentData, setContentData] = useState();
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState();
    const [head, setHead] = useState();
    const [styleDefinitions, setStyleDefinitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    //const [isEmailDrawer, setEmailDrawer] = useState(false);

    //const document = {...docVersion};

    const getContents = () => {
        const domDoc = new DOMParser().parseFromString(docVersion.Html, "text/html")
        const stylematches = [...domDoc.querySelectorAll('style')].map(style => style.textContent)
        const headmatch = domDoc.querySelector('head')
        const bodymatch = domDoc.querySelector('#body')
        const headermatch = domDoc.querySelector('header')
        const footermatch = domDoc.querySelector('footer')

        setHead(headmatch.outerHTML);
        if (headermatch) {
            setHeader(headermatch.outerHTML);
        }
        if (footermatch) {
            setFooter(footermatch.outerHTML);
        }
        if (bodymatch) {
            setContentData(bodymatch.innerHTML);
        }
        const stylestring = stylematches.join('\n') //.filter(sm => !sm.indexOf("@import") >= 0).join('\n')
        setStyleDefinitions(stylestring);
    }

    useEffect(() => {
        if (docVersion) {
            getContents()
        }
    }, [docVersion]);

    const htmlPreview = useMemo(() => {
        const domDoc = new DOMParser().parseFromString(docVersion.Html, "text/html");
        const headermatch = domDoc.querySelector('header');
        const footermatch = domDoc.querySelector('footer');
        if (headermatch) {
            headermatch.remove();
        }
        if (footermatch) {
            footermatch.remove();
        }
        //adding a style definition to add some padding on the body
        const style = document.createElement('style');
        style.innerHTML = 'body { padding: 4rem 2rem; }';
        domDoc.head.appendChild(style);
        // Convert the modified DOM back to an HTML string
        return domDoc.documentElement.outerHTML;
    }, docVersion)

    const handleSaveDocument = async () => {
        if (editorRef.current && editing) {
            const content = editorRef.current.getContent();
            let savedDocument = "<!DOCTYPE html><html lang=\"en\">" + head
            savedDocument += "<body>" + header + '<section id="body">' + content + '</section>' + footer + "</body>"
            savedDocument += "</html>";
            setLoading(true);
            const saveDocumentData = {
                ID: docVersion.ID,
                Html: savedDocument
            };
            try {
                await Axios.put('/docVersions/UpdateHtml', saveDocumentData);
                setLoading(false);
            } catch (error) {
                console.log('ant : Save document error => ', error);
                setLoading(false);
            }
        }
    };

    const handlePrintDocument = async () => {
        if (editorRef.current) {
            await handleSaveDocument()
        }
        setPdfLoading(true);
        try {
            const url = await Axios.get(`/docVersions/pdf/${docVersion.DocGuid}`);
            //const documentUrl = new URL(url, Constants.tenanturl);
            const documentUrl = new URL(url);
            window.open(documentUrl.href, '_blank');
            setPdfLoading(false);
        } catch (error) {
            //console.log('ant : Load pdf error => ', error);
            setPdfLoading(false);
        }
    };

    // const handleOpenEmailsDrawer = () => {
    //     setEmailDrawer(true);
    // };

    // const handleCloseEmailsDrawer = () => {
    //     setEmailDrawer(false);
    // };

    // const handleSendDocument = (subject, message, recipients) => {
    //     const recipients = selectedEmails.map(email => {
    //         if (email.ContactID) {
    //             return {
    //                 ContactID: email.ContactID,
    //                 ConnectionID: email.ConnectionID,
    //                 ContactDetails: [email.Email]
    //             }
    //         } else {
    //             return {
    //                 ContactDetails: [email.Email]
    //             }
    //         }
    //     });
    //     const data = [
    //         {
    //             Recipients: recipients,
    //             DocVersionGUID: document.DocGuid,
    //             SendMethod: "SendGrid",
    //             Subject: subject,
    //             AsAttachment: true,
    //             Message: message
    //         }
    //     ];
    //     try {
    //         await Axios.post('/docVersions/sendmessage', data);
    //         setTouchForm(false);
    //         setSubmitting(false);
    //     } catch (error) {
    //         console.log('ant : Send document to emails error => ', error);
    //         setSubmitting(false);
    //     }
    // }

    const editorRef = useRef(null);

    return contentData ? (
        <Wrapper>
            <div className="actions-on-documents">
                <div>
                    {/* segmented component to switch from preview to editing */}
                    <Segmented
                        options={[
                            { label: 'Preview', value: false },
                            { label: 'Edit', value: true }
                        ]}
                        value={editing}
                        onChange={setEditing}
                    />
                </div>
                <div className="document-buttons">
                    {editing && saveButton && (currentUser.UserID === docVersion.UserCreated) &&
                        <NwButton label='Save' icon={light('floppy-disk')} primary onClick={handleSaveDocument} loading={loading} />
                    }
                    {/* <NwButton label='Send' icon={light('paper-plane')} primary onClick={handleOpenEmailsDrawer} loading={loading} />
                        &nbsp;&nbsp; */}
                    <NwButton label='Generate PDF' primary icon={light('file-pdf')} onClick={handlePrintDocument} loading={pdfLoading} />
                </div>
            </div>
            <div className="editor-wrapper">
                {contentData
                    ?
                    (editing
                        ?
                        <Editor
                            apiKey='rk3ntw79uux2vx6q4kp5b52qsbse3206q8pij11up8vwbhfu'
                            //apiKey='twj4dzqrnrkzil9tsdzupufw4jtt5t34ujzta4wvwsh4tacc'
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={contentData}
                            plugins='table lists link charmap'
                            init={{
                                height: 'calc(100vh - 180px)',
                                menubar: 'edit insert tools table tc help',
                                toolbar: 'undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify |  table | outdent indent | numlist bullist checklist | forecolor backcolor | pagebreak | charmap emoticons | link',
                                plugins: ['lists', 'link', 'charmap', 'table'],
                                content_style: styleDefinitions
                            }}
                        />
                        :
                        <iframe
                            style={{
                                backgroundColor: "#fff",
                                height: "calc(100vh - 180px)",
                                width: "100%"
                            }}
                            srcdoc={htmlPreview}
                        />
                    )
                    :
                    <Loading textBlack />
                }
            </div>
            {/* {isEmailDrawer && document &&
                    <NwDrawer padding="0px" title='Send Document' onClose={handleCloseEmailsDrawer}>
                        <SendDrawer
                            searchBox
                            suggestions={[]} //aggiungere e-mail cliente progetto/evento
                            existingEmails={[]}
                            recipientsBox
                            textsBox
                            hasSubject
                            hasMessage
                            subjectDefault="document"
                            sendBox
                            onSend={handleSendDocument}
                            afterSend={handleCloseEmailsDrawer}
                            onCancel={handleCloseEmailsDrawer}
                        />
                    </NwDrawer>
                } */}
        </Wrapper>
    ) :
        <DocumentLayout>
            <Loading loadingType="box" />
        </DocumentLayout>;
};

export default PdfPrintDrawer